import { useEffect, useState } from 'react';
import { useQueryClient } from 'react-query';
import { useFiscalCodeFlow } from '@vfit/consumer/hooks';
import { NameFiscalCode, PersonalInfo } from '@vfit/consumer/components';
import { ErrorDetail, LoggerInstance, resetData, useTracking } from '@vfit/shared/data-access';
import { API, getItemCookie, openPopup, PAGES, setTrackLink } from '@vfit/shared/data-access';
import {
  CMS_CONFIG,
  ErrorCodes,
  errorMock,
  getCurrentUserType,
  IGenericErrorCMS,
  IPersonalInfoCmsMobile,
  IPortabilityMobileCMS,
  isInResetUser,
  IUserType,
  PARTNER_OFFER_COOKIE,
  useCmsConfig,
} from '@vfit/consumer/data-access';
import { IPersonalDataObject } from '@vfit/shared/models';
import { ICheckoutData } from '../../checkout.models';
import {
  checkError,
  organizeNameFiscalCodeMobile,
  organizePrsonalInfoMobile,
  organizeRetriveFiscalCodeMobile,
} from './nameFiscalCodeCard.utils';
import { ID_FLOWS } from '../../checkout.constants';
import { useCheckout } from '../../../iBuyMobile.context';
import { getUserInfo, getTagging } from '../../checkout.utils';

const NameFiscalCodeCard = ({ handleOnChangeEnableGoNext, handleOnClose }: ICheckoutData) => {
  const {
    checkoutErrors,
    owningData,
    findCaller,
    isLoadingCart,
    isModalButtonSticky,
    customerFlow,
    product,
    setIsStartCustomerFlow,
    setOwningData,
    setFindCaller,
    setIsReadOnly,
    setCurrentStepKey,
    serializeContext,
    deleteSerializedContext,
  } = useCheckout();
  const queryClient = useQueryClient();
  const isResetUser = isInResetUser();
  const isLoggedUser = getCurrentUserType() === IUserType.LOGGED_USER;
  const taggingMobile = getTagging('personal data');

  const { trackView, trackWithNewDatalayer } = useTracking({
    event: ['checkout_step1'],
    event_label: 'personal information',
    opts: taggingMobile?.opts,
    cartProduct: taggingMobile?.trackingProduct,
    pageProduct: taggingMobile?.pageProductsInfo,
    visitorTrackingOpts: getUserInfo(),
  });
  setTrackLink(trackView, 'personal information');

  const [isLoadingReset, setIsLoadingReset] = useState(isResetUser);
  const {
    isLoading: isLoadingFiscalCodeFlow,
    isError,
    errorCode,
    errorMessage,
    getFindCallerResponse,
    isInResetCustomer,
  } = useFiscalCodeFlow({
    fiscalCode: owningData.owningIndividual.fiscalCode,
    owningDataIsValid: isLoadingReset ? false : owningData.isValid || false,
    isFlowEnabled: !isLoadingCart,
    isWinbackFlow: product?.isWinback,
  });
  const customerTypeForRedirect: string[] = ['existingLegacyCustomer', 'inMigration'];
  const [isErrorFiscaleCode, setIsErrorFiscalCode] = useState('');
  const genericError = useCmsConfig(
    CMS_CONFIG[PAGES.CONSUMER],
    API.CMS_GET_GENERIC_ERROR_MOBILE
  ) as IGenericErrorCMS;
  const dataFromCms = useCmsConfig(
    CMS_CONFIG[PAGES.CONSUMER],
    API.CMS_GET_PERSONAL_DATA_MOBILE
  ) as IPersonalInfoCmsMobile;
  const errorMessge = useCmsConfig(
    CMS_CONFIG[PAGES.CONSUMER],
    API.CMS_GET_GENERIC_ERROR
  ) as IGenericErrorCMS;
  const portabilityCardCms = useCmsConfig(
    CMS_CONFIG[PAGES.CONSUMER],
    API.CMS_GET_PORTABILITY_MOBILE
  ) as IPortabilityMobileCMS;

  const { legacyPopup } = organizeNameFiscalCodeMobile(dataFromCms, product);
  const personalInfoConfigCms = organizePrsonalInfoMobile(dataFromCms);
  const nameFiscalCodeConfigCms = organizeRetriveFiscalCodeMobile(
    dataFromCms,
    portabilityCardCms,
    product?.skipMnp
  );
  const isPartnerOffer = (): boolean => product?.partnerOffer?.isPartnerOffer || false;
  const isLoading = isLoggedUser
    ? isLoadingFiscalCodeFlow || !customerFlow?.associateCustomer?.isSuccess
    : isLoadingFiscalCodeFlow;

  useEffect(() => {
    if (!isLoadingCart && isLoggedUser) setIsStartCustomerFlow(true);
  }, [isLoadingCart]);

  useEffect(() => {
    setCurrentStepKey(ID_FLOWS.PERSONAL_INFO);
    if (isResetUser) {
      setTimeout(() => {
        deleteSerializedContext();
        window.history.pushState(null, '', window.location.pathname);
        setIsLoadingReset(false);
      }, 1000);
    }
  }, []);

  const handleGoNext = () => {
    if (handleOnChangeEnableGoNext) {
      handleOnChangeEnableGoNext(owningData.isValid && !isLoading);
    }
  };

  const resetQueries = () => {
    resetData(queryClient, [
      'fiscalCodeBirthPlace',
      'findCaller',
      'generateOTP',
      'verifyOTP',
      'postCustomer',
      'customerData',
      'updateProductCharacteristic',
      'validateDuplicateOrder',
      'payMean',
      'associateCustomer',
    ]);
  };

  const checkCampaignMMForProspect = (isActive: boolean) => {
    // family plus check for prospect
    if ((product?.isFamilyPlusProduct || product?.forceCampaign) && !isActive) {
      trackWithNewDatalayer({
        event: ['journey_end'],
        event_label: 'family plus not available for not client',
        opts: {
          ...taggingMobile.opts,
          event_category: 'journey',
          page_browser_system: 'browser',
        },
        cartProduct: taggingMobile.trackingProduct,
        visitorTrackingOpts: getUserInfo(),
      });
      checkoutErrors?.showAndTrackError?.(
        genericError,
        handleOnClose,
        undefined,
        product?.familyPlus?.noCustomer?.title || 'Non sei ancora un nostro cliente?',
        product?.familyPlus?.noCustomer?.message ||
          'Non ti preoccupare, abbiamo altre offerte pronte a stupirti! ',
        handleOnClose,
        product?.familyPlus?.noCustomer?.actionLabel || 'Scopri le altre offerte',
        undefined,
        undefined,
        isModalButtonSticky,
        undefined,
        true
      );
    }
  };

  useEffect(() => {
    if (getFindCallerResponse) {
      if (
        !findCaller.verifyOtp &&
        getFindCallerResponse.resultInfo &&
        customerTypeForRedirect.includes(getFindCallerResponse.resultInfo)
      ) {
        resetQueries();
      }
    }
  }, []);

  useEffect(() => {
    if (getFindCallerResponse) {
      LoggerInstance.debug('FIND CALLER RESPONSE', getFindCallerResponse);
      const showOtp =
        !owningData.isLogged &&
        !findCaller.isVerified &&
        !!(
          getFindCallerResponse.individualRef?.email || getFindCallerResponse.individualRef?.msisdn
        );
      setFindCaller({
        ...getFindCallerResponse,
        showOtp,
        isVerified: !!owningData.isLogged,
      });
      if (
        !findCaller.verifyOtp &&
        getFindCallerResponse.resultInfo &&
        customerTypeForRedirect.includes(getFindCallerResponse.resultInfo)
      ) {
        const redirectAction = () => {
          if (legacyPopup?.legacyPopupUrl) {
            openPopup(legacyPopup?.legacyPopupUrl, handleOnClose);
          } else if (handleOnClose) {
            handleOnClose();
          }
        };
        trackView({
          event_name: 'checkout_step1.3',
          event_label_track: 'coexistent popup',
        });
        checkoutErrors?.showAndTrackError?.(
          genericError,
          handleOnClose,
          errorMock(
            'findCaller',
            {
              url: 'findCaller',
              status: '200',
              statusText: 'ok',
            },
            undefined,
            undefined,
            'legacy customer',
            false,
            {
              fiscalCode: owningData?.owningIndividual?.fiscalCode || '',
            }
          ),
          legacyPopup?.legacyPopupTitle,
          legacyPopup?.legacyPopupMessage,
          redirectAction,
          legacyPopup?.legacyPopupActionText,
          undefined,
          undefined,
          isModalButtonSticky
        );
      } else {
        checkCampaignMMForProspect(showOtp);
      }
    }
  }, [getFindCallerResponse]);

  useEffect(() => {
    if (isInResetCustomer && !isResetUser) {
      checkoutErrors?.showAndTrackError?.(
        genericError,
        () => {
          if (handleOnClose) handleOnClose();
        },
        errorMock('resetUser', {
          url: 'resetUser',
          status: '200',
          statusText: '200',
        }),
        'Attenzione!',
        "Vuoi effettuare l'ordine con un altro utente?",
        () => {
          localStorage.removeItem('customerDataCf');
          serializeContext(true);
          setTimeout(() => {
            if (handleOnChangeEnableGoNext) {
              handleOnChangeEnableGoNext(false);
            }
            const href = `${window.location.href}?${ErrorCodes.MODE_RESET_USER}`;
            LoggerInstance.debug('Redirect reset', href);
            window.location.href = href;
          }, 1500);
        },
        'Si',
        () => {
          if (handleOnClose) handleOnClose();
        },
        'No',
        isModalButtonSticky,
        true
      );
    }
  }, [isInResetCustomer]);

  useEffect(() => {
    handleGoNext();
  }, [isLoading, owningData.isLogged, owningData.isValid]);

  useEffect(() => {
    if (isError) {
      const isFiscalCodeError = errorCode === ErrorCodes.FISCAL_CODE_ERROR;
      const errorDetail: ErrorDetail = checkError(
        errorCode,
        handleOnClose,
        errorMessge?.genericerror
      );
      setOwningData({
        ...owningData,
        owningIndividual: {
          ...owningData.owningIndividual,
          fiscalCode:
            errorCode === ErrorCodes.FISCAL_CODE_ERROR
              ? owningData.owningIndividual.fiscalCode
              : '',
        },
        isValid: false,
      });
      resetQueries();

      if (errorCode !== ErrorCodes.FISCAL_CODE_ERROR) {
        setIsErrorFiscalCode('');
      } else {
        setIsErrorFiscalCode(() => errorDetail?.message || '');
      }

      checkoutErrors?.showAndTrackError?.(
        genericError,
        errorDetail?.onClose,
        errorMock(
          'checkFiscalCode',
          {
            url: 'checkFiscalCode',
            status: 'NA',
            statusText: errorCode || 'error',
          },
          undefined,
          errorCode,
          errorMessage || 'Fiscal code error',
          false,
          {
            fiscalCode: owningData?.owningIndividual?.fiscalCode || '',
          }
        ),
        errorDetail?.title,
        errorDetail?.message,
        errorDetail?.onClose,
        errorDetail.actionText,
        () => openPopup(product?.genericErrorCallMeNow?.url || '', handleOnClose),
        product?.removeCTC ? undefined : product?.genericErrorCallMeNow?.label || '',
        isModalButtonSticky,
        true,
        isFiscalCodeError
      );
    }
  }, [isError]);

  const getPartnerOfferData = (): IPersonalDataObject => {
    const partnerOffer = getItemCookie(PARTNER_OFFER_COOKIE);
    if (!partnerOffer) {
      checkoutErrors?.showAndTrackError?.(
        genericError,
        handleOnClose,
        errorMock(
          'partnerOffer',
          {
            url: 'getPartnerOfferData',
            status: '200',
            statusText: 'ok',
          },
          undefined,
          undefined,
          'cookie partner offer not found',
          true
        ),
        legacyPopup?.legacyPopupTitle,
        legacyPopup?.legacyPopupMessage,
        handleOnClose,
        legacyPopup?.legacyPopupActionText,
        () => openPopup(product?.genericErrorCallMeNow?.url || '', handleOnClose),
        product?.removeCTC ? undefined : product?.genericErrorCallMeNow?.url || '',
        isModalButtonSticky,
        true
      );
    }
    return {
      ...owningData,
      owningIndividual: {
        ...owningData?.owningIndividual,
        firstName: partnerOffer?.firstName || '',
        lastName: partnerOffer?.lastName || '',
        fiscalCode: partnerOffer?.fiscalCode || '',
      },
    };
  };

  const onChangeData = (data: IPersonalDataObject, isValid?: boolean) => {
    const { owningIndividual: owningIndividualData } = data;
    const {
      firstName: firstNameData,
      lastName: lastNameData,
      fiscalCode: fiscalCodeData,
    } = owningIndividualData;
    const { owningIndividual } = owningData;
    const { firstName, lastName, fiscalCode } = owningIndividual;
    if (owningData) {
      setOwningData({
        ...data,
      });
    }

    if (firstNameData !== firstName || lastNameData !== lastName || fiscalCodeData !== fiscalCode) {
      resetQueries();
      setIsReadOnly(false);
      if (!isValid) {
        setOwningData({
          ...data,
          // owningIndividual: DEFAULT_DATA.owningIndividual,
          isValid: false,
          isLogged: false,
        });
      }
    }
  };

  return (
    <div>
      {(owningData.isLogged || isLoggedUser) && (
        <PersonalInfo data={owningData} isLoading={isLoading} cmsConfig={personalInfoConfigCms} />
      )}
      {!owningData.isLogged && !isLoadingReset && !isLoggedUser && (
        <NameFiscalCode
          errorFiscalCode={
            !owningData.isValid && isErrorFiscaleCode ? isErrorFiscaleCode : undefined
          }
          data={isPartnerOffer() ? getPartnerOfferData() : owningData}
          onChangeData={onChangeData}
          isLoading={owningData?.isValid && isLoadingCart ? true : isLoading}
          cmsConfig={nameFiscalCodeConfigCms}
          taggingData={{
            trackingProduct: taggingMobile.trackingProduct,
            opts: taggingMobile.opts,
          }}
          isFormDisabled={product?.isAcceptedFamilyPlusProduct || isPartnerOffer()}
          isLoggedUser={isLoggedUser}
        />
      )}
    </div>
  );
};

export default NameFiscalCodeCard;
