import { useState, useEffect } from 'react';
import { API, PAGES } from '@vfit/shared/data-access';
import { ICommonData, IOptionalText } from '@vfit/shared/models';
import { CMS_CONFIG, useCmsConfig, IPortabilityCMS } from '@vfit/consumer/data-access';
import { useTracking } from '@vfit/shared/data-access';
import { CheckboxCards } from '@vfit/consumer/components';
import {useQueryClient} from "react-query";
import { organizeCheckboxCardsPortability } from './portabilityInfoCard.utils';
import { getTagging, getUserInfo } from '../../checkout.utils';
import { useCheckout } from '../../../iBuyFixed.context';
import { ID_FLOWS } from '../../checkout.constants';

const PortabilityInfoCard = ({ handleOnChangeEnableGoNext }: ICommonData) => {
  const { portability, setPortability, setCurrentStepKey } = useCheckout();
  const queryClient = useQueryClient();
  const taggingFixed = getTagging('portability', 'fixed', queryClient);

  useTracking({
    event: ['checkout_step4.2'],
    event_label: 'landline information',
    opts: taggingFixed.opts,
    cartProduct: taggingFixed.trackingProduct,
    pageProduct: taggingFixed?.pageProductsInfo,
    visitorTrackingOpts: getUserInfo(),
  });

  const [selectedItem, setSelectedItem] = useState<IOptionalText>();

  const portabilityCms = useCmsConfig(
    CMS_CONFIG[PAGES.CONSUMER],
    API.CMS_GET_PORTABILITY
  ) as IPortabilityCMS;

  const { title, items, description } = organizeCheckboxCardsPortability(
    portabilityCms,
    portability
  );

  useEffect(() => {
    setCurrentStepKey(ID_FLOWS.PORTABILITY_INFORMATIONS);
    setSelectedItem(undefined);
    setPortability({
      ...portability,
      portabilityInfo: '',
      portabilityOperators: '',
    });
  }, []);

  const handleSelect = (item: IOptionalText | undefined) => {
    setPortability({
      ...portability,
      portabilityInfo: item?.value || '',
      portabilityOperators: '',
    });
    if (item && handleOnChangeEnableGoNext) handleOnChangeEnableGoNext(true);
  };

  return (
    <CheckboxCards
      items={items}
      description={description}
      title={title}
      value={portability.portabilityInfo}
      selectedItem={selectedItem}
      setSelectedItem={setSelectedItem}
      onSelectItem={handleSelect}
    />
  );
};

export default PortabilityInfoCard;
