import { useQuery, useQueryClient } from 'react-query';
import { IPage, IUseTranslate } from './page.models';
import { ICMSConfigPage } from '../cms.models';
import { CONSUMER_CMS_DEFAULT } from '../cms.config';
import {
  translateKeyByLang,
  translateKeyByLangInverse,
  translateDeviceAndProductsByLang,
  getLocalLanguage,
  getHubKey,
} from './page.utils';

export const useTranslate = (cmsPrefetchConfigPage?: ICMSConfigPage) => {
  const queryClient = useQueryClient();
  const languages = queryClient.getQueryData<[{ id: string; value: string }]>('languages') || [];
  const cmsConfig = cmsPrefetchConfigPage || CONSUMER_CMS_DEFAULT;
  const { data: page } = useQuery<IPage>(['page']);
  const { data: apiCustomHubs } = useQuery<string[]>(['apiCustomHubs']);
  const apiProductList = page?.params?.elements?.apiProductsList;
  const apiDevicesList = page?.params?.elements?.apiDeviceList;
  const isHub = page?.params?.layout === 'HUB';
  const isDetail = page?.params?.layout === 'PRODUCT_DETAIL';
  const isShoppingCart = page?.params?.layout === 'SHOPPING_CART';

  const translate = (lang?: string): void => {
    if (languages.length === 0) {
      localStorage.removeItem('lang');
      return;
    }
    const localLang = getLocalLanguage();
    localStorage.setItem('lang', lang || localLang || 'it');
    const selectedLanguage = lang || localLang;

    // region PAGE
    if (page) {
      translateKeyByLang(queryClient, 'page', selectedLanguage);
    }
    // endregion

    // region DEFAULT
    if (cmsConfig?.apis) {
      cmsConfig?.apis.forEach((api) => {
        translateKeyByLang(queryClient, api.key, selectedLanguage);
      });
    }
    // endregion

    // region PRODUCT
    if ((isDetail || isShoppingCart) && apiProductList) {
      translateKeyByLang(queryClient, 'product', selectedLanguage);
    }
    // endregion

    // region PRODUCTS
    if ((isHub || isShoppingCart) && apiProductList) {
      const key = getHubKey(page?.params?.key, apiProductList);
      translateDeviceAndProductsByLang(queryClient, key, selectedLanguage);
    }
    // endregion

    // region DEVICE
    if (isDetail && apiDevicesList) {
      translateKeyByLang(queryClient, 'device', selectedLanguage);
    }
    // endregion

    // region DEVICES
    if (isHub && apiDevicesList) {
      const apiName = apiDevicesList?.toLowerCase().split('/').join('-');
      translateDeviceAndProductsByLang(queryClient, apiName, selectedLanguage);
    }
    // endregion

    // region CUSTOM PRODUCTS IN CAROUSEL ONLY IF A WIDGET HAVE A CARD FOR MORE HUBS
    if (Object.keys(apiCustomHubs || {})?.length > 0) {
      Object.keys(apiCustomHubs || {})?.forEach((el) => {
        const apiName = apiCustomHubs[el];
        if (apiName) {
          translateDeviceAndProductsByLang(queryClient, apiName, selectedLanguage);
        }
      });
    }
    // endregion
  };

  const initDefaultMultiLang = (): void => {
    if (languages.length === 0) {
      localStorage.removeItem('lang');
    } else {
      const initLang = localStorage.getItem('lang');
      const languagesIds = languages.map(el => el.id);
      localStorage.setItem('lang', languagesIds.includes(initLang) ? initLang :  'it');
    }

    // region PAGE
    if (page) {
      translateKeyByLangInverse(queryClient, 'page');
    }
    // endregion

    // region DEFAULT
    if (cmsConfig?.apis) {
      cmsConfig?.apis.forEach((api) => {
        translateKeyByLangInverse(queryClient, api.key);
      });
    }
    // endregion

    // region PRODUCT
    if (isDetail && apiProductList) {
      translateKeyByLangInverse(queryClient, 'product');
    }
    // endregion

    // region PRODUCTS
    if (isHub && apiProductList) {
      const key = getHubKey(page?.params?.key, apiProductList);
      translateKeyByLangInverse(queryClient, key);
    }
    // endregion

    // region DEVICE
    if (isDetail && apiDevicesList) {
      translateKeyByLangInverse(queryClient, 'device');
    }
    // endregion

    // region DEVICES
    if (isHub && apiDevicesList) {
      const apiName = apiDevicesList?.toLowerCase().split('/').join('-');
      translateKeyByLangInverse(queryClient, apiName);
    }
    // endregion

    // region CUSTOM PRODUCTS IN CAROUSEL ONLY IF A WIDGET HAVE A CARD FOR MORE HUBS
    if (Object.keys(apiCustomHubs || {})?.length > 0) {
      Object.keys(apiCustomHubs || {})?.forEach((el) => {
        const apiName = apiCustomHubs[el];
        if (apiName) {
          translateKeyByLangInverse(queryClient, apiName);
        }
      });
    }

  };

  return {
    translate,
    initDefaultMultiLang,
    selectedLang: getLocalLanguage(),
  };
};

