import { ShadowsGroup } from '@vfit/shared/components';
import { useEffect } from 'react';
import { ICMSApiError, ICMSError, ICommonData } from '@vfit/shared/models';
import {
  CMS_CONFIG,
  ErrorCodes,
  errorMock,
  IGenericErrorCMS,
  productSpecificError,
  useCmsConfig,
} from '@vfit/consumer/data-access';
import { API, openPopup, PAGES } from '@vfit/shared/data-access';
import { useCheckout } from '../../../iBuyMobile.context';
import { useAddressMobileFlow } from '../../hooks/UseAddressMobileFlow/useAddressMobileFlow';
import { IEditorialErrorConfig } from '../../checkout.models';
import { checkErrorCustomer } from '../../checkout.utils';
import { Skeleton } from '../RecognitionCard/recognitionCard.style';
import RecognitionCardMainSkipMnp from './components/RecognitionCardMainSkipMnp/recognitionCardMainSkipMnp';
import { ID_FLOWS } from '../../checkout.constants';

const RecognitionCardSkipMnp = (props: ICommonData) => {
  const { handleOnChangeEnableGoNext, handleGoPrevSlide, handleOnClose } = props;
  const {
    setIsDisabledBackButton,
    setIsStartRecognition,
    setSlidesGoBack,
    setIsStartCustomerFlow,
    setBillingAddress,
    setShippingAddress,
    setCurrentStepKey,
    product,
    isModalButtonSticky,
    checkoutErrors,
    isStartRecognition,
    customerDeliveryBaseInfo,
    customerFlow,
  } = useCheckout();

  const { urlGenericErrorCallMeNow } = productSpecificError(product);

  const genericError = useCmsConfig(
    CMS_CONFIG[PAGES.CONSUMER],
    API.CMS_GET_GENERIC_ERROR_MOBILE
  ) as IGenericErrorCMS;
  const { errorcomponent: errorComponentOrder } =
    (useCmsConfig(
      CMS_CONFIG[PAGES.CONSUMER],
      API.CMS_GET_RECURRENT_ORDER_ERROR_MOBILE
    ) as ICMSApiError) || {};
  const orderErrors = errorComponentOrder?.errors || [];

  const { errorcomponent: errorComponentCustomer } =
    (useCmsConfig(CMS_CONFIG[PAGES.CONSUMER], API.CMS_GET_CUSTOMER_ERROR_MOBILE) as ICMSApiError) ||
    {};
  const customerErrors = errorComponentCustomer?.errors || [];

  const errors: ICMSError[] = [...orderErrors, ...customerErrors];

  const isLoadingCustomer =
    customerFlow?.postCustomer?.isLoading ||
    customerFlow?.patchCustomer?.isLoading ||
    customerFlow?.customerInfo?.isLoading ||
    customerFlow?.associateCustomer?.isLoading;
  const isErrorCustomer =
    customerFlow?.postCustomer?.isError ||
    customerFlow?.patchCustomer?.isError ||
    customerFlow?.customerInfo?.isError ||
    customerFlow?.associateCustomer?.isError;
  const errorCustomer =
    customerFlow?.postCustomer?.error ||
    customerFlow?.patchCustomer?.error ||
    customerFlow?.associateCustomer?.error;
  const isSuccessAssociate = customerFlow?.associateCustomer?.isSuccess;

  const {
    data: dataAddress,
    isSuccess: isSuccessAddress,
    isLoading: isLoadingAddress,
    isError: isErrorAddress,
  } = useAddressMobileFlow({
    onConfirmAddress: true,
  });

  const isSuccessDeliveryInfo =
    customerDeliveryBaseInfo?.deliveryBase?.isSuccess &&
    customerDeliveryBaseInfo?.modifySimDevice?.isSuccess;

  useEffect(() => {
    if (isErrorCustomer) {
      const errorDetail: IEditorialErrorConfig = checkErrorCustomer(
        errors,
        errorCustomer?.errorCode || '',
        {
          title: genericError?.genericerror?.title || 'Ops',
          description: genericError?.genericerror?.description || '',
        }
      );
      const getAction = (isPopup?: boolean) => {
        customerFlow?.resetPatchAndPost?.(customerFlow);
        switch (errorCustomer?.errorCode) {
          case ErrorCodes.CREATE_CUSTOMER_FISCAL_CODE_ALREADY_PRESENT:
            setSlidesGoBack(4);
            break;
          case ErrorCodes.CREATE_CUSTOMER_DOCUMENT_ALREADY_PRESENT:
          case ErrorCodes.CREATE_CUSTOMER_MISSING_NATION_ADDRESS:
            if (errorDetail?.isPopup && isPopup) {
              openPopup(errorDetail?.url || urlGenericErrorCallMeNow, () => {
                if (handleOnClose) {
                  handleOnClose();
                }
              });
            } else {
              setSlidesGoBack(2);
            }
            break;
          case ErrorCodes.CREATE_CUSTOMER_PHONE_NUMBER_ALREADY_PRESENT:
          case ErrorCodes.CREATE_CUSTOMER_EMAIL_ADDRESS_ALREADY_PRESENT:
          case ErrorCodes.CREATE_CUSTOMER_CONTACT_ALREADY_PRESENT:
            setSlidesGoBack(3);
            break;
          case ErrorCodes.ASSOCIATE_CUSTOMER_BLOCKED:
            if (errorDetail?.isPopup && isPopup) {
              openPopup(errorDetail?.url || urlGenericErrorCallMeNow, () => {
                if (handleOnClose) {
                  handleOnClose();
                }
              });
            } else if (handleOnClose) {
              handleOnClose();
            }
            break;
          default:
            if (handleOnClose) {
              handleOnClose();
            }
            break;
        }
      };
      checkoutErrors?.showAndTrackError?.(
        {
          genericerror: {
            title: errorDetail?.title || '',
            description: errorDetail?.message || '',
            buttonLabel: errorDetail?.actionText || 'Chiudi',
          },
        },
        getAction,
        errorCustomer,
        '',
        '',
        () => getAction(true),
        ''
      );
    }
  }, [isErrorCustomer]);

  useEffect(() => {
    if (isErrorAddress) {
      const closeModalAction = () => {
        handleGoPrevSlide?.();
      };
      checkoutErrors?.showAndTrackError?.(
        genericError,
        closeModalAction,
        errorMock('address', { url: 'validateAddress', status: '500', statusText: '500' }),
        undefined,
        undefined,
        closeModalAction,
        'Chiudi',
        () => openPopup(product?.genericErrorCallMeNow?.url || '', closeModalAction),
        product?.removeCTC ? undefined : product?.genericErrorCallMeNow?.label,
        isModalButtonSticky,
        true
      );
    }
  }, [isErrorAddress]);

  useEffect(() => {
    setIsDisabledBackButton(isLoadingCustomer || isLoadingAddress);
  }, [isLoadingCustomer, isLoadingAddress]);

  useEffect(() => {
    handleOnChangeEnableGoNext?.(isSuccessDeliveryInfo);
    setIsDisabledBackButton(!isSuccessDeliveryInfo);
  }, [isSuccessDeliveryInfo]);

  useEffect(() => {
    if (isStartRecognition) setIsStartRecognition(false);
  }, [isStartRecognition]);

  useEffect(() => {
    setIsStartRecognition(true);
  }, [isSuccessAssociate]);

  useEffect(() => {
    if (isSuccessAddress && dataAddress) {
      setBillingAddress(dataAddress);
      setShippingAddress(dataAddress);
      setIsStartCustomerFlow(true);
    }
  }, [isSuccessAddress, dataAddress]);

  useEffect(() => {
    setCurrentStepKey(ID_FLOWS.RECOGNITION_CARD_SKIP_MNP);
    localStorage.setItem('portability', '0');
  }, []);

  if (!isSuccessDeliveryInfo || isLoadingCustomer || isLoadingAddress) {
    return (
      <Skeleton>
        <ShadowsGroup quantity={4} heights={75} space={40} />
      </Skeleton>
    );
  }

  return <RecognitionCardMainSkipMnp {...props} />;
};

export default RecognitionCardSkipMnp;
