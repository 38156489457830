import React, { useRef, useLayoutEffect, Children, useState } from 'react';
import { ImageAtoms } from '@vfit/shared/atoms';
import { useCookie } from '@vfit/shared/hooks';
import { IHead } from '@vfit/shared/models';
import {
  MainContainer,
  CustomerTypeBox,
  OtherInfoBox,
  ListCustomer,
  ListOtherInfo,
  IconMarkerWrapper,
  Box,
} from './head.style';
import TrovaModal from '../TrovaPopUp/modal';

const Head: React.FC<IHead> = ({
  customerType,
  otherInfo,
  trovaNegozioPopup,
  getContainerHeight,
}) => {
  const containerRef = useRef() as React.MutableRefObject<HTMLDivElement>;
  const [isTrovaModal, setIsTrovaModal] = useState(false);
  const [_, updateCookie] = useCookie('session-domain', '');
  useLayoutEffect(() => {
    if (containerRef && containerRef.current && getContainerHeight) {
      getContainerHeight(containerRef.current.clientHeight);
    }
  }, [getContainerHeight]);

  const isValidWebUrl = (url: string) => {
    const regEx =
      /^https?:\/\/(?:www\.)?[-a-zA-Z0-9@:%._+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_+.~#?&/=]*)$/gm;
    return regEx.test(url);
  };

  const setCookie = (url: string, indexCookie: number) => {
    if (isValidWebUrl(url)) {
      const { origin: currentUrl } = new URL(url);
      if (indexCookie === 0) {
        updateCookie?.('', -1);
      } else {
        updateCookie?.(currentUrl, 20);
      }
    }
    window.location.href = url;
  };

  return (
    <MainContainer ref={containerRef}>
      <CustomerTypeBox>
        {customerType?.map(({ id, selected, url, category }, indexCookie) => (
          <ListCustomer key={id}>
            <a
              className={selected === 'true' ? 'selected' : ''}
              href="#"
              onClick={() => setCookie(url, indexCookie)}
            >
              {category}
            </a>
          </ListCustomer>
        ))}
      </CustomerTypeBox>
      <TrovaModal
        show={isTrovaModal}
        data={trovaNegozioPopup ?? {}}
        onCloseModal={() => setIsTrovaModal((prevState) => !prevState)}
      />
      <OtherInfoBox>
        {Children.toArray(
          otherInfo?.map((item, _id) => {
            const { logo, url, info } = item || { logo: { image: '' }, url: '', id: '' };
            return (
              <ListOtherInfo>
                <Box>
                  {url ? (
                    <a href={url} target="_blank">
                      {logo?.image && (
                        <IconMarkerWrapper>
                          <ImageAtoms
                            nameCard={`logo-${_id}`}
                            image={logo?.image}
                            alt="icon"
                            sizes="10px"
                            width="10px"
                          />
                        </IconMarkerWrapper>
                      )}
                      {info}
                    </a>
                  ) : (
                    <a className="otherinfo" onClick={() => setIsTrovaModal(true)}>
                      {logo?.image && (
                        <IconMarkerWrapper>
                          <ImageAtoms
                            nameCard={`logo-${_id}`}
                            image={logo?.image}
                            alt="icon"
                            sizes="15px"
                            width="15px"
                          />
                        </IconMarkerWrapper>
                      )}
                      {info}
                    </a>
                  )}
                </Box>
              </ListOtherInfo>
            );
          })
        )}
      </OtherInfoBox>
    </MainContainer>
  );
};
export default Head;
