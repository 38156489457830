import {
  CMS_CONFIG,
  ErrorCodes,
  errorMock,
  IGenericErrorCMS,
  useCmsConfig,
  useGetCustomerReference,
} from '@vfit/consumer/data-access';
import { useQueryClient } from 'react-query';
import {
  API,
  getFromLocalStorageByKey,
  openPopup,
  PAGES,
  trackView,
} from '@vfit/shared/data-access';
import { ITrackError, resetData } from '@vfit/shared/data-access';
import { useEffect } from 'react';
import {
  IAddressDataObject,
  ICMSApiError,
  ICMSError,
  ICommonData,
  ICountryObject,
} from '@vfit/shared/models';
import {
  getShippingForLoggedOrActive,
  getBillingForLoggedOrActive,
  getTrackError,
  retrieveOwningIndividual,
} from '../../checkout.utils';
import { useCheckout } from '../../../iBuyMobile.context';

export const usePostOtpFlow = (props: ICommonData) => {
  const { handleOnClose } = props;
  const {
    product,
    findCaller,
    customerFlow,
    customerAsyncInfo,
    owningData,
    checkoutErrors,
    isModalButtonSticky,
    setBillingAddress,
    setShippingAddress,
    setIsDisabledBackButton,
    setCustomerId,
    setOwningData,
    setIsStartCustomerFlow,
    setFindCaller,
  } = useCheckout();
  const queryClient = useQueryClient();
  const countries: ICountryObject[] = getFromLocalStorageByKey('getCountry');
  const otpVerifyResponse = { id: findCaller.verifyOtp };
  const {
    isSuccess: isReferenceSuccess,
    isLoading: isReferenceLoading,
    isError: isReferenceError,
    error: errorCustomerReference,
  } = useGetCustomerReference();
  const { associateCustomer } = customerFlow;
  const genericError = useCmsConfig(
    CMS_CONFIG[PAGES.CONSUMER],
    API.CMS_GET_GENERIC_ERROR_MOBILE
  ) as IGenericErrorCMS;
  const { errorcomponent: { errors } = { errors: [] } } =
    useCmsConfig(CMS_CONFIG[PAGES.CONSUMER], API.CMS_GET_RECURRENT_ORDER_ERROR_MOBILE) ||
    ({} as ICMSApiError);
  const blockedCustomer = errors?.find(
    (item: ICMSError) => item?.error === ErrorCodes.ASSOCIATE_CUSTOMER_BLOCKED
  );
  const isLoadingCustomerInfo =
    isReferenceLoading ||
    !isReferenceSuccess ||
    (!!otpVerifyResponse?.id && !associateCustomer.isSuccess) ||
    (!!otpVerifyResponse?.id && !customerFlow?.billingAccount?.isSuccess) ||
    customerAsyncInfo?.customerInfo?.isLoading ||
    customerFlow?.payment?.isLoading ||
    customerAsyncInfo?.termsAndConditions?.isLoading;

  const preconditionCustomer = () => {
    if (associateCustomer.isSuccess || associateCustomer.isError || isReferenceError) {
      setFindCaller({
        ...findCaller,
        ...(customerFlow?.findcaller?.data?.paymean && {
          paymean: customerFlow?.findcaller?.data?.paymean,
        }),
        showOtp: false,
        verifyOtp: '',
        isVerified: true,
      });
      if (
        associateCustomer.isError &&
        associateCustomer.error?.error?.statusText === ErrorCodes.ASSOCIATE_CUSTOMER_BLOCKED
      ) {
        if (associateCustomer.isError) {
          trackView(getTrackError(associateCustomer.error as ITrackError));
        }
        const handleClose = () => {
          if (handleOnClose) {
            resetData(queryClient);
            handleOnClose();
          }
        };
        const goBackAndReset = () => {
          if (blockedCustomer?.button?.urlForRedirect) {
            openPopup(blockedCustomer?.button?.urlForRedirect || '', () => {
              if (handleOnClose) {
                resetData(queryClient);
                handleOnClose();
              }
            });
          } else if (handleOnClose) {
            resetData(queryClient);
            handleOnClose();
          }
        };
        checkoutErrors?.showAndTrackError?.(
          genericError,
          () => handleClose(),
          undefined,
          blockedCustomer?.title || 'Attenzione',
          blockedCustomer?.message ||
            'I seguenti articoli non sono attivabili dal cliente selezionato',
          () => goBackAndReset(),
          blockedCustomer?.button?.title || 'Ti richiamiamo',
          () => openPopup(product?.genericErrorCallMeNow?.url || '', handleClose),
          product?.removeCTC ? undefined : product?.genericErrorCallMeNow?.label,
          isModalButtonSticky
        );
      } else if (associateCustomer.isError || isReferenceError) {
        if (isReferenceError && errorCustomerReference)
          trackView(
            getTrackError(errorMock(ErrorCodes.REFERENCE_CUSTOMER, errorCustomerReference))
          );
        if (associateCustomer.isError && customerAsyncInfo?.customerInfo?.isError)
          trackView(getTrackError(customerAsyncInfo?.customerInfo?.error as ITrackError));
        checkoutErrors?.showAndTrackError?.(
          genericError,
          handleOnClose,
          associateCustomer.isError && customerAsyncInfo?.customerInfo?.isError
            ? (customerAsyncInfo?.customerInfo?.error as ITrackError)
            : undefined,
          '',
          '',
          handleOnClose,
          genericError?.genericerror?.buttonLabel || '',
          () => openPopup(product?.genericErrorCallMeNow?.url || '', () => handleOnClose),
          product?.removeCTC ? undefined : product?.genericErrorCallMeNow?.label,
          isModalButtonSticky
        );
      }
    }
  };

  useEffect(() => {
    preconditionCustomer();
    if (associateCustomer.isSuccess) {
      const customer = customerAsyncInfo.customerInfo.data?.[0];
      if (customer) {
        const retrivedIndividual = retrieveOwningIndividual(
          customer,
          countries as ICountryObject[]
        );
        setOwningData({
          ...owningData,
          owningIndividual: {
            ...owningData.owningIndividual,
            ...retrivedIndividual,
          },
        });
        setCustomerId(customer.id);
        const postalAddress = getShippingForLoggedOrActive(customer);
        const billingAddress = getBillingForLoggedOrActive(customer);
        setShippingAddress(postalAddress as unknown as IAddressDataObject);
        setBillingAddress(billingAddress as unknown as IAddressDataObject);
      }
    }
  }, [associateCustomer, isReferenceError, customerAsyncInfo]);

  useEffect(() => {
    setIsDisabledBackButton(isLoadingCustomerInfo);
  }, [isLoadingCustomerInfo]);

  useEffect(() => {
    setFindCaller({
      ...findCaller,
      showOtp: false,
    });
    setIsStartCustomerFlow(true);
  }, []);

  return {
    isLoading: isLoadingCustomerInfo,
  };
};
