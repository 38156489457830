import { useQueryClient } from 'react-query';
import {
  CMS_CONFIG,
  ICharacteristicsDevices,
  IDevice,
  IPageProps,
  useCmsConfig,
  usePage,
  useTranslate
} from '@vfit/consumer/data-access';
import { SoftManagerService } from '@vfit/consumer/providers';
import { useTrackingProvider } from '@vfit/shared/data-access';
import { Footer, Header } from '@vfit/shared/components';
import { useSkuWindowLocation } from '@vfit/consumer/hooks';
import { API, PAGES, useFooter, useHeader } from '@vfit/shared/data-access';
import Widgets from '../../Widgets/widgets';
import { WidgetWrapper } from '../Layout/pageLayout.style';
import TopDevice from '../../TopDevice/topDevice';
import { withProtected } from '../Hoc/withProtected';
import TaggingDeviceDetails from './deviceDetails.tagging';
import BrightSky from '../../BrightSky/brightSky';

const DeviceDetail = ({ isApp }: IPageProps) => {
  const { page, pageNavigation, device } = usePage();
  const client = useQueryClient();
  const { isReady: isReadyTagging } = useTrackingProvider();
  const { elements, haveHeader, isHeaderSmall, haveFooter, isNewHeader } = page?.params || {};
  const { header, tagging } = useHeader(pageNavigation);
  const { footer } = useFooter(pageNavigation);
  const { translate } = useTranslate();
  const widgets = page?.params?.elements?.widgets;
  const haveStickyOffer = !!document?.getElementById('sticky-offer-product');
  const enablePaddingWidget = !haveFooter && haveStickyOffer;

  const deviceCharacteristics = useCmsConfig(
    CMS_CONFIG[PAGES.CONSUMER],
    API.CMS_GET_DEVICE_CHARACTERISTICS
  ) as ICharacteristicsDevices;

  const { selectedSku, changeSku } = useSkuWindowLocation(
    (device as IDevice)?.devicedetail?.models,
    deviceCharacteristics?.devicecharacteristic
  );

  const taggingProduct = SoftManagerService(client).getProductDevice(
    device as IDevice,
    selectedSku
  );

  client.setQueryData('pageDatalayer', {
    event: ['product_view'],
    event_label: SoftManagerService(client).getPromoLabel(
      (device as IDevice)?.devicedetail?.slug || ''
    ),
    opts: elements?.taggingOptions,
    pageProduct: taggingProduct,
    cartProduct: undefined,
    visitorTrackingOpts: SoftManagerService(client).getUser(),
  });

  return (
    <>
      {isReadyTagging && (
        <TaggingDeviceDetails
          device={device as IDevice}
          selectedSku={selectedSku}
          taggingOptions={elements?.taggingOptions}
          taggingProduct={taggingProduct}
        />
      )}
      {haveHeader && header && !isApp && (
        <Header
          onTrack={tagging}
          header={header}
          lite={isHeaderSmall}
          onTranslate={translate}
          isExtendedHeader={isNewHeader}
          topHeaderBanner={elements?.topheaderbanner}
        />
      )}
      <div className="noPadding">
        <TopDevice
          device={device as IDevice}
          selectedSku={selectedSku || (device as IDevice).devicedetail.models[0]}
          changeSku={changeSku}
          deviceCharacteristics={deviceCharacteristics}
        />
      </div>
      {widgets?.sliders && (
        <WidgetWrapper padding={enablePaddingWidget ? '5' : '0'}>
          <Widgets widgets={widgets?.sliders} tagging={tagging} />
        </WidgetWrapper>
      )}
      {haveFooter && footer && !isApp && (
        <WidgetWrapper padding={enablePaddingWidget ? '90' : '0'}>
          <Footer onTrack={`${tagging}_FOOTER`} footer={footer} subChildren={<BrightSky />} />
        </WidgetWrapper>
      )}
    </>
  );
};

const DeviceDetailProtected = withProtected()(DeviceDetail);

export default DeviceDetailProtected;
