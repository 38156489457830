import {IOfferingType} from "../getAddons";

export enum EndpointsEnum {
  ADD_FIXED_VOICE = "addFixedVoiceAddOnOfferingConfiguration",
  REMOVE_FIXED_VOICE = "removeFixedVoiceAddOnOfferingConfiguration",
  ADD_INTERNET = "addInternetAddOnOfferingConfiguration",
  REMOVE_INTERNET = "removeInternetAddOnOfferingConfiguration",
  ADD_DATA_SIM = "addDataSimAddOnOfferingConfiguration",
  REMOVE_DATA_SIM = "removeDataSimAddOnOfferingConfiguration"
}

export interface IUseAddonMutation {
  cartId: string;
  addonId: string;
  type: IOfferingType;
}
