import { ICMSWidgetType } from '@vfit/business-data-access';
import dynamic from 'next/dynamic';

import WidgetFaq from '../WidgetFaq/widgetFaq';
import WidgetSplittedList from '../WidgetSplittedList/widgetSplittedList';
import WidgetLargeSmallSlider from '../WidgetLargeSmallSlider/widgetLargeSmallSlider';
import WidgetSliderProductsHub from '../WidgetSliderProductsHub/widgetSliderProductsHub';
import WidgetProductHeroBanner from '../WidgetProductHeroBanner/widgetProductHeroBanner';
import WidgetCardsInfoProduct from '../WidgetCardsInfoProduct/widgetCardsInfoProduct';
import WidgetConnectionCards from '../WidgetConnectionCards/widgetConnectionCards';
import WidgetAddons from '../WidgetAddons/widgetAddons';
import WidgetHubDevice from '../WidgetHubDevice/widgetHubDevice';
import WidgetText from '../WidgetText/widgetText';
import WidgetDeviceHeroBanner from '../WidgetDeviceHeroBanner/widgetDeviceHeroBanner';
import WidgetSplittedTableText from '../WidgetSplittedTableText/widgetSplittedTableText';
import WidgetHubDigitalSolutions from '../WidgetHubDigitalSolutions/widgetHubDigitalSolutions';
import WidgetProductHeroBannerDS from '../WidgetProductHeroBannerDS/widgetProductHeroBannerDS';
import WidgetSliderProductDSMultiple from '../WidgetSliderProductDSMultiple/widgetSliderProductDSMultiple';

const WidgetTopHero = dynamic(() => import('../WidgetTopHero/widgetTopHero'), { ssr: false });
const WidgetOfferMap = dynamic(() => import('../WidgetOfferMapCard/widgetOfferMapCard'), { ssr: false });
const WidgetTecnologycard = dynamic(() => import('../WidgetTecnologyCards/widgetTecnologyCard'), { ssr: false });
const WidgetSlotCalendar = dynamic(() => import('../WidgetSlotCalendar/widgetSlotCalendar'), { ssr: false });
const WidgetSimActivationOtp = dynamic(() => import('../WidgetSimActivationOtp/widgetSimActivationOtp'), { ssr: false });
const WidgetSerialConfirmationOtp = dynamic(() => import('../WidgetSerialConfirmationOtp/widgetSerialConfirmationOtp'), { ssr: false });
const WidgetTabControlled = dynamic(() => import('../WidgetTabControlled/widgetTabControlled'), { ssr: false });
const WidgetSearchNation = dynamic(() => import('../WidgetSearchNation/widgetSearchNation'), { ssr: false });
const WidgetOperatorPlans = dynamic(() => import('../WidgetOperatorPlans/widgetOperatorPlans'), { ssr: false });
const WidgetGuidedSellingTool = dynamic(() => import('../WidgetGuidedSellingTool/widgetGuidedSellingTool'), { ssr: false });
const WidgetEditorialCard = dynamic(() => import('../WidgetEditiorialCard/widgetEditorialCard'), { ssr: false });
const WidgetEditorialCardSlider = dynamic(() => import('../WidgetEditiorialCardSlider/widgetEditorialCardSlider'), { ssr: false });
const WidgetSearchingOffersEu = dynamic(() => import('../WidgetSearchingOffersEu/widgetSearchingOffersEu'), { ssr: false });
const WidgetColumnItems = dynamic(() => import('../WidgetColumnItems/widgetColumnItems'), { ssr: false });
const WidgetWorldOffers = dynamic(() => import('../WidgetWorldOffers/widgetWorldOffers'), { ssr: false });
const WidgetFullBanner = dynamic(() => import('../WidgetFullBanner/widgetFullBanner'), { ssr: false });
const LandingEsteroPopUp = dynamic(() => import('../widgetLandingPopUp/landingEsteroPopUp'), { ssr: false });
const WidgetEsimInstruction = dynamic(()=>import('../WidgetEsimInstruction/widgetEsimInstruction'),{ssr:false})

export const WidgetComponents = {
  [ICMSWidgetType.FAQ]: WidgetFaq, // OK
  [ICMSWidgetType.SPLITTED_IMAGE_TEXT]: WidgetSplittedList, // OK
  [ICMSWidgetType.SLIDER_LARGE_SMALL]: WidgetLargeSmallSlider,
  [ICMSWidgetType.SLIDER_DEFAULT]: WidgetLargeSmallSlider,
  [ICMSWidgetType.SLIDER_PRODUCTS_HUB_BUSINESS]: WidgetSliderProductsHub, // use DXL info --> LOADER (OK)
  [ICMSWidgetType.PRODUCT_HERO_BANNER]: WidgetProductHeroBanner,  // use DXL info --> LOADER (OK)
  [ICMSWidgetType.SLIDER_ICON_TITLE_DESCRIPTION]: WidgetCardsInfoProduct, // OK
  [ICMSWidgetType.OFFER_MAP_SHOPPING_CART]: WidgetOfferMap,
  [ICMSWidgetType.SHOPPING_CART_TECHNOLOGIES]: WidgetTecnologycard,
  [ICMSWidgetType.SLIDER_IMAGE_TITLE_DESCRIPTION]: WidgetConnectionCards,
  [ICMSWidgetType.SLIDER_ADDON]: WidgetAddons, // use DXL info --> LOADER (OK)
  [ICMSWidgetType.HUB_DEVICE]: WidgetHubDevice, // use DXL info --> LOADER (OK)
  [ICMSWidgetType.TEXT]: WidgetText, // OK
  [ICMSWidgetType.DEVICE_HERO_BANNER]: WidgetDeviceHeroBanner, // use DXL info --> LOADER (OK)
  [ICMSWidgetType.SPLITTED_TABLE_TEXT]: WidgetSplittedTableText, // OK
  [ICMSWidgetType.HUB_DS]: WidgetHubDigitalSolutions, // use DXL info (OK)
  [ICMSWidgetType.DS_HERO_BANNER]: WidgetProductHeroBannerDS, // use DXL info --> LOADER (OK)
  [ICMSWidgetType.TOP_HERO]: WidgetTopHero,
  [ICMSWidgetType.TABS]: WidgetTabControlled,
  [ICMSWidgetType.SEARCH_NATION]: WidgetSearchNation,
  [ICMSWidgetType.OPERATOR_PLANS_MODAL]: WidgetOperatorPlans,
  [ICMSWidgetType.SLIDER_PRODUCT_DS_PDP]: WidgetSliderProductDSMultiple, // use DXL info --> LOADER (OK)
  [ICMSWidgetType.GUIDED_SELLING_TOOL]: WidgetGuidedSellingTool,
  [ICMSWidgetType.EDITORIAL_CARD]: WidgetEditorialCard,
  [ICMSWidgetType.SLOT_CALENDAR]: WidgetSlotCalendar,
  [ICMSWidgetType.EDITORIAL_CARD_SLIDER]: WidgetEditorialCardSlider,
  [ICMSWidgetType.SIM_ACTIVATION_OTP]: WidgetSimActivationOtp,
  [ICMSWidgetType.SEARCHING_OFFERS_EU]: WidgetSearchingOffersEu, // UI non verificabile perchè non usato su business
  [ICMSWidgetType.COLUMN_ITEMS]: WidgetColumnItems, // UI non verificabile perchè non usato su business
  [ICMSWidgetType.OFFERS_WORLD]: WidgetWorldOffers, // UI non verificabile perchè non usato su business
  [ICMSWidgetType.SERIAL_CONFIRMATION_OTP]: WidgetSerialConfirmationOtp,
  [ICMSWidgetType.FULL_BANNER]: WidgetFullBanner,
  [ICMSWidgetType.LANDING_ESTERO_POPUP]: LandingEsteroPopUp,
  [ICMSWidgetType.ESIM_INSTRUCTIONS]: WidgetEsimInstruction
};
