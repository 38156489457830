import { SoftManagerService } from '@vfit/consumer/providers';
import { useQueryClient } from 'react-query';
import { setTrackLink, setTrackView,TrackingOptions, useTracking } from '@vfit/shared/data-access';
import { ISlides } from '@vfit/consumer/data-access';

interface ITaggingHubApp {
  taggingOptions?: TrackingOptions;
  isDisabledInitialTrack?: boolean;
  catalogElements?: ISlides[];
}

const TaggingHubApp = ({
  taggingOptions,
  isDisabledInitialTrack,
  catalogElements,
}: ITaggingHubApp) => {
  const client = useQueryClient();
  const allWgtIds: string[] = [];
  const allWgtNames: string[] = [];
  const allWgtCategory: string[] = [];
  if (catalogElements && catalogElements?.length > 0) {
    catalogElements?.forEach((catalogElement) => {
      allWgtIds.push(catalogElement?.id?.toString() || '');
      allWgtNames.push(catalogElement?.title || '');
      allWgtCategory.push(catalogElement?.category || '');
    });
  }
  const { trackView } = useTracking({
    event: [taggingOptions?.event || 'view'],
    event_label: taggingOptions?.eventLabel || '',
    opts: {
      ...taggingOptions,
    },
    pageProduct: {
      product_id: allWgtIds,
      product_name: allWgtNames,
      product_category: allWgtCategory,
      product_price: '0',
      product_quantity: '0',
    },
    visitorTrackingOpts: SoftManagerService(client).getUser(),
    disableInitialTrack: isDisabledInitialTrack || false,
  });
  setTrackView(trackView);
  setTrackLink(trackView);
  return null;
};

export default TaggingHubApp;
