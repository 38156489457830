import { useEffect, useState } from 'react';
import { API, openPopup, PAGES } from '@vfit/shared/data-access';
import { CMS_CONFIG, IGenericErrorCMS, IPayments, useCmsConfig } from '@vfit/consumer/data-access';
import { IWarningInfo } from '@vfit/shared/models';
import { useTracking } from '@vfit/shared/data-access';
import UpdateCartCard from './components/UpdatingCartCard/updatingCartCard';
import ConvergenceModal from './components/ConvergenceModal/convergenceModal';
import { useCheckout } from '../../../iBuyMobile.context';
import { ICheckoutData } from '../../checkout.models';
import { getTagging, getUserInfo } from '../../checkout.utils';
import LoadingCard from '../LoadingCard/loadingCart';

const CampaignCard = ({ handleOnClose }: ICheckoutData) => {
  const {
    campaign: campaignData,
    product,
    checkoutErrors,
    isModalButtonSticky,
    setCampaign: setCamaignData,
    setIsStartAcceptCampaign,
  } = useCheckout();
  const { campaign, updateCart, accept } = campaignData || {};
  const [isChoosedAction, setIsChoosedAction] = useState(false);
  const isProductEligible = product?.isFamilyPlusProduct || product?.forceCampaign;
  const taggingData = getTagging('ko');
  const { trackWithNewDatalayer } = useTracking({
    event: ['view'],
    disableInitialTrack: true,
  });
  const genericError = useCmsConfig(
    CMS_CONFIG[PAGES.CONSUMER],
    API.CMS_GET_GENERIC_ERROR
  ) as IGenericErrorCMS;
  const API_PAYMENTS = product?.isTpoOffer
    ? API.CMS_GET_PAYMENTS_MOBILE
    : API.CMS_GET_FAMILY_PLUS_PAYMENT;
  const { paymentsmobile } = (useCmsConfig(
    CMS_CONFIG[PAGES.CONSUMER],
    API_PAYMENTS
  ) as IPayments) || {
    paymentsmobile: {
      warningPriceChange: {
        title: '',
        subtitle: '',
        description: '',
      },
    },
  };
  const showConvergenceModal =
    !isProductEligible &&
    campaignData?.campaign.isSuccess &&
    campaignData?.campaign?.isEligible &&
    !isChoosedAction;
  const showNotEligibleModal =
    campaignData?.campaign.isSuccess && !campaignData?.campaign?.isEligible && isProductEligible;
  const isCampaignProductAndEligible =
    campaignData?.campaign.isSuccess && campaignData?.campaign?.isEligible && isProductEligible;

  const onAcceptCampaign = () => {
    setIsStartAcceptCampaign(true);
    setIsChoosedAction(true);
  };

  const onRefuseCampaign = () => {
    setCamaignData({
      ...campaignData,
      campaign: {
        ...campaignData?.campaign,
        isRejected: true,
      },
    });
    setIsChoosedAction(true);
  };

  useEffect(() => {
    if (isCampaignProductAndEligible) {
      onAcceptCampaign();
    }
  }, [isCampaignProductAndEligible]);

  useEffect(() => {
    if (showNotEligibleModal || accept?.isError) {
      const showCtc =
        !product?.removeCTC &&
        product?.familyPlus?.noCampaign?.actionCtcLabel &&
        product?.familyPlus?.noCampaign?.actionCtcUrl;
      trackWithNewDatalayer({
        event: ['journey_end'],
        event_label: 'campaign not available for client',
        opts: {
          ...taggingData?.opts,
          event_category: 'journey',
          page_browser_system: 'browser',
        },
        cartProduct: taggingData?.trackingProduct,
        pageProduct: taggingData?.pageProductsInfo,
        visitorTrackingOpts: getUserInfo(),
      });
      checkoutErrors?.showAndTrackError?.(
        genericError,
        handleOnClose,
        undefined,
        product?.familyPlus?.noCampaign?.title || 'Al momento non abbiamo offerte riservate a te',
        product?.familyPlus?.noCampaign?.message ||
          'Ma non ti preoccupare, abbiamo altre offerte pronte a stupirti! ',
        showCtc
          ? () => openPopup(product?.familyPlus?.noCampaign?.actionCtcUrl || '', handleOnClose)
          : undefined,
        showCtc && !product?.removeCTC
          ? product?.familyPlus?.noCampaign?.actionCtcLabel
          : undefined,
        handleOnClose,
        product?.familyPlus?.noCampaign?.actionLabel || 'Scopri le altre offerte',
        isModalButtonSticky,
        undefined,
        true,
        true
      );
    }
  }, [showNotEligibleModal, accept?.isError]);

  const cmsWarningUpdateOffer: IWarningInfo = {
    title: paymentsmobile?.warningPriceChange?.title || '',
    subtitle: paymentsmobile?.warningPriceChange?.subtitle || '',
    description: paymentsmobile?.warningPriceChange?.description || '',
  };

  return (
    <>
      {updateCart?.isLoading && !product?.isTpoOffer && (
        <UpdateCartCard {...cmsWarningUpdateOffer} />
      )}
      {updateCart?.isLoading && product?.isTpoOffer && <LoadingCard />}
      {showConvergenceModal && (
        <ConvergenceModal
          isOpen
          handleClose={() => {
            window.open(product?.hubPath, '_self');
          }}
          handleContinue={onRefuseCampaign}
          product={campaign?.campaignProduct}
          handleAccept={onAcceptCampaign}
        />
      )}
    </>
  );
};

export default CampaignCard;
