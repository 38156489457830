import React, { useEffect, useState } from 'react';
import {
  CMS_CONFIG,
  useCmsConfig,
  ITermsAndCondition,
  BYPASS_CALL_API_SIA,
  productSpecificError,
  errorMock,
  IGenericErrorCMS,
} from '@vfit/consumer/data-access';
import { API, openPopup, PAGES, setTrackLink } from '@vfit/shared/data-access';
import { useTracking } from '@vfit/shared/data-access';
import { ICommonData } from '@vfit/shared/models';
import {useQueryClient} from "react-query";
import { organizeTermsCard } from './termsCard.utils';
import { ID_FLOWS } from '../../checkout.constants';
import { useCheckout } from '../../../iBuyFixed.context';
import { getUserInfo, getTagging } from '../../checkout.utils';
import PostAddress from './components/PostAddress/postAddress';
import TermsAndConditions from './components/TermsAndConditions/termsAndConditions';
import WithdrawalPopup from './components/WithdrawalPopup/withdrawalPopup';
import LoadingCard from '../LoadingCard/loadingCard';

const TermsCard = (props: ICommonData) => {
  const { handleGoNextSlide, handleOnChangeEnableGoNext, handleGoPrevSlide } = props;
  const {
    checkoutErrors,
    termsAndConditions,
    isNewShippingAddress,
    deliveryAdvance,
    isModalButtonSticky,
    product,
    setIsDisabledBackButton,
    setTermsAndConditions,
    setCurrentStepKey,
  } = useCheckout();
  const [isOpenWithdrawalPopUp, setOpenWithdrawalPopUp] = useState(false);
  const { buttonGenericError, urlGenericErrorCallMeNow } = productSpecificError(product);
  const queryClient = useQueryClient();
  const genericError = useCmsConfig(
    CMS_CONFIG[PAGES.CONSUMER],
    API.CMS_GET_GENERIC_ERROR
  ) as IGenericErrorCMS;
  const cmsData = useCmsConfig(
    CMS_CONFIG[PAGES.CONSUMER],
    API.CMS_GET_TERMS_AND_CONDITIONS
  ) as ITermsAndCondition;
  const editorialLabels = organizeTermsCard(cmsData);

  const { titleModal, messageModal } = editorialLabels;

  const tagging = getTagging('contract terms', 'fixed', queryClient);

  const { trackView } = useTracking({
    event: ['checkout_step7'],
    event_label: 'terms and conditions',
    opts: tagging.opts,
    cartProduct: tagging.trackingProduct,
    pageProduct: tagging?.pageProductsInfo,
    visitorTrackingOpts: getUserInfo(),
  });
  setTrackLink(trackView, 'terms and conditions');

  const handleSetNext = (item: string, checked: boolean) => {
    if (item) {
      const optionToReconsider: string = item === 'yes' ? 'no' : 'yes';
      setTermsAndConditions({
        ...termsAndConditions,
        consensus: checked,
        optionToReconsider,
      });
    }
  };

  const handleOpenPopupWithdrawal = (value: boolean) => {
    setOpenWithdrawalPopUp(value);
  };

  useEffect(() => {
    if (handleOnChangeEnableGoNext) {
      if (termsAndConditions.isSuccessTermsAndConditions && termsAndConditions.consensus) {
        handleOnChangeEnableGoNext(termsAndConditions.isSuccessTermsAndConditions);
      } else {
        handleOnChangeEnableGoNext(false);
        if (
          !termsAndConditions?.isLoadingTermsAndConditions &&
          !termsAndConditions?.isSuccessTermsAndConditions &&
          termsAndConditions?.isErrorTermsAndConditions
        ) {
          checkoutErrors?.showAndTrackError?.(
            genericError,
            handleGoPrevSlide,
            errorMock('termsAndConditions', {
              url: 'getTermsAndConditions',
              status: '500',
              statusText: '500',
            }),
            titleModal,
            messageModal,
            handleGoPrevSlide,
            'Chiudi',
            () => openPopup(urlGenericErrorCallMeNow, handleGoPrevSlide),
            buttonGenericError,
            isModalButtonSticky,
            true
          );
        }
      }
    }
  }, [termsAndConditions]);

  useEffect(() => {
    setIsDisabledBackButton(deliveryAdvance?.isLoading);
  }, [deliveryAdvance]);

  useEffect(() => {
    setCurrentStepKey(ID_FLOWS.TERMS_AND_CONDITIONS);
    localStorage.removeItem(BYPASS_CALL_API_SIA);
  }, []);

  if (deliveryAdvance?.isLoading || termsAndConditions.isLoadingTermsAndConditions) {
    return <LoadingCard />;
  }

  return (
    <>
      {isNewShippingAddress && <PostAddress {...props} />}
      {!isNewShippingAddress && (
        <>
          <TermsAndConditions
            setNextAble={handleSetNext}
            value={termsAndConditions}
            pdfContractSummary={product?.pdfContractSummary}
            onChangePopupWithdrawal={handleOpenPopupWithdrawal}
          />
          <WithdrawalPopup
            handleGoNextSlide={handleGoNextSlide}
            handleOnChangeEnableGoNext={handleOnChangeEnableGoNext}
            onChangePopupWithdrawal={handleOpenPopupWithdrawal}
            isOpenWithdrawalPopUp={isOpenWithdrawalPopUp}
          />
        </>
      )}
    </>
  );
};

export default TermsCard;
