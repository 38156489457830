/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from 'react';
import {
  useClearStorage,
  useLoggedCustomerFlow,
  useSoftManager,
  useABTest,
  useEcmpCookie,
} from '@vfit/consumer/hooks';
import { useRouter } from 'next/router';
import {
  ALL_EVENTS,
  isErrorOnPayment,
  isPersistData,
  useIdentify,
  WIRELESS_SECURITY_TOKEN,
} from '@vfit/consumer/data-access';
import {
  PAGES,
  checkWindow,
  getFromLocalStorageByKey,
  setItemCookie,
} from '@vfit/shared/data-access';

const LoggedCustomerFlow = () => {
  const { isSuccess, isError, errorSilentLogin } = useLoggedCustomerFlow();

  if (isSuccess) {
    setTimeout(() => {
      document.dispatchEvent(new CustomEvent(ALL_EVENTS.SILENT_LOGIN_COMPLETE, { detail: true }));
    }, 3000);
  }

  if (isError) {
    setTimeout(() => {
      document.dispatchEvent(
        new CustomEvent(ALL_EVENTS.SILENT_LOGIN_ERROR, {
          detail: errorSilentLogin || {
            errorMessage: 'Silent login error',
            errorCode: '500',
          },
        })
      );
    }, 3000);
  }
  return null;
};

/**
 * for this it is more correct to create a new provider
 * We are currently unable to create multiple providers per app
 * @constructor
 */
const CustomHookFlow = () => {
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const { asPath } = useRouter();
  const isShoppingCart = asPath.includes(PAGES.SHOPPING_CART);
  const isHomepage = asPath === '/';
  const isOffersPage = asPath.includes('/offers');
  const isAbroadPage = asPath.includes('/tariffe-estero');
  const isEnrgPage = asPath.includes('/energy');
  const isHubAppPage = asPath.includes('/landing-app-wow');
  const isTpoPage = asPath.includes('/offertaclientifastwebvodafone');
  const isLoggedCustomerFlow = [
    !isLoading,
    !isHomepage,
    !isOffersPage,
    !isAbroadPage,
    !isEnrgPage,
    !isHubAppPage,
    !isTpoPage,
  ].every((condition) => condition);

  useClearStorage();
  useSoftManager();
  useABTest();
  useEcmpCookie();
  // Remove TMP enrichment
  // useEnrichment();
  const { refetch: refetchIdentify } = useIdentify({ enabled: false });

  useEffect(() => {
    setIsLoading(false);
  }, []);

  useEffect(() => {
    if (checkWindow() && !isLoading) {
      if (!getFromLocalStorageByKey('identify')) refetchIdentify();
      if (isShoppingCart && !isPersistData() && !isErrorOnPayment() && sessionStorage) {
        // handle 'uxfauthorization' token clear
        sessionStorage.removeItem('uxfauthorization');
        setItemCookie(WIRELESS_SECURITY_TOKEN, '', 0);
      }
    }
  }, [isLoading]);

  return isLoggedCustomerFlow && !isPersistData() && <LoggedCustomerFlow />;
};

export default CustomHookFlow;
