import { useEffect, useState } from 'react';
import { useQueryClient } from 'react-query';
import {
  CMS_CONFIG,
  errorMock,
  IGlobalConfigCMS,
  IShoppingCartResponse,
  SELECTED_PAYMENT,
  useAuthorizationOrder,
  useCmsConfig,
  useGetPaymean,
  useLightCreditVettingMutation,
  useNotify,
  usePaymentDistributionMobile,
  useReserveEquipmentMutation,
  useSubmitMobileMutation,
  useSubmitWithPaymentMobile,
  useValidateIbansMobile,
} from '@vfit/consumer/data-access';
import { ITrackError, LoggerInstance } from '@vfit/shared/data-access';
import { EMethodCode, Path } from '@vfit/shared/models';
import {
  API,
  getFromLocalStorageByKey,
  PAGES,
  usePostCustomerEligibility,
} from '@vfit/shared/data-access';
import { useCheckout } from '../../../iBuyMobile.context';
import { useTpoCheck } from '../../../ShoppingCart/hooks/useTpoCheck';

interface IUseCheckoutMobileFlow {
  ignoreQueryString?: boolean;
}

// API FLOW
// Check eligibility
// Authorization
// Paymean
// Payment distribution
// validate iban only if is IBAN
// reserve equipment
// light credit vetting
// modifyPhoneNumberPortability (only if the portIn was choosen in checkout flow)
// submit / submitWithPayment
// post eligibility if is TPO e NOT CAMPAIGN

export const useCheckoutFlow = ({ ignoreQueryString }: IUseCheckoutMobileFlow) => {
  const shoppingCart: IShoppingCartResponse = getFromLocalStorageByKey('shoppingCart');
  const queryString = window.location.search;
  const { portability, portabilityStatus, setPortabilityStatus } = useCheckout();
  const globalConfig = useCmsConfig(
    CMS_CONFIG[PAGES.CONSUMER],
    API.CMS_GET_GLOBAL_CONFIG
  ) as IGlobalConfigCMS;

  // setting internal hook state
  const [isSuccess, setIsSuccess] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [isError, setIsError] = useState<boolean>(false);
  const [error, setError] = useState<ITrackError>({
    errorMessage: '',
    error: undefined,
  });

  const queryClient = useQueryClient();
  const authorizationError: ITrackError | undefined =
    queryClient.getQueryData('authorizationError');
  const paymentDistributionError: ITrackError | undefined =
    queryClient.getQueryData('paymentDistribution');
  const reserveEquipmentError: ITrackError | undefined =
    queryClient.getQueryData('reserveEquipment');
  const lightCreditVettingError: ITrackError | undefined =
    queryClient.getQueryData('lightCreditVetting');
  const validateIbanError: ITrackError | undefined = queryClient.getQueryData('validateIbansQuery');
  const modifyPhoneNumberProductError: ITrackError | undefined = queryClient.getQueryData(
    'modifyPhoneNumberProductQuery'
  );
  const submitError: ITrackError | undefined = queryClient.getQueryData('submitQuery');

  // (1) Check eligibility
  const { isTpoOffer, isInCampaignTpo, isLoadingCheck, isEligible, token } = useTpoCheck();

  // (2) calling hook mutation
  const {
    refetch: refetchAuthorization,
    data: authorizationOrderData,
    isSuccess: isSuccessAuthorizationOrder,
    isError: isErrorAuthorizationOrder,
    isLoading: isLoadingAuthorizationOrder,
  } = useAuthorizationOrder({
    enabled: false,
  });
  const {
    isLoading: isLoadingValidateIban,
    mutate: mutateValidateIban,
    isError: isErrorValidateIban,
    isSuccess: isSuccessValidateIban,
  } = useValidateIbansMobile();

  const { isError: isErrorPayMean, isLoading: isLoadingPayMean } = useGetPaymean(
    undefined,
    globalConfig
  );

  // (3) calling notify
  const notifyMutation = useNotify();

  // (4) calling hook mutation
  const paymentDistributionMutation = usePaymentDistributionMobile();
  const selectedPaymentMethod = localStorage.getItem(SELECTED_PAYMENT);

  // (5) calling hook mutation
  const reserveEquipmentMutation = useReserveEquipmentMutation();

  // (6) calling hook mutation or lightCreditVettingMutation
  const lightCreditVettingMutation = useLightCreditVettingMutation();

  // (7) calling hook mutation final
  const submitCCMutation = useSubmitMobileMutation();
  const submitWithPaymentMutation = useSubmitWithPaymentMobile();
  const submitMutation =
    selectedPaymentMethod?.toLowerCase() === EMethodCode.CREDIT_CARD.toLowerCase()
      ? submitCCMutation
      : submitWithPaymentMutation;

  // (8) post submit eligibility
  const {
    isLoading: isLoadingPostCustomerEligibility,
    isSuccess: isSuccessPostCustomerEligibility,
    isError: isErrorPostCustomerEligibility,
    mutate: mutatePostCustomerEligibility,
  } = usePostCustomerEligibility();

  const isIban = selectedPaymentMethod?.toLowerCase() === EMethodCode.BANK_ACCOUNT.toLowerCase();

  /**
   * Submit for portability condition
   */
  useEffect(() => {
    if (
      portabilityStatus?.isSuccess &&
      portabilityStatus?.isStartFinalPortIn &&
      lightCreditVettingMutation?.isSuccess &&
      !submitMutation?.isSuccess &&
      !submitMutation?.isLoading &&
      !submitMutation?.isError
    ) {
      submitMutation.mutate();
    } else if (portabilityStatus?.isError) {
      setIsLoading(false);
      setIsSuccess(false);
      setIsError(true);
      setError(modifyPhoneNumberProductError as ITrackError);
    }
  }, [
    portabilityStatus,
    lightCreditVettingMutation?.isSuccess,
    submitMutation?.isSuccess,
    submitMutation?.isLoading,
  ]);

  useEffect(() => {
    if (!isLoadingPostCustomerEligibility) {
      if (isSuccessPostCustomerEligibility || isErrorPostCustomerEligibility) {
        setIsError(false);
        setIsLoading(false);
        setIsSuccess(true);
      }
    }
  }, [
    isSuccessPostCustomerEligibility,
    isLoadingPostCustomerEligibility,
    isErrorPostCustomerEligibility,
  ]);

  /**
   * After Submit hook to finalize the flow
   * setting internal state isLoading=false && isSuccess=true
   */
  useEffect(() => {
    if (!submitMutation.isLoading) {
      if (submitMutation.isSuccess) {
        if (isTpoOffer && !isInCampaignTpo) {
          mutatePostCustomerEligibility({
            t: token || '',
            idOrder: shoppingCart?.id,
          });
        } else {
          setIsError(false);
          setIsLoading(false);
          setIsSuccess(true);
        }
      } else if (submitMutation.isError && submitError) {
        setIsLoading(false);
        setIsSuccess(false);
        setIsError(true);
        setError(submitError);
      }
    }
  }, [submitMutation.isSuccess, submitMutation.isLoading, submitMutation.isError, submitError]);

  useEffect(() => {
    if (isErrorValidateIban) {
      setIsLoading(false);
      setIsSuccess(false);
      setIsError(true);
      setError(validateIbanError as ITrackError);
    } else if (isSuccessValidateIban) {
      reserveEquipmentMutation.mutate();
    }
  }, [isSuccessValidateIban, isLoadingValidateIban, isErrorValidateIban]);

  /**
   * LightCreditVetting conditioned by portability if chosen
   */
  useEffect(() => {
    if (!lightCreditVettingMutation.isLoading) {
      if (lightCreditVettingMutation.isSuccess) {
        LoggerInstance.debug(
          'useCheckoutFlow lightCreditVettingMutation.data',
          lightCreditVettingMutation.data
        );
        if (
          portability?.portabilityWant &&
          portabilityStatus?.isSuccessCheck
        ) {
          setPortabilityStatus({
            ...portabilityStatus,
            isStartFinalPortIn: true,
          });
        } else if (!portability?.portabilityWant && !submitMutation?.isError) {
          submitMutation.mutate();
        }
      } else if (lightCreditVettingMutation.isError && lightCreditVettingError) {
        setIsLoading(false);
        setIsSuccess(false);
        setIsError(true);
        setError(lightCreditVettingError);
      }
    }
  }, [
    lightCreditVettingMutation.isLoading,
    lightCreditVettingMutation.isSuccess,
    lightCreditVettingMutation.isError,
    lightCreditVettingError,
    portability,
  ]);

  /**
   * handlerPaymentType hook conditioned by reserveEquipmentMutation.data
   */
  useEffect(() => {
    if (!reserveEquipmentMutation.isLoading) {
      if (reserveEquipmentMutation.isSuccess) {
        if (reserveEquipmentMutation?.data?.status === 'KO') {
          setIsLoading(false);
          setIsSuccess(false);
          setIsError(true);
          setError(
            errorMock(
              'reserveEquipment',
              {
                status: '200',
                statusText: 'KO',
                url: API.RESERVE_EQUIPMENT,
              },
              'KO'
            )
          );
        } else {
          lightCreditVettingMutation.mutate();
        }
      } else if (reserveEquipmentMutation.isError && reserveEquipmentError) {
        setIsLoading(false);
        setIsSuccess(false);
        setIsError(true);
        setError(reserveEquipmentError);
      }
    }
  }, [
    reserveEquipmentMutation.isSuccess,
    reserveEquipmentMutation.isError,
    reserveEquipmentMutation.isLoading,
    reserveEquipmentError,
  ]);

  /**
   * ReserveEquipment mutation initializer conditioned by paymentDistributionMutation.data
   */
  useEffect(() => {
    if (!paymentDistributionMutation.isLoading) {
      if (paymentDistributionMutation.isSuccess) {
        if (paymentDistributionMutation.data?.error) {
          setIsLoading(false);
          setIsSuccess(false);
          setIsError(true);
          setError(paymentDistributionMutation.data as ITrackError);
        } else if (isIban) {
          mutateValidateIban();
        } else {
          reserveEquipmentMutation.mutate();
        }
      } else if (paymentDistributionMutation.isError && paymentDistributionError?.error) {
        setIsLoading(false);
        setIsSuccess(false);
        setIsError(true);
        setError(paymentDistributionError);
      }
    }
  }, [
    paymentDistributionMutation.isLoading,
    paymentDistributionMutation.isSuccess,
    paymentDistributionMutation.isError,
    paymentDistributionError,
  ]);

  /**
   * PaymentDistribution mutation initializer conditioned by authorizationOrderData
   */
  useEffect(() => {
    LoggerInstance.debug('queryString', queryString);
    if (queryString?.includes(Path.URL_FORWARD) || ignoreQueryString) {
      if (isSuccessAuthorizationOrder && !isLoadingAuthorizationOrder) {
        if (authorizationError?.error) {
          setIsLoading(false);
          setIsSuccess(false);
          setIsError(true);
          setError(authorizationError);
        } else {
          LoggerInstance.debug('useCheckoutFlow authorizationOrderData : ', authorizationOrderData);
          LoggerInstance.debug('useCheckoutFlow calling paymentDistributionMutation.mutate()');
          notifyMutation.mutate();
          paymentDistributionMutation.mutate();
        }
      } else if (
        (isErrorPayMean && !isLoadingPayMean) ||
        (isErrorAuthorizationOrder && !isLoadingAuthorizationOrder)
      ) {
        setIsLoading(false);
        setIsSuccess(false);
        setIsError(true);
        const paymeanError: ITrackError = getFromLocalStorageByKey('payMean');
        setError(paymeanError);
      }
    }
  }, [
    isLoadingAuthorizationOrder,
    isErrorAuthorizationOrder,
    isSuccessAuthorizationOrder,
    isErrorPayMean,
  ]);

  useEffect(() => {
    if (!isLoadingCheck) {
      if (!isTpoOffer || (isTpoOffer && isEligible)) {
        refetchAuthorization();
      }
    }
  }, [isLoadingCheck]);

  return {
    isLoading,
    isSuccess,
    isError,
    error,
  };
};
