import { useEffect, useLayoutEffect, useRef, useState } from 'react';
import { useQueryClient } from 'react-query';
import { LoggerInstance, errorManager, ErrorService, ITrackError } from '@vfit/shared/data-access';
import {
  ALL_EVENTS,
  CMS_CONFIG,
  FLOW_TYPE,
  LOGGED_USER_KEYS,
  PAYMENT_ERROR_MESSAGE,
  PAYMENT_ERROR_TITLE,
  PAYMENT_SHOW_CTC,
  SELECTED_PAYMENT,
  ErrorCodes,
  IGlobalConfigCMS,
  IGenericErrorCMS,
  IPayments,
  IProduct,
  checkIsGoBackSIA,
  getLastPaymentMethod,
  productSpecificError,
  useCmsConfig,
  checkIsApp,
  getCurrentUserType,
  IUserType,
} from '@vfit/consumer/data-access';
import {
  ICMSApiError,
  ICMSError,
  ICountryObject,
  IPayMeanServiceResponse,
  ISIAFlowType,
  IStepCard,
  IStepperUtils,
} from '@vfit/shared/models';
import { LoadingSpinner } from '@vfit/shared/atoms';
import { API, getFromLocalStorageByKey, openPopup, PAGES } from '@vfit/shared/data-access';
import { ISupportModule, StepperStateCards } from '@vfit/shared/components';
import {ProductDetailExtended} from "@vfit/consumer/components";
import { CheckoutModalContainer, LoadingPage, OverlayGlobalStyle } from './checkout.style';
import { ICheckoutModalChildrenProps, ICheckoutSIA } from './checkout.models';
import {
  addRemoveVisibilityCards,
  deSeriealizeAllSteps,
  getActiveSlideFromStorage,
  getSlideIndexByKey,
  getUserFlow,
  isErrorOnInstant,
  isErrorOnPaymentGeneric,
  organizeFlowsAppend,
  organizePaymentError,
  seriealizeAllSteps,
  isTechFWA,
  checkIsShowUpfront,
  getInstallmentOptionsFWA,
} from './checkout.utils';
import DebugSIACard from './components/DebugSIACard/debugSIACard';
import { isAppLockin } from '../ShoppingCart/shoppingCart.utils';
import { useCustomerAsyncInformation } from './hooks/UseCustomerAsyncInformation/useCustomerAsyncInformation';
import { useCustomerDeliveryBase } from './hooks/UseCustomerDeliveryBase/useCustomerDeliveryBase';
import { useCartAsyncInformation } from './hooks/UseCartAsyncInformation/useCartAsyncInformation';
import { useCheckoutErrors } from './hooks/UseCheckoutErrors/useCheckoutErrors';
import { useDeliveryBillingFlow } from './hooks/UseDeliveryBillingFlow/useDeliveryBillingFlow';
import { useDeliveryLocateAndPickupPoint } from './hooks/UseDeliveryLocateAndPickupPoint/useDeliveryLocateAndPickupPoint';
import { useAuthorizationAsyncInformation } from './hooks/UseAuthorizationAsyncInformation/useAuthorizationAsyncInformation';
import { useCustomerFlow } from './hooks/UseCustomerFlow/useCustomerFlow';
import { useVoucherDiscount } from './hooks/UseVoucherDiscount/useVoucherDiscount';
import { usePortabilityFlow } from './hooks/UsePortabilityFlow/usePortabilityFlow';
import { useAuthorizeFlow } from './hooks/UseAuhorizeFlow/useAuthorizeFlow';
import { useLockInMMFlow } from './hooks/UseLockInMMFlow/useLockInMMFlow';
import { useLockInAppFlow } from './hooks/UseLockInAppFlow/useLockInAppFlow';
import { useLockInFlow } from './hooks/UseLockInFlow/useLockInFlow';
import PreCheckout from './components/PreCheckout/preCheckout';
import { useCheckout } from '../iBuyFixed.context';
import { ENABLE_DEBUG, isEnabledWaitingCart } from '../iBuyFixed.utils';
import {
  OTP_FLOW,
  PAYMENT_METHOD_WITHOUT_RECURRING_FLOW,
  PORTABILITY_FLOW,
  UPFRONT_SELECTION_FLOW,
} from './checkout.flow';
import { ID_FLOWS } from './checkout.constants';
import { useCookieDeleteCart } from './hooks/UseCookieDeleteCart/useCookieDeleteCart';
import {useAddonFlow} from "./hooks/UseAddonFlow/useAddonFlow";

const Checkout = ({
  handleClose,
  backgroundImage,
  isLoadingCart,
  isErrorOnPaymentShowErrorModal,
  hideCards,
}: ICheckoutModalChildrenProps) => {
  const {
    owningData,
    isLastCard,
    findCaller,
    slidesGoBack,
    isDisabledBackButton,
    currentStepKey,
    hideStickyBar,
    shippingAddress,
    isNewShippingAddress,
    pickupLocationStore,
    customerFlow,
    configuredStickyStepperLabel,
    isModalButtonSticky,
    checkoutErrors,
    product,
    portability,
    addons,
    addonState,
    setIsStartCustomerFlow,
    setPickupLocationStore,
    setBillingReplace,
    setSlidesGoBack,
    initContext,
    setOfferId,
    checkInitContext,
    deleteSerializedContext,
    serializeContext,
    setIsLoadingCart,
    setIsNewShippingAddress,
    setIsModalButtonSticky,
    setAddonState
  } = useCheckout();
  const IS_LOADING_CART = isEnabledWaitingCart() ? isLoadingCart : false;
  const queryClient = useQueryClient();
  const { paymean } = customerFlow;
  const stepperRef = useRef<IStepperUtils>();
  const [checkoutSIA, setCheckoutSIA] = useState<ICheckoutSIA>({
    isShow: false,
  });
  const IS_DISABLED_REDIRECT = localStorage.getItem('disableRed');
  const silentLogin: any = queryClient.getQueryData('silentLogin');
  const isLoggedUser = LOGGED_USER_KEYS.includes(silentLogin?.flowId) || false;
  const flow = checkIsGoBackSIA() ? deSeriealizeAllSteps(product) : getUserFlow(product);
  const [steps, setSteps] = useState<IStepCard[]>(flow);
  const [showPageLoader, setShowPageLoader] = useState<boolean>(checkIsGoBackSIA());
  const [hideInternalComponents, setHideInternalComponents] = useState<boolean>(checkIsGoBackSIA());

  const globalConfig = useCmsConfig(
    CMS_CONFIG[PAGES.CONSUMER],
    API.CMS_GET_GLOBAL_CONFIG
  ) as IGlobalConfigCMS;

  const isButtonStickyConfig = globalConfig?.globalconfig?.modals?.enableButtonSticky === 'true';
  setIsModalButtonSticky(isButtonStickyConfig);

  // region Hooks API

  const {
    startDeliveryLocateAndPickupPoint,
    global: globalPPOrPO,
    searchPickupLocations,
    searchLocateStore,
    searchPostalOffice,
  } = useDeliveryLocateAndPickupPoint();

  usePortabilityFlow();

  useCustomerAsyncInformation();

  useCustomerDeliveryBase();

  useCartAsyncInformation();

  useAuthorizationAsyncInformation();

  useCustomerFlow();

  useAuthorizeFlow();

  useVoucherDiscount();

  useLockInMMFlow();

  useLockInFlow();

  useLockInAppFlow();

  useAddonFlow();

  // Hook for delete cart by cookie. For ibuy fixed is disabled for now
  useCookieDeleteCart();

  const {
    createAddress: createBillingAddress,
    validateAddress,
    replaceAddress: replaceBillingAddress,
    billDelivery,
  } = useDeliveryBillingFlow();

  useEffect(() => {
    setPickupLocationStore({
      ...pickupLocationStore,
      startDeliveryLocateAndPickupPoint,
      global: { ...globalPPOrPO },
      searchPostalOffice: { ...searchPostalOffice },
      searchLocateStore: { ...searchLocateStore },
      searchPickupLocations: { ...searchPickupLocations },
    });
  }, [globalPPOrPO, searchPickupLocations, searchPostalOffice]);

  useEffect(() => {
    setBillingReplace({
      createAddress: {
        ...createBillingAddress,
      },
      validateAddress: {
        ...validateAddress,
      },
      replaceAddress: {
        ...replaceBillingAddress,
      },
      billDelivery: {
        ...billDelivery,
      },
    });
  }, [createBillingAddress, validateAddress, replaceBillingAddress, billDelivery]);

  useEffect(() => {
    if (!isNewShippingAddress) setIsNewShippingAddress(true);
  }, [shippingAddress]);

  useCheckoutErrors({ handleClose, replaceBillingAddress, createBillingAddress });

  // endregion

  // region Organize CMS
  const dataFromCms = useCmsConfig(CMS_CONFIG[PAGES.CONSUMER], API.CMS_GET_PAYMENTS) as IPayments;
  const errorList = useCmsConfig(
    CMS_CONFIG[PAGES.CONSUMER],
    API.CMS_GET_ORDER_ERROR
  ) as ICMSApiError;
  const supportModulesCms = useCmsConfig(
    CMS_CONFIG[PAGES.CONSUMER],
    API.CMS_GET_SUPPORT_MODULE
  ) as ISupportModule;
  const genericError = useCmsConfig(
    CMS_CONFIG[PAGES.CONSUMER],
    API.CMS_GET_GENERIC_ERROR
  ) as IGenericErrorCMS;

  const siaError = errorList?.errorcomponent?.errors?.find(
    (item: ICMSError) => item?.error === ErrorCodes.ERROR_ON_PAYMENT_GENERIC
  );

  const { messageErrorPayment, actionTextErrorPayment, titleErrorPayment } =
    organizePaymentError(dataFromCms);

  const { buttonPaymentError, urlPaymentErrorCallMeNow } = productSpecificError(
    product as IProduct
  );

  // endregion

  // region Append steps

  /**
   * On remove cards, this method update the stepper's active index
   * @param organizedStep
   */
  const updateActiveIndexOnRemoveCards = (organizedStep: IStepCard[]) => {
    const diffSteps = steps.length - organizedStep.length;
    if (diffSteps > 0 && stepperRef.current) {
      const diff = stepperRef.current._getActiveSlideIndex() - diffSteps;
      stepperRef.current._setActiveSlideIndex(diff < 0 ? 0 : diff);
    }
  };

  const appendPortabilitySteps = () => {
    let organizedStepPortabilityLine: IStepCard[] = steps;
    const portabilityLineCard = isAppLockin()
      ? ID_FLOWS.PORTABILITY_LINE_APP
      : ID_FLOWS.PORTABILITY_LINE;

    switch (portability.portabilityLine) {
      case 'internetOnly':
        organizedStepPortabilityLine = organizeFlowsAppend(
          steps,
          false,
          portabilityLineCard,
          PORTABILITY_FLOW.PORTABILITY
        );
        organizedStepPortabilityLine = organizeFlowsAppend(
          organizedStepPortabilityLine,
          true,
          portabilityLineCard,
          PORTABILITY_FLOW.PORTABILITY_INTERNET
        );
        break;
      case 'yes':
        organizedStepPortabilityLine = organizeFlowsAppend(
          steps,
          false,
          portabilityLineCard,
          PORTABILITY_FLOW.PORTABILITY_INTERNET
        );
        organizedStepPortabilityLine = organizeFlowsAppend(
          organizedStepPortabilityLine,
          true,
          portabilityLineCard,
          PORTABILITY_FLOW.PORTABILITY
        );
        break;
      default:
        organizedStepPortabilityLine = organizeFlowsAppend(
          steps,
          false,
          portabilityLineCard,
          PORTABILITY_FLOW.PORTABILITY_INTERNET
        );
        organizedStepPortabilityLine = organizeFlowsAppend(
          organizedStepPortabilityLine,
          false,
          portabilityLineCard,
          PORTABILITY_FLOW.PORTABILITY
        );
        organizedStepPortabilityLine = organizeFlowsAppend(
          organizedStepPortabilityLine,
          false,
          portabilityLineCard,
          PORTABILITY_FLOW.PORTABILITY_INFO
        );
        break;
    }
    const organizedStepPortabilityWant = organizeFlowsAppend(
      organizedStepPortabilityLine,
      portability?.portabilityWant || false,
      ID_FLOWS.PORTABILITY,
      PORTABILITY_FLOW.PORTABILITY_INFO
    );
    const organizedStepPortabilityInfo = organizeFlowsAppend(
      organizedStepPortabilityWant,
      portability.portabilityInfo === 'TelInt',
      ID_FLOWS.PORTABILITY_INFORMATIONS,
      PORTABILITY_FLOW.PORTABILITY_OPERATORS
    );
    setSteps(organizedStepPortabilityInfo);
  };

  const appendOtpAndPaymentSteps = () => {
    if (checkIsGoBackSIA()) return;
    const installmentOptions = getInstallmentOptionsFWA();
    const showUpfrontSelection =
      isTechFWA() &&
      installmentOptions?.options?.length > 1 &&
      product?.checkoutFWA?.items &&
      product?.checkoutFWA?.items?.length > 0;
    const paymentData = getLastPaymentMethod(paymean?.data as IPayMeanServiceResponse);
    const appendCardForCreditCard =
      (paymean?.isSuccess &&
        paymean?.data?.payMeans &&
        paymean?.data?.payMeans?.length > 0 &&
        getCurrentUserType() !== IUserType.NEXT_USER_INACTIVE) ||
      false;
    if (paymentData) {
      localStorage.setItem(SELECTED_PAYMENT, paymentData.type);
    }
    let organizedSteps = steps;
    const fromKeySteps = ID_FLOWS.PERSONAL_INFO;
    if (!findCaller.showOtp) {
      organizedSteps = organizeFlowsAppend(steps, false, fromKeySteps, OTP_FLOW.OTP);
    } else if (!isAppLockin()) {
      organizedSteps = organizeFlowsAppend(
        steps,
        findCaller.showOtp || false,
        fromKeySteps,
        OTP_FLOW.OTP
      );
    }
    // remove upfront selection for fwa
    if (!showUpfrontSelection) {
      organizedSteps = organizeFlowsAppend(
        organizedSteps,
        false,
        ID_FLOWS.CONSENTS,
        UPFRONT_SELECTION_FLOW.UPFRONT_SELECTION
      );
    }
    if (!product?.enableRecurringPayment && !product?.enableVoucher) {
      organizedSteps = organizeFlowsAppend(
        organizedSteps,
        appendCardForCreditCard,
        showUpfrontSelection ? ID_FLOWS.UPFRONT_SELECTION : ID_FLOWS.CONSENTS,
        PAYMENT_METHOD_WITHOUT_RECURRING_FLOW.PAYMENT_METHOD
      );
    } else {
      organizedSteps = organizeFlowsAppend(
        organizedSteps,
        appendCardForCreditCard,
        ID_FLOWS.VOUCHER,
        PAYMENT_METHOD_WITHOUT_RECURRING_FLOW.PAYMENT_METHOD
      );
    }
    setSteps(organizedSteps);
    updateActiveIndexOnRemoveCards(organizedSteps);
  };

  // endregion

  /**
   * Method to go back programmatically from context
   */
  const programmaticallyGoBackNext = async (
    numberSlides: number,
    type: 'NEXT' | 'BACK',
    onFinish?: () => void
  ) => {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    const delay = (time: number) => new Promise((resolve) => setTimeout(resolve, time));
    if (numberSlides !== -1) {
      // eslint-disable-next-line no-restricted-syntax
      for (const indexSlides of Array.from(Array(numberSlides - 1))) {
        LoggerInstance.debug('IndexSlides', indexSlides);
        if (type === 'NEXT') {
          stepperRef?.current?._stepperGoNext();
          await delay(100);
        } else {
          stepperRef?.current?._stepperGoPrev();
          await delay(600);
        }
      }
      // setSlidesGoBack(-1);
      setTimeout(() => {
        if (onFinish) {
          stepperRef?.current?._setActiveSlideIndex(numberSlides + 1);
          onFinish?.();
        }
      }, 150 * numberSlides);
    }
  };

  /**
   * Method to manage errors on back SIA
   */
  const manageErrorsGoBackSIA = () => {
    let onClose = () => {};
    let actionEvent = () => {};
    let errorSpecificPaymentTitle;
    let errorSpecificPaymentMessage;
    let errorSpecificPaymentShowCtc;
    if (isErrorOnInstant()) {
      errorSpecificPaymentTitle = localStorage.getItem(PAYMENT_ERROR_TITLE);
      errorSpecificPaymentMessage = localStorage.getItem(PAYMENT_ERROR_MESSAGE);
      errorSpecificPaymentShowCtc = localStorage.getItem(PAYMENT_SHOW_CTC);
    } else if (isErrorOnPaymentGeneric()) {
      errorSpecificPaymentTitle = siaError?.title || 'Ops';
      errorSpecificPaymentMessage = siaError?.message || 'Si è verificato un errore';
      errorSpecificPaymentShowCtc = true;
      onClose = () => {
        deleteSerializedContext();
        window.history.pushState(null, '', window.location.pathname);
        handleClose();
      };
      actionEvent = () => window.history.pushState(null, '', window.location.pathname);
    }
    errorManager.handleError(ErrorService.getSeverityErrorMedium(), {
      disableTrack: true,
      title: errorSpecificPaymentTitle || titleErrorPayment,
      message: errorSpecificPaymentMessage || messageErrorPayment,
      onClose,
      actionEvent,
      actionText: actionTextErrorPayment,
      isButtonSticky: isModalButtonSticky,
      ...(errorSpecificPaymentShowCtc &&
        errorSpecificPaymentShowCtc !== 'undefined' && {
          secondActionEvent: () => openPopup(urlPaymentErrorCallMeNow),
          secondButtonText: buttonPaymentError,
        }),
      opts: {
        product_name: product?.slug || '',
      },
    });
  };

  const checkBackSIA = () => {
    if (!checkIsGoBackSIA() || !checkInitContext()) return;
    initContext();
    addRemoveVisibilityCards('hide');
    if (isErrorOnPaymentShowErrorModal) {
      manageErrorsGoBackSIA();
    }
    programmaticallyGoBackNext(
      getSlideIndexByKey(steps, getActiveSlideFromStorage()),
      'NEXT',
      () => {
        setShowPageLoader(false);
        addRemoveVisibilityCards('show');
        if (checkIsGoBackSIA()) {
          setHideInternalComponents(false);
          setTimeout(() => {
            stepperRef?.current?._stepperGoPrev();
            window.history.pushState(null, '', window.location.pathname);
          }, 0);
        }
      }
    );
  };

  /**
   * On FINISH Go to SIA and return Back
   */
  const handleRedirect = () => {
    seriealizeAllSteps(steps);
    serializeContext();
    localStorage.removeItem(FLOW_TYPE);
    switch (currentStepKey) {
      case ID_FLOWS.PAYMENT_METHOD:
        localStorage.setItem(FLOW_TYPE, ISIAFlowType.RECURRENT.toString());
        setCheckoutSIA({
          isShow: true,
        });
        break;
      case ID_FLOWS.RECURRENT_PAYMENT_CARD:
        // eslint-disable-next-line no-case-declarations
        let flowType = ISIAFlowType.RECURRENT;
        // eslint-disable-next-line no-case-declarations
        const isShowUpfront = checkIsShowUpfront();
        if (!isShowUpfront) {
          flowType = ISIAFlowType.INSTANT;
        } else {
          flowType = ISIAFlowType.RECURRENT;
        }
        localStorage.setItem(FLOW_TYPE, flowType.toString());
        setCheckoutSIA({
          isShow: true,
        });
        break;
      case ID_FLOWS.UPFRONT_PAYMENT:
      case ID_FLOWS.PAYMENT_METHOD_WITHOUT_RECURRING:
        localStorage.setItem(FLOW_TYPE, ISIAFlowType.INSTANT.toString());
        setCheckoutSIA({
          isShow: true,
        });
        break;
      default:
        break;
    }
  };

  const manageSilentLoginError = (event: Event) => {
    const { detail } = event as CustomEvent as { detail: ITrackError };
    if (detail) {
      checkoutErrors?.showAndTrackError?.(
        genericError,
        () => {
          window.location.href = '/';
        },
        detail
      );
    }
  };

  useLayoutEffect(() => {
    if (hideCards) {
      addRemoveVisibilityCards('hide');
    }
  }, []);

  useEffect(() => {
    setIsLoadingCart(!!isLoadingCart);
  }, [isLoadingCart]);

  useEffect(() => {
    if (isLoggedUser && !owningData.isLogged && !isLoadingCart) {
      const customerData = getFromLocalStorageByKey('customerData')?.[0];
      if (customerData) {
        const countries: ICountryObject[] = getFromLocalStorageByKey('getCountry');
        initContext({ customerData, countries, isLogged: isLoggedUser });
      }
    }
  }, [silentLogin, isLoggedUser, isLoadingCart]);

  useEffect(() => {
    appendOtpAndPaymentSteps();
  }, [findCaller, paymean]);

  useEffect(() => {
    appendPortabilitySteps();
  }, [portability]);

  useEffect(() => {
    const closeOnEscapeKey = (e: KeyboardEvent) => (e.key === 'Escape' ? handleClose() : null);
    document.body.addEventListener('keydown', closeOnEscapeKey);
    return () => {
      document.body.removeEventListener('keydown', closeOnEscapeKey);
    };
  }, [handleClose]);

  useEffect(() => {
    if (slidesGoBack && slidesGoBack !== -1 && stepperRef?.current) {
      programmaticallyGoBackNext(slidesGoBack, 'BACK');
      setSlidesGoBack(-1);
    }
  }, [slidesGoBack]);

  useEffect(() => {
    setOfferId(product?.offerId?.toString() || '');
    checkBackSIA();
    if (isLoggedUser) setIsStartCustomerFlow(true);
    document.addEventListener(ALL_EVENTS.SILENT_LOGIN_ERROR, manageSilentLoginError);
    return () => {
      document.removeEventListener(ALL_EVENTS.SILENT_LOGIN_ERROR, manageSilentLoginError);
    };
  }, []);

  const productToShow = product ? (
    <ProductDetailExtended
      product={product}
      addons={addons}
      addonState={addonState}
      setAddonState={setAddonState}
      supportModule={supportModulesCms}
    />
  ) : (
    <div />
  );

  return (
    <>
      <OverlayGlobalStyle />
      <CheckoutModalContainer>
        <StepperStateCards
          ref={stepperRef}
          steps={steps}
          onBack={handleClose}
          onClose={handleClose}
          onFinish={handleRedirect}
          enableStickyStepper={!hideStickyBar}
          topLabelStickyStepper={product?.topLabelStickyCheckout || ''}
          titleStickyStepper={`${product?.price}<span class="rec">/${product?.recurrence}</span>`}
          actionLabelStickyStepper={product?.offerDetailLabel || 'SCOPRI DETTAGLI'}
          contentStickyStepper={productToShow}
          blurredImage={backgroundImage}
          forceFinish={isLastCard || false}
          hideBackButton={isDisabledBackButton || checkoutSIA?.isShow}
          isLoadingContainer={!silentLogin || isLoggedUser ? IS_LOADING_CART : false}
          isLoadingStickyOffer={IS_LOADING_CART}
          isForcedDisableCtaActivation={checkoutSIA?.isShow || false}
          hideInternalComponents={hideInternalComponents}
          lottieLoader={product?.loaders?.coverageTool}
          nextLabelStickyStepper={configuredStickyStepperLabel || ''}
          variantNextLabelStickyStepper={
            // eslint-disable-next-line no-nested-ternary
            configuredStickyStepperLabel ? (checkIsApp() ? 'mva:alt1' : 'secondary') : undefined
          }
        />
        {showPageLoader && (
          <LoadingPage>
            <LoadingSpinner />
          </LoadingPage>
        )}
      </CheckoutModalContainer>
      {!ENABLE_DEBUG && !IS_DISABLED_REDIRECT && checkoutSIA.isShow && <PreCheckout />}
      {ENABLE_DEBUG && checkoutSIA?.isShow && <DebugSIACard />}
    </>
  );
};

export default Checkout;

