import {
  CMS_CONFIG,
  getCurrentUserType,
  IBillingCard,
  IGlobalConfigCMS,
  IUserType,
  useBillingAccount,
  useCmsConfig,
} from '@vfit/consumer/data-access';
import { API, PAGES } from '@vfit/shared/data-access';
import { InvoiceType } from './chooseInvoice.models';

const checkUserType = () =>
  getCurrentUserType() === IUserType.NEXT_USER_ACTIVE ||
  getCurrentUserType() === IUserType.LOGGED_USER;

export const organizeBillingInvoiceCard = (
  billingCheckboxProps: IBillingCard,
  address: string,
  billingEmail?: string,
  secondItemAction?: () => void
) => {
  const prefix = billingCheckboxProps?.billinginformation?.card;
  return {
    title: prefix?.title || '',
    description: prefix?.description || '',
    items: [
      {
        title: prefix?.choice?.option1?.title || '',
        description:
          prefix?.choice?.option1?.description.replace('FIRSTADDRESS', `${address}`) || '',
        extra: {
          text: '',
        },
        value: InvoiceType?.PAPER,
      },
      {
        title: prefix?.choice?.option2?.title || '',
        titleIcon: prefix?.choice?.option2?.icon,
        description:
          prefix?.choice?.option2?.description.replace('EMAIL', `${billingEmail || ''}`) || '',
        extra: {
          text: checkUserType() ? prefix?.choice?.option2?.linkAddressLabel || '' : '',
          action: checkUserType() ? secondItemAction : undefined,
          variant: 'action',
        },
        value: InvoiceType?.EBILL,
      },
    ],
  };
};

export const useGetUpdatedBilling = () => {
  const globalConfig = useCmsConfig(
    CMS_CONFIG[PAGES.CONSUMER],
    API.CMS_GET_GLOBAL_CONFIG
  ) as IGlobalConfigCMS;
  const {
    isSuccess,
    isLoading,
    isError,
    refetch: refecthBillingAccount,
  } = useBillingAccount({ enabled: false }, globalConfig);

  const handleRefetch = (enabler: boolean) => enabler && refecthBillingAccount();

  return {
    handleRefetch,
    isError,
    isLoading,
    isSuccess,
  };
};
