import { useRef, useState, useEffect } from 'react';
import { useDeviceType } from '@vfit/shared/hooks';
import { trackLink, useTracking } from '@vfit/shared/data-access';
import { useQueryClient } from 'react-query';
import { ILang, ILanguageSelector } from './languageSelector.models';
import { getLocalLanguage, switchLang } from './languageSelector.utils';

const useLanguageSelector = ({ onSelectLanguage, isOpenSearch }: ILanguageSelector) => {
  const queryClient = useQueryClient();
  const navRef = useRef<HTMLElement>(null);
  const [allLangs, setAllLangs] = useState<ILang[]>([]);
  const [isOpenLangSelectWindow, setIsOpenLangSelectWindow] = useState(false);
  const [heightLangNavMenu, setHeightLangNavMenu] = useState('0px');
  const { isMobile, isDesktop } = useDeviceType();
  //TODO FIX Tracking
  const { trackWithNewDatalayer } = useTracking({ event: ['home'], disableInitialTrack: true });

  const onSelectLang = (id: string) => {
    setAllLangs(switchLang(allLangs, id));
    navRef?.current?.scrollTo({ top: 0, behavior: 'smooth' });
    onSelectLanguage?.(id);
    const upDatedLang = allLangs.find((l) => l.id === id)?.id || '';
    localStorage.setItem('lang', upDatedLang);
    trackLink(`lang:${upDatedLang}`);
  };

  useEffect(() => {
    if (allLangs.length > 1) {
      if (navRef.current) setHeightLangNavMenu(`${navRef.current.scrollHeight}px`);
      const selectedLang = allLangs?.find((l) => l.isSelected) || allLangs?.[0];
      if (selectedLang) {
        trackWithNewDatalayer({
          event: ['home'],
          page_country: selectedLang?.id?.toUpperCase() || 'IT',
          page_language: selectedLang?.id || 'IT',
          page_locale: selectedLang?.id
            ? `${selectedLang.id}-${selectedLang.id?.toUpperCase()}`
            : 'it-IT',
        });
      }
    }
  }, [allLangs]);

  useEffect(() => {
    isOpenSearch && setIsOpenLangSelectWindow(false);
  }, [isOpenSearch]);

  useEffect(() => {
    const languages: ILang[] =
      JSON.parse(JSON.stringify(queryClient.getQueryData<ILang[]>('languages') || [])) || [];
    languages.unshift({ id: 'it', value: 'IT' });
    const switchedLangs = switchLang(languages, getLocalLanguage() || '');
    setAllLangs([...switchedLangs]);
  }, []);

  return {
    allLangs,
    isMobile,
    isDesktop,
    isOpenLangSelectWindow,
    heightLangNavMenu,
    navRef,
    selectedLang: allLangs?.find((l) => l.isSelected) || allLangs?.[0],
    setIsOpenLangSelectWindow,
    onSelectLang,
  };
};

export { useLanguageSelector };
