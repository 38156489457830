/* eslint-disable no-param-reassign */
import {
  CART_TYPE,
  getCostFromShoppingCartByType, getFirstInvoicePrice,
  getHubPath, getOneTimeAmountForUpfront,
  getOneTimeAmountFromShoppingCart, getPlanCostFromShoppingCart,
  IProduct,
  ITechCard,
  ITechs,
  organizeTechnology,
  Placeholders,
  retrieveProductTechnology,
  START_FROM_APP_LOCKIN,
  TechnologyCategories,
  updateProduct,
} from '@vfit/consumer/data-access';
import { IOfferingServiceabilityResponse, IUpdateProductPriceType } from '@vfit/shared/models';
import { checkWindow, getFromLocalStorageByKey, htmlModalManager } from '@vfit/shared/data-access';
import { retrieveProduct } from '../iBuyFixed.utils';

/**
 * Method to update mobile product price
 */
export const updateProductPrice = (
  product: IProduct,
  updateType?: IUpdateProductPriceType
): IProduct | null => {
  const oneTimeAmountCost = getOneTimeAmountFromShoppingCart();
  const recurringAmountCost = getCostFromShoppingCartByType(CART_TYPE.RECURRING);
  const shippingCost = getCostFromShoppingCartByType(CART_TYPE.SHIPPING);
  const shippingCostFormattedPrice = shippingCost?.formattedPrice;

  const oneTimeAmount = oneTimeAmountCost?.formattedPrice?.toString() || 0;
  const recurringAmount = recurringAmountCost?.formattedPrice?.toString() || 0;

  const PLACEHOLDERS_ONETIME_RECURRENCE = [
    CART_TYPE.PLACEHOLDER_ONETIME,
    CART_TYPE.PLACEHOLDER_RECURRENCE,
  ];

  const PLACEHOLDERS_ONETIME_RECURRENCE_SHIPPING = [
    CART_TYPE.PLACEHOLDER_ONETIME,
    CART_TYPE.PLACEHOLDER_RECURRENCE,
    CART_TYPE.PLACEHOLDER_SHIPPING_PRICE,
  ];

  const PLACEHOLDERS_ACTIVATION_RECURRING = [
    CART_TYPE.PLACEHOLDER_RECURRING,
    CART_TYPE.PLACEHOLDER_ONETIME,
    CART_TYPE.PLACEHOLDER_RECURRENCE,
  ];

  const PLACEHOLDERS_ACTIVATION_RECURRING_SHIPPING = [
    CART_TYPE.PLACEHOLDER_RECURRING,
    CART_TYPE.PLACEHOLDER_ONETIME,
    CART_TYPE.PLACEHOLDER_RECURRENCE,
    CART_TYPE.PLACEHOLDER_SHIPPING_PRICE,
  ];

  const PLACEHOLDERS_RECURRING_RECURRENCE = [
    CART_TYPE.PLACEHOLDER_RECURRING,
    CART_TYPE.PLACEHOLDER_RECURRENCE,
  ];

  const PLACEHOLDERS_RECURRING_RECURRENCE_SHIPPING = [
    CART_TYPE.PLACEHOLDER_RECURRING,
    CART_TYPE.PLACEHOLDER_RECURRENCE,
    CART_TYPE.PLACEHOLDER_SHIPPING_PRICE,
  ];

  const havePlaceholder = (text: string, placeholders: string[]) => {
    let checkPlaceholder = false;
    placeholders.forEach((el) => {
      checkPlaceholder = checkPlaceholder || text.includes(el);
    });
    return checkPlaceholder;
  };

  if (product) {
    // region ONE TIME AND RECURRING
    if (oneTimeAmount && recurringAmount) {
      if (
        havePlaceholder(product?.priceActivationRecurring || '', PLACEHOLDERS_ACTIVATION_RECURRING)
      ) {
        product.price =
          product.priceActivationRecurring
            ?.replace(CART_TYPE.PLACEHOLDER_RECURRING, recurringAmount || '')
            ?.replace(CART_TYPE.PLACEHOLDER_ONETIME, `${oneTimeAmount}€` || '')
            ?.replace(CART_TYPE.PLACEHOLDER_RECURRENCE, product.recurrence || '') || '';
      }
      if (
        havePlaceholder(
          product?.subpriceActivationRecurring || '',
          PLACEHOLDERS_ACTIVATION_RECURRING
        )
      ) {
        product.subprice =
          product.subpriceActivationRecurring
            ?.replace(CART_TYPE.PLACEHOLDER_RECURRING, recurringAmount || '')
            ?.replace(CART_TYPE.PLACEHOLDER_ONETIME, `${oneTimeAmount}€` || '')
            ?.replace(CART_TYPE.PLACEHOLDER_RECURRENCE, product.recurrence || '') || '';
      }
      if (
        shippingCostFormattedPrice &&
        havePlaceholder(
          product?.descriptionActivationRecurringShipping || '',
          PLACEHOLDERS_ACTIVATION_RECURRING_SHIPPING
        )
      ) {
        product.description =
          product.descriptionActivationRecurringShipping
            ?.replace(CART_TYPE.PLACEHOLDER_RECURRING, recurringAmount || '')
            ?.replace(CART_TYPE.PLACEHOLDER_ONETIME, `${oneTimeAmount}€` || '')
            ?.replace(CART_TYPE.PLACEHOLDER_RECURRENCE, product.recurrence || '')
            ?.replace(
              CART_TYPE.PLACEHOLDER_SHIPPING_PRICE,
              shippingCostFormattedPrice?.toString() || ''
            ) || '';
      } else if (
        havePlaceholder(
          product?.descriptionActivationRecurring || '',
          PLACEHOLDERS_ACTIVATION_RECURRING
        )
      ) {
        product.description =
          product.descriptionActivationRecurring
            ?.replace(CART_TYPE.PLACEHOLDER_RECURRING, recurringAmount || '')
            ?.replace(CART_TYPE.PLACEHOLDER_ONETIME, `${oneTimeAmount}€` || '')
            ?.replace(CART_TYPE.PLACEHOLDER_RECURRENCE, product.recurrence || '') || '';
      }
    }
    // endregion
    // region ONLY ONE TIME
    else if (oneTimeAmount && !recurringAmount) {
      if (havePlaceholder(product?.priceActivation || '', PLACEHOLDERS_ONETIME_RECURRENCE)) {
        product.price =
          product.priceActivation
            ?.replace(CART_TYPE.PLACEHOLDER_ONETIME, `${oneTimeAmount}€` || '')
            ?.replace(CART_TYPE.PLACEHOLDER_RECURRENCE, product.recurrence || '') || '';
      }
      if (havePlaceholder(product?.subpriceActivation || '', PLACEHOLDERS_ONETIME_RECURRENCE)) {
        product.subprice =
          product.subpriceActivation
            ?.replace(CART_TYPE.PLACEHOLDER_ONETIME, `${oneTimeAmount}€` || '')
            ?.replace(CART_TYPE.PLACEHOLDER_RECURRENCE, product.recurrence || '') || '';
      }
      if (
        shippingCostFormattedPrice &&
        havePlaceholder(
          product?.descriptionActivationShipping || '',
          PLACEHOLDERS_ONETIME_RECURRENCE_SHIPPING
        )
      ) {
        product.description =
          product.descriptionActivationShipping
            ?.replace(CART_TYPE.PLACEHOLDER_ONETIME, `${oneTimeAmount}€` || '')
            ?.replace(
              CART_TYPE.PLACEHOLDER_SHIPPING_PRICE,
              shippingCostFormattedPrice?.toString() || ''
            )
            ?.replace(CART_TYPE.PLACEHOLDER_RECURRENCE, product.recurrence || '') || '';
      } else if (
        havePlaceholder(product?.descriptionActivation || '', PLACEHOLDERS_ONETIME_RECURRENCE)
      ) {
        product.description =
          product.descriptionActivation
            ?.replace(CART_TYPE.PLACEHOLDER_ONETIME, `${oneTimeAmount}€` || '')
            ?.replace(CART_TYPE.PLACEHOLDER_RECURRENCE, product.recurrence || '') || '';
      }
    }
    // endregion
    // region ONLY RECURRING
    else if (!oneTimeAmount && recurringAmount) {
      if (havePlaceholder(product?.priceRecurring || '', PLACEHOLDERS_RECURRING_RECURRENCE)) {
        product.price =
          product.priceRecurring
            ?.replace(CART_TYPE.PLACEHOLDER_RECURRING, recurringAmount || '')
            ?.replace(CART_TYPE.PLACEHOLDER_RECURRENCE, product.recurrence || '') || '';
      }
      if (havePlaceholder(product?.subpriceRecurring || '', PLACEHOLDERS_RECURRING_RECURRENCE)) {
        product.subprice =
          product.subpriceRecurring
            ?.replace(CART_TYPE.PLACEHOLDER_RECURRING, recurringAmount || '')
            ?.replace(CART_TYPE.PLACEHOLDER_RECURRENCE, product.recurrence || '') || '';
      }
      if (
        shippingCostFormattedPrice &&
        havePlaceholder(
          product?.descriptionRecurringShipping || '',
          PLACEHOLDERS_RECURRING_RECURRENCE_SHIPPING
        )
      ) {
        product.description =
          product.descriptionRecurringShipping
            ?.replace(CART_TYPE.PLACEHOLDER_RECURRING, recurringAmount || '')
            ?.replace(
              CART_TYPE.PLACEHOLDER_SHIPPING_PRICE,
              shippingCostFormattedPrice?.toString() || ''
            )
            ?.replace(CART_TYPE.PLACEHOLDER_RECURRENCE, product.recurrence || '') || '';
      } else if (
        havePlaceholder(product?.descriptionRecurring || '', PLACEHOLDERS_RECURRING_RECURRENCE)
      ) {
        product.description =
          product.descriptionRecurring
            ?.replace(CART_TYPE.PLACEHOLDER_RECURRING, recurringAmount || '')
            ?.replace(CART_TYPE.PLACEHOLDER_RECURRENCE, product.recurrence || '') || '';
      }
    }
    // endregion
    // region NOTHING
    else {
      if (havePlaceholder(product?.price || '', PLACEHOLDERS_ACTIVATION_RECURRING)) {
        product.price =
          product.price
            ?.replace(CART_TYPE.PLACEHOLDER_RECURRING, recurringAmount || '')
            ?.replace(CART_TYPE.PLACEHOLDER_ONETIME, oneTimeAmount || '')
            ?.replace(CART_TYPE.PLACEHOLDER_RECURRENCE, product.recurrence || '') || '';
      }
      if (havePlaceholder(product?.subprice || '', PLACEHOLDERS_ACTIVATION_RECURRING)) {
        product.subprice =
          product.subprice
            ?.replace(CART_TYPE.PLACEHOLDER_RECURRING, recurringAmount || '')
            ?.replace(CART_TYPE.PLACEHOLDER_ONETIME, oneTimeAmount || '')
            ?.replace(CART_TYPE.PLACEHOLDER_RECURRENCE, product.recurrence || '') || '';
      }
      if (havePlaceholder(product?.description || '', PLACEHOLDERS_ACTIVATION_RECURRING_SHIPPING)) {
        product.description =
          product.description
            ?.replace(CART_TYPE.PLACEHOLDER_RECURRING, recurringAmount || '')
            ?.replace(CART_TYPE.PLACEHOLDER_ONETIME, oneTimeAmount || '')
            ?.replace(
              CART_TYPE.PLACEHOLDER_SHIPPING_PRICE,
              shippingCostFormattedPrice?.toString() || ''
            )
            ?.replace(CART_TYPE.PLACEHOLDER_RECURRENCE, product.recurrence || '') || '';
      }
    }
    // endregion

    // region CART PRICES
    product.cartPrices = {
      immediatePrice: {
        amount: getOneTimeAmountForUpfront(false)
      },
      firstInvoicePrice: {
        amount: getFirstInvoicePrice() || 0
      },
      recurringPrice: {
        oldAmount: getCostFromShoppingCartByType(CART_TYPE.RECURRING)?.originalFormattedPrice,
        amount: getCostFromShoppingCartByType(CART_TYPE.RECURRING)?.formattedPrice,
      },
      offerPrice: {
        amount: getPlanCostFromShoppingCart()?.formattedPrice || 0,
        oldAmount: getPlanCostFromShoppingCart()?.originalFormattedPrice || 0,
        recurringPeriod: getPlanCostFromShoppingCart()?.recurringPeriod || 0
      },
    }
    // endregion

    product.recurringCost = recurringAmountCost?.price || 0;
    product.oneTimeCost = oneTimeAmountCost?.price || 0;
    product.shippingCost = shippingCost?.price || 0;

    switch (updateType) {
      case IUpdateProductPriceType.EDIT_WALLET:
        product.backupOfferDetails = JSON.parse(JSON.stringify(product.offerDetails));
        product.backupConditions = JSON.parse(JSON.stringify(product.conditions));
        product.offerDetails = product.offerDetailsWallet;
        product.conditions = product.conditionsWallet;
        break;
      case IUpdateProductPriceType.RESET:
        if (product.backupOfferDetails) {
          product.offerDetails = product.backupOfferDetails;
          delete product.backupOfferDetails;
        }
        if (product.backupConditions) {
          product.conditions = product.backupConditions;
          delete product.backupConditions;
        }
        break;
      default:
        break;
    }

    product.isUpdatedPriceWallet = updateType === IUpdateProductPriceType.EDIT_WALLET;

    updateProduct(product);
  }
  return product;
};

/**
 * Method to update shoppingCartUrl
 */
export const updateProductShoppingCartUrl = (pageProduct?: IProduct): void => {
  const hub = pageProduct ? getHubPath(pageProduct) : '';
  const product = retrieveProduct(true);
  if (product && checkWindow()) {
    updateProduct({
      ...product,
      ...(hub && { hubPath: hub }),
      shoppingCartUrl: window.location.href,
    });
  }
};

export const getLocalAddress = () => {
  const addressData = getFromLocalStorageByKey('createdAddress');
  const dataToDisplay = [
    `${addressData?.street || ''} ${addressData?.streetNumber || ''}`,
    `${addressData?.postalCode || ''} ${addressData?.city}`,
    `${addressData?.stateOrProvince || ''}`,
  ];
  return {
    ...addressData,
    city: addressData?.city || '',
    postalCode: addressData?.postalCode || '',
    stateOrProvince: addressData?.stateOrProvince || '',
    street: addressData?.street || '',
    streetNumber: addressData?.streetNumber || '',
    latitude: Number(addressData?.latitude) || 0,
    longitude: Number(addressData?.longitude) || 0,
    displayedAddress: dataToDisplay.join(', ') || '',
  };
};

export const getTechnology = () => {
  const offeringServiceAbility: IOfferingServiceabilityResponse =
    getFromLocalStorageByKey('offeringServiceability');
  return organizeTechnology(offeringServiceAbility);
};

const organizeLableShoppingCart = (
  tech: ITechCard | undefined,
  originalTechType: string
): {
  editAddress: string;
  bestOfferChoice: string;
  bestOfferChoiceMap: string;
} => ({
  editAddress: tech?.technology?.bestOfferChoice?.editAddress || '',
  bestOfferChoice:
    tech?.technology?.bestOfferChoice?.text?.replace('$TECHNOLOGY', `${originalTechType}`) || '',
  bestOfferChoiceMap:
    tech?.technology?.bestOfferChoice?.textForMap?.replace('$TECHNOLOGY', `${originalTechType}`) ||
    '',
});

export const getTechLabels = (allTechs: ITechs) => {
  const tecnology = getTechnology();
  let tech: ITechCard | undefined;
  switch (tecnology?.mappedCategory) {
    case TechnologyCategories.FTTH:
      tech = allTechs.ftth;
      break;
    case TechnologyCategories.FTTC:
      tech = allTechs.fttc;
      break;
    case TechnologyCategories.ADSL:
      tech = allTechs.adsl;
      break;
    case TechnologyCategories.FWAOUTDOOR:
      tech = allTechs.FWAOUTDOOR;
      break;
    case TechnologyCategories.FWAINDOOR:
      tech = allTechs.FWAINDOOR;
      break;
    case TechnologyCategories.FWAOUTDOOR5G:
      tech = allTechs.FWAOUTDOOR5G;
      break;
    case TechnologyCategories.FTTHTIM:
      tech = allTechs.FTTHTIM;
      break;
    case TechnologyCategories.FWAINDOOR5G:
      tech = allTechs.FWAINDOOR5G;
      break;
    default:
      break;
  }
  if (!tecnology?.mappedCategory) {
    return {
      editAddress: '',
      bestOfferChoice: '',
      bestOfferChoiceMap: '',
    };
  }
  return organizeLableShoppingCart(tech, tecnology?.shoppingCartTech);
};

export const isAppLockin = (): boolean => {
  const isAppLockinRetrieved = localStorage.getItem(START_FROM_APP_LOCKIN);
  return !!isAppLockinRetrieved && isAppLockinRetrieved === 'true';
};

export const checkPopupVisibility = (product: IProduct) => {
  if (product?.cartPopup?.isEnabled && product?.cartPopup?.htmlDesktop) {
    const formatString = (str: string) =>
      str
        ?.replace(Placeholders.TECHNOLOGY, retrieveProductTechnology())
        ?.replace(Placeholders.PRICE, product?.price || '')
        ?.replace(Placeholders.ORIGINAL_PRICE, product?.originalPrice || '');
    const htmlDesktop = formatString(product?.cartPopup?.htmlDesktop || '');
    const htmlTablet = formatString(product?.cartPopup?.htmlTablet || '');
    const htmlMobile = formatString(product?.cartPopup?.htmlMobile || '');
    htmlModalManager.handleHtmlModal({
      target: product?.cmsId?.toString() || '',
      isEnabledAlwaysShown: product?.cartPopup?.isEnabledAlwaysShown || false,
      isEnabled: product?.cartPopup?.isEnabled || false,
      timerToDisplay: product?.cartPopup?.timerToDisplay || undefined,
      htmlDesktop: htmlDesktop || '',
      htmlTablet: htmlTablet || '',
      htmlMobile: htmlMobile || '',
    });
  }
};
