import React, { useContext, useState } from 'react';
import {
  IPortabilityObject,
  IAddressDataObject,
  IInvoiceObject,
  IPickUpLocation,
  IOptionalText,
  IFormat,
  IPersonalDataObject,
  IFindCallerObject,
  IAvailablePaymentMethods,
  IPayMean,
  IInputsCoverageTool,
  IInputs,
} from '@vfit/shared/models';
import { LoggerInstance } from '@vfit/shared/data-access';
import { IProduct, updateProduct } from '@vfit/consumer/data-access';
import { getValueToEncode } from '@vfit/shared/data-access';
import {
  IContactInfo,
  IConsentsObject,
  ITermsAndConditionsCheck,
  IUpfrontSelection,
  IInitContext,
  ISimOptions,
  IRechargeOption,
  IOwningIndividual,
  IUpdateProductPriceControl,
  IPickUpLocationStore,
  IDeliveryAdvance,
  IPortabilityStatus,
} from './Checkout/checkout.models';
import {
  CONTEXT_KEY,
  DEFAULT_BILLING_ADDRESS,
  DEFAULT_CONSENTS,
  DEFAULT_CONTACT_INFO,
  DEFAULT_DATA,
  DEFAULT_FIND_CALLER,
  DEFAULT_INVOICE,
  DEFAULT_IS_DISABLED_BACK_BUTTON,
  DEFAULT_IS_FLOW_READY,
  DEFAULT_LAST_CARD,
  DEFAULT_READ_ONLY,
  DEFAULT_SLIDES_GO_BACK,
  DEFAULT_TERMS_AND_CONDITIONS,
  DEFAULT_UPFRONT_SELECTION,
  DEFAULT_PORTABILITY,
  DEFAULT_PORTABILITY_STATUS,
  DEFAULT_SHIPPING_ADDRESS,
  DEFAULT_PICKUP_POINT,
  DEFAULT_IS_DELAYED_ICCID_RETRIVE,
  DEFAULT_ICCID,
  DEFAULT_ADDRESS,
  DEFAULT_SIM_OPTIONS,
  DEFAULT_RECHARGE_SELECTION,
  DEFAULT_KEEPS_NUMBER,
  DEFAULT_UPDATE_PRODUCT_PRICE,
  DEFAULT_IS_VALID_NUMBER_PORTABILITY,
  DEFAULT_WINBACK_NUMBER,
  DEFAULT_OFFER_ID,
  DEFAULT_CUSTOMER_ID,
  DEFAULT_PICKUP_LOCATION_STORE,
  DEFAULT_SIM_TYPE_SELECTED_OPTION,
  DEFAULT_IS_START_RECOGNITION,
  DEFAULT_DELIVERY_ADVANCE,
  DEFAULT_IS_START_ADDRESS,
  DEFAULT_RECOGNITION_CODE,
  DEFAULT_IS_NEW_ADDRESS,
  DEFAULT_IS_START_DELIVERY_BASE,
  DEFAULT_BILLING_REPLACE,
  DEFAULT_CART_ASYNC_INFO,
  DEFAULT_CUSTOMER_DELIVERY_BASE_INFO,
  DEFAULT_CUSTOMER_ASYNC_INFO,
  DEFAULT_AUTHORIZATION_ASYNC_INFO,
  DEFAULT_IS_START_AUTHORIZATION_ASYNC_INFO,
  DEFAULT_IS_START_CUSTOMER_FLOW,
  DEFAULT_CUSTOMER_FLOW,
  DEFAULT_VOUCHER_DISCOUNT,
  DEFAULT_CHECKOUT_ERRORS,
  DEFAULT_PRODUCT,
  DEFAULT_IS_START_CAMPAIGN,
  DEFAULT_IS_START_ACCEPT_CAMPAIGN,
  DEFAULT_BILLING_EMAIL,
  DEFAULT_CAMPAIGN,
} from './iBuyMobile.context.data';
import { retrieveOwningIndividual } from './Checkout/checkout.utils';
import { ID_FLOWS, DEFAULT_CURRENT_STEP_KEY } from './Checkout/checkout.constants';
import { IUseCartAsyncInformationResponse } from './Checkout/hooks/UseCartAsyncInformation/useCartAsyncInformation.models';
import { IUseCustomerDeliveryBaseResponse } from './Checkout/hooks/UseCustomerDeliveryBase/useCustomerDeliveryBase.models';
import { IUseCustomerAsyncInformationResponse } from './Checkout/hooks/UseCustomerAsyncInformation/useCustomerAsyncInformation.models';
import { IUseDeliveryBillingResponse } from './Checkout/hooks/UseDeliveryBillingFlow/useDeliveryBillingFlow.models';
import { IUseAuthorizationAsyncInformationResponse } from './Checkout/hooks/UseAuthorizationAsyncInformation/useAuthorizationAsyncInformation.models';
import { IUseCustomerResponse } from './Checkout/hooks/UseCustomerFlow/useCustomerFlow.models';
import { IUseVoucherDiscountResponse } from './Checkout/hooks/UseVoucherDiscount/useVoucherDiscount.models';
import { IUseCheckoutErrors } from './Checkout/hooks/UseCheckoutErrors/useCheckoutErrors.models';
import { getSerializeContext, retrieveProduct } from './iBuyMobile.utils';
import { IUseCampaign } from './Checkout/hooks/UseForceCampaignFlow/useForceCampaignFlow.models';

interface ICheckoutProvider {
  children: React.ReactNode;
}

export type ICheckoutContext = {
  customerId: string;
  owningData: IPersonalDataObject;
  deliveryAdvance: IDeliveryAdvance;
  contactInfo: IContactInfo;
  consents: IConsentsObject;
  portability: IPortabilityObject;
  portabilityStatus: IPortabilityStatus;
  findCaller: IFindCallerObject;
  isReadOnly: boolean;
  isLastCard?: boolean;
  isFlowReady?: boolean;
  isDelayedICCIDretrive: boolean;
  termsAndConditions: ITermsAndConditionsCheck;
  invoice: IInvoiceObject;
  slidesGoBack: number;
  upfrontSelection: IUpfrontSelection;
  currentStepKey: string;
  address: IInputsCoverageTool;
  pickupPoint: IPickUpLocation;
  selectedPickupPoint: IPickUpLocation;
  billingAddress: IAddressDataObject;
  billingEmail: string;
  oldShippingAddress: IAddressDataObject;
  oldBillingAddress: IAddressDataObject;
  shippingAddress: IAddressDataObject;
  ICCID: null | string;
  simOptions: ISimOptions;
  availablePaymentMethods: IAvailablePaymentMethods[];
  recharge: IRechargeOption;
  hideStickyBar: boolean;
  keepsNumber?: IOptionalText;
  setKeepsNumber: (payload: IOptionalText) => void;
  updateProductPriceCtrl: IUpdateProductPriceControl;
  isValidNumberPortability: boolean;
  billDeliveryDone: boolean;
  winBackNumber: string;
  upfrontPayment: IPayMean | undefined;
  isLoadingCart: boolean;
  pickupLocationStore: IPickUpLocationStore;
  isStartRecognition: boolean;
  isStartDeliveryBase: boolean;
  recognitionCode: string;
  isStartAddress: boolean;
  isNewShippingAddress: boolean;
  billingReplace: IUseDeliveryBillingResponse;
  cartAsyncInfo: IUseCartAsyncInformationResponse;
  customerDeliveryBaseInfo: IUseCustomerDeliveryBaseResponse;
  customerAsyncInfo: IUseCustomerAsyncInformationResponse;
  authorizationAsyncInfo: IUseAuthorizationAsyncInformationResponse;
  isStartAuthorizationAsyncInfo: boolean;
  simTypeSelectedOption: IFormat | undefined;
  isModalButtonSticky: boolean;
  isStartCustomerFlow: boolean;
  isStartCampaign: boolean;
  isStartCampaignAccept: boolean;
  customerFlow: IUseCustomerResponse;
  voucher: IUseVoucherDiscountResponse;
  offerId: string;
  product: IProduct;
  isPreselectedCard: boolean;
  configuredStickyStepperLabel: string;
  checkoutErrors: IUseCheckoutErrors;
  campaign: IUseCampaign;
  setICCID: (iccid: string | null) => void;
  setIsDelayedICCIDretrive: (isDelayed: boolean) => void;
  setCustomerId: (customerId: string) => void;
  setOwningData: (dataOwning: IPersonalDataObject) => void;
  setPortability: (dataPortability: IPortabilityObject) => void;
  setContactInfo: (dataContact: IContactInfo) => void;
  setConsents: (dataConsents: IConsentsObject) => void;
  setBillingAddress: (dataAddress: IAddressDataObject) => void;
  setBillingEmail: (dataEmail: string) => void;
  setOldShippingAddress: (dataAddress: IAddressDataObject) => void;
  setOldBillingAddress: (dataAddress: IAddressDataObject) => void;
  setShippingAddress: (dataAddress: IAddressDataObject) => void;
  setFindCaller: (dataCaller: IFindCallerObject) => void;
  setIsReadOnly: (dataRead: boolean) => void;
  setIsLastCard: (isLastCard: boolean) => void;
  setIsFlowReady: (isFlowReady: boolean) => void;
  setTermsAndConditions: (termsAndConditions: ITermsAndConditionsCheck) => void;
  setInvoice: (invoice: IInvoiceObject) => void;
  setSlidesGoBack: (slidesGoback: number) => void;
  setUpfrontSelection: (upfrontSelection: IUpfrontSelection) => void;
  setCurrentStepKey: (currentStepKey: string) => void;
  setIsDisabledBackButton: (isDisabledBackButton: boolean) => void;
  serializeContext: (ignoreUserData?: boolean) => void;
  deleteSerializedContext: () => void;
  checkInitContext: () => boolean;
  printContext: () => void;
  isDisabledBackButton: boolean;
  initContext: (initContext?: IInitContext) => boolean;
  setOfferId: (offerId: string) => void;
  setAddress: (address: IInputs) => void;
  setSelectedPickupPoint: (pickupPoint: IPickUpLocation) => void;
  setPickupPoint: (pickupPoint: IPickUpLocation) => void;
  setSimOptions: (simOptions: ISimOptions) => void;
  setAvailablePaymentMethods: (availablePaymentMethods: IAvailablePaymentMethods[]) => void;
  setRecharge: (recharge: IRechargeOption) => void;
  setHideStickyBar: (hideStickybar: boolean) => void;
  setUpdateProductPriceCtrl: (updateProductPriceCtrl: IUpdateProductPriceControl) => void;
  setIsValidNumberPortability: (isValidNumberPortability: boolean) => void;
  setPortabilityStatus: (portabilityStatus: IPortabilityStatus) => void;
  setBillDeliveryDone: (billDeliveryDone: boolean) => void;
  setUpfrontPayment: (upfrontPayment: IPayMean | undefined) => void;
  setIsLoadingCart: (isLoadingCart: boolean) => void;
  setIsPreselectedCard: (isPreselectedcard: boolean) => void;
  setWinBackNumber: (number: string) => void;
  setDeliveryAdvance: (deliveryAdvance: IDeliveryAdvance) => void;
  setPickupLocationStore: (pickupLocationStore: IPickUpLocationStore) => void;
  setSimTypeSelectedOption: (simTypeSelection: IFormat) => void;
  setIsStartRecognition: (isStart: boolean) => void;
  setIsStartAddress: (isStart: boolean) => void;
  setRecognitionCode: (recCode: string) => void;
  setBillingReplace: (billingReplace: IUseDeliveryBillingResponse) => void;
  setIsNewShippingAddress: (isNewAddress: boolean) => void;
  setIsStartDeliveryBase: (dB: boolean) => void;
  setCartAsyncInfo: (cartAsyncInfo: IUseCartAsyncInformationResponse) => void;
  setCustomerDeliveryBaseInfo: (cDeliveryBaseInfo: IUseCustomerDeliveryBaseResponse) => void;
  setCustomerAsyncInfo: (cAsyncInfo: IUseCustomerAsyncInformationResponse) => void;
  setAuthorizationAsyncInfo: (aAsyncInfo: IUseAuthorizationAsyncInformationResponse) => void;
  setIsStartAuthorizationAsyncInfo: (iSAsyncInfo: boolean) => void;
  setIsModalButtonSticky: (isModalButtonSticky: boolean) => void;
  setIsStartCustomerFlow: (isStartCustomerFlow: boolean) => void;
  setIsStartCampaign: (isStartCampaign: boolean) => void;
  setIsStartAcceptCampaign: (isStartCampaignAccept: boolean) => void;
  setCustomerFlow: (customerRes: IUseCustomerResponse) => void;
  setVoucher: (voucherRes: IUseVoucherDiscountResponse) => void;
  setConfiguredStickyStepperLabel: (label: string) => void;
  setCheckoutErrors: (checkoutObj: IUseCheckoutErrors) => void;
  setProduct: (prod: IProduct) => void;
  setCampaign: (campaign: IUseCampaign) => void;
};

export const CheckoutContext = React.createContext<ICheckoutContext>({
  customerId: DEFAULT_CUSTOMER_ID,
  owningData: DEFAULT_DATA,
  contactInfo: DEFAULT_CONTACT_INFO,
  portability: DEFAULT_PORTABILITY,
  portabilityStatus: DEFAULT_PORTABILITY_STATUS,
  consents: DEFAULT_CONSENTS,
  findCaller: DEFAULT_FIND_CALLER,
  isReadOnly: DEFAULT_READ_ONLY,
  isLastCard: DEFAULT_LAST_CARD,
  isFlowReady: DEFAULT_IS_FLOW_READY,
  termsAndConditions: DEFAULT_TERMS_AND_CONDITIONS,
  invoice: DEFAULT_INVOICE,
  slidesGoBack: DEFAULT_SLIDES_GO_BACK,
  currentStepKey: ID_FLOWS.PERSONAL_INFO,
  upfrontSelection: DEFAULT_UPFRONT_SELECTION,
  isDisabledBackButton: DEFAULT_IS_DISABLED_BACK_BUTTON,
  address: DEFAULT_ADDRESS,
  pickupPoint: DEFAULT_PICKUP_POINT,
  selectedPickupPoint: DEFAULT_PICKUP_POINT,
  offerId: DEFAULT_OFFER_ID,
  oldShippingAddress: DEFAULT_SHIPPING_ADDRESS,
  oldBillingAddress: DEFAULT_SHIPPING_ADDRESS,
  shippingAddress: DEFAULT_SHIPPING_ADDRESS,
  billingAddress: DEFAULT_BILLING_ADDRESS,
  billingEmail: DEFAULT_BILLING_EMAIL,
  isDelayedICCIDretrive: DEFAULT_IS_DELAYED_ICCID_RETRIVE,
  ICCID: DEFAULT_ICCID,
  simOptions: DEFAULT_SIM_OPTIONS,
  availablePaymentMethods: [],
  recharge: DEFAULT_RECHARGE_SELECTION,
  hideStickyBar: false,
  keepsNumber: DEFAULT_KEEPS_NUMBER,
  updateProductPriceCtrl: DEFAULT_UPDATE_PRODUCT_PRICE,
  isValidNumberPortability: DEFAULT_IS_VALID_NUMBER_PORTABILITY,
  billDeliveryDone: false,
  upfrontPayment: undefined,
  isLoadingCart: true,
  isPreselectedCard: false,
  winBackNumber: DEFAULT_WINBACK_NUMBER,
  pickupLocationStore: DEFAULT_PICKUP_LOCATION_STORE,
  simTypeSelectedOption: DEFAULT_SIM_TYPE_SELECTED_OPTION,
  isStartRecognition: DEFAULT_IS_START_RECOGNITION,
  isStartAddress: DEFAULT_IS_START_ADDRESS,
  deliveryAdvance: DEFAULT_DELIVERY_ADVANCE,
  recognitionCode: DEFAULT_RECOGNITION_CODE,
  billingReplace: DEFAULT_BILLING_REPLACE,
  isNewShippingAddress: DEFAULT_IS_NEW_ADDRESS,
  isStartDeliveryBase: DEFAULT_IS_START_DELIVERY_BASE,
  cartAsyncInfo: DEFAULT_CART_ASYNC_INFO,
  customerDeliveryBaseInfo: DEFAULT_CUSTOMER_DELIVERY_BASE_INFO,
  customerAsyncInfo: DEFAULT_CUSTOMER_ASYNC_INFO,
  authorizationAsyncInfo: DEFAULT_AUTHORIZATION_ASYNC_INFO,
  isStartAuthorizationAsyncInfo: DEFAULT_IS_START_AUTHORIZATION_ASYNC_INFO,
  isModalButtonSticky: false,
  isStartCampaign: false,
  isStartCampaignAccept: false,
  isStartCustomerFlow: DEFAULT_IS_START_CUSTOMER_FLOW,
  customerFlow: DEFAULT_CUSTOMER_FLOW,
  voucher: DEFAULT_VOUCHER_DISCOUNT,
  configuredStickyStepperLabel: '',
  checkoutErrors: DEFAULT_CHECKOUT_ERRORS,
  product: DEFAULT_PRODUCT as IProduct,
  campaign: DEFAULT_CAMPAIGN,
  setCustomerId: () => {},
  setICCID: () => {},
  setIsDelayedICCIDretrive: () => {},
  setOwningData: () => {},
  setContactInfo: () => {},
  setPortability: () => {},
  setPortabilityStatus: () => {},
  setConsents: () => {},
  setBillingAddress: () => {},
  setBillingEmail: () => {},
  setOldShippingAddress: () => {},
  setOldBillingAddress: () => {},
  setShippingAddress: () => {},
  setFindCaller: () => {},
  setIsReadOnly: () => {},
  setIsLastCard: () => {},
  setIsFlowReady: () => {},
  setTermsAndConditions: () => {},
  setInvoice: () => {},
  printContext: () => {},
  setSlidesGoBack: () => {},
  setUpfrontSelection: () => {},
  setCurrentStepKey: () => {},
  serializeContext: () => {},
  deleteSerializedContext: () => {},
  checkInitContext: () => false,
  setIsDisabledBackButton: () => {},
  initContext: () => false,
  setOfferId: () => {},
  setAddress: () => {},
  setPickupPoint: () => {},
  setSelectedPickupPoint: () => {},
  setSimOptions: () => {},
  setAvailablePaymentMethods: () => {},
  setRecharge: () => {},
  setHideStickyBar: () => {},
  setUpdateProductPriceCtrl: () => {},
  setKeepsNumber: () => {},
  setIsValidNumberPortability: () => false,
  setBillDeliveryDone: () => false,
  setUpfrontPayment: () => {},
  setIsLoadingCart: () => {},
  setIsPreselectedCard: () => false,
  setWinBackNumber: () => {},
  setPickupLocationStore: () => {},
  setSimTypeSelectedOption: () => {},
  setIsStartRecognition: () => {},
  setIsStartAddress: () => {},
  setDeliveryAdvance: () => {},
  setRecognitionCode: () => {},
  setBillingReplace: () => {},
  setIsNewShippingAddress: () => {},
  setIsStartDeliveryBase: () => {},
  setCartAsyncInfo: () => {},
  setCustomerDeliveryBaseInfo: () => {},
  setCustomerAsyncInfo: () => {},
  setAuthorizationAsyncInfo: () => {},
  setIsStartAuthorizationAsyncInfo: () => {},
  setIsModalButtonSticky: () => {},
  setIsStartCustomerFlow: () => {},
  setCustomerFlow: () => {},
  setVoucher: () => {},
  setConfiguredStickyStepperLabel: () => {},
  setCheckoutErrors: () => {},
  setProduct: () => {},
  setCampaign: () => {},
  setIsStartCampaign: () => false,
  setIsStartAcceptCampaign: () => false,
});

export const CheckoutProvider = ({ children }: ICheckoutProvider) => {
  const [customerId, setCustomerIdData] = useState<string>('');

  const [owningData, setCheckoutModalData] = useState<IPersonalDataObject>({
    ...DEFAULT_DATA,
  });

  const [contactInfo, setContact] = useState<IContactInfo>({
    ...DEFAULT_CONTACT_INFO,
  });

  const [portability, setPortabilityData] = useState<IPortabilityObject>({
    ...DEFAULT_PORTABILITY,
  });

  const [consents, setConsents] = useState<IConsentsObject>({
    ...DEFAULT_CONSENTS,
  });

  const [winBackNumber, setWinBackNumber] = useState(DEFAULT_WINBACK_NUMBER);

  const [oldBillingAddress, setOldBilling] = useState<IAddressDataObject>({
    ...DEFAULT_BILLING_ADDRESS,
  });

  const [billingAddress, setBilling] = useState<IAddressDataObject>({
    ...DEFAULT_BILLING_ADDRESS,
  });

  const [billingEmail, setBillingEmailData] = useState<string>(DEFAULT_BILLING_EMAIL);

  const [campaign, setCampaignData] = useState<IUseCampaign>({
    ...DEFAULT_CAMPAIGN,
  });

  const [shippingAddress, setShipping] = useState<IAddressDataObject>({
    ...DEFAULT_SHIPPING_ADDRESS,
  });

  const [oldShippingAddress, setOldShipping] = useState<IAddressDataObject>({
    ...DEFAULT_SHIPPING_ADDRESS,
  });

  const [findCaller, setCaller] = useState<IFindCallerObject>({
    ...DEFAULT_FIND_CALLER,
  });

  const [isReadOnly, setReadOnly] = useState<boolean>(DEFAULT_READ_ONLY);

  const [isLastCard, setLastCard] = useState<boolean>(DEFAULT_LAST_CARD);

  const [isFlowReady, setFlowReady] = useState<boolean>(DEFAULT_IS_FLOW_READY);

  const [termsAndConditions, setTermsAndConditionsData] = useState<ITermsAndConditionsCheck>(
    DEFAULT_TERMS_AND_CONDITIONS
  );

  const [invoice, setInvoiceOption] = useState<IInvoiceObject>(DEFAULT_INVOICE);

  const [isLoadingCart, setIsLoadingCartData] = useState<boolean>(true);

  const [slidesGoBack, setSlidesGoBackData] = useState<number>(DEFAULT_SLIDES_GO_BACK);

  const [upfrontSelection, setUpfrontSelectionOption] =
    useState<IUpfrontSelection>(DEFAULT_UPFRONT_SELECTION);

  const [currentStepKey, setCurrentStepKeyData] = useState<string>(DEFAULT_CURRENT_STEP_KEY);

  const [isDisabledBackButton, setIsDisabledBack] = useState<boolean>(
    DEFAULT_IS_DISABLED_BACK_BUTTON
  );

  const [isPreselectedCard, setIsPreselectedCardData] = useState<boolean>(false);

  const [offerId, setOfferIdData] = useState<string>(DEFAULT_OFFER_ID);

  const [address, setInfoAddress] = useState<IInputs>({
    ...DEFAULT_ADDRESS,
  });

  const [pickupPoint, setPickupPointData] = useState<IPickUpLocation>({
    ...DEFAULT_PICKUP_POINT,
  });

  const [selectedPickupPoint, setSelectedPickupPointData] = useState<IPickUpLocation>({
    ...DEFAULT_PICKUP_POINT,
  });

  const [isDelayedICCIDretrive, setIsDelayedICCIDretrive] = useState(false);

  const [ICCID, setICCID] = useState<null | string>(null);

  const [simOptions, setSimOptionsData] = useState<ISimOptions>(DEFAULT_SIM_OPTIONS);

  const [recharge, setRechargeData] = useState<IRechargeOption>(DEFAULT_RECHARGE_SELECTION);

  const [hideStickyBar, setHideStickyBarData] = useState<boolean>(false);

  const [updateProductPriceCtrl, setUpdateProductPriceCtrlData] =
    useState<IUpdateProductPriceControl>(DEFAULT_UPDATE_PRODUCT_PRICE);

  const [availablePaymentMethods, setAvailablePaymentMethodsData] = useState<
    IAvailablePaymentMethods[]
  >([]);

  const [keepsNumber, setKeepsNumber] = useState<IOptionalText>();

  const [isValidNumberPortability, setIsValidNumberPortabilityData] = useState<boolean>(
    DEFAULT_IS_VALID_NUMBER_PORTABILITY
  );

  const [portabilityStatus, setPortabilityStatusData] = useState<IPortabilityStatus>(
    DEFAULT_PORTABILITY_STATUS
  );

  const [billDeliveryDone, setBillDeliveryDoneData] = useState<boolean>(false);

  const [upfrontPayment, setUpfrontPaymentData] = useState<IPayMean | undefined>(undefined);

  const [pickupLocationStore, setPickupLocationStoreData] = useState<IPickUpLocationStore>({
    ...DEFAULT_PICKUP_LOCATION_STORE,
  });

  const [simTypeSelectedOption, setSimTypeSelectedOptionData] = useState<IFormat | undefined>(
    DEFAULT_SIM_TYPE_SELECTED_OPTION
  );

  const [isStartRecognition, setIsStartRecognitionData] = useState<boolean>(
    DEFAULT_IS_START_RECOGNITION
  );

  const [isStartCampaign, setIsStartCampaignData] = useState<boolean>(
    DEFAULT_IS_START_CAMPAIGN
  );

  const [isStartCampaignAccept, setIsStartAcceptCampaignData] = useState<boolean>(
    DEFAULT_IS_START_ACCEPT_CAMPAIGN
  );

  const [isNewShippingAddress, setIsNewShippingAddressData] =
    useState<boolean>(DEFAULT_IS_NEW_ADDRESS);

  const [isStartDeliveryBase, setIsStartDeliveryBaseData] = useState<boolean>(
    DEFAULT_IS_START_DELIVERY_BASE
  );

  const [isStartAuthorizationAsyncInfo, setIsStartAuthorizationAsyncInfoData] = useState<boolean>(
    DEFAULT_IS_START_AUTHORIZATION_ASYNC_INFO
  );

  const [customerDeliveryBaseInfo, setCustomerDeliveryBaseInfoData] =
    useState<IUseCustomerDeliveryBaseResponse>(DEFAULT_CUSTOMER_DELIVERY_BASE_INFO);

  const [recognitionCode, setRecognitionCodeData] = useState<string>(DEFAULT_RECOGNITION_CODE);

  const [isStartAddress, setIsStartAddressData] = useState<boolean>(DEFAULT_IS_START_ADDRESS);

  const [billingReplace, setBillingReplaceData] =
    useState<IUseDeliveryBillingResponse>(DEFAULT_BILLING_REPLACE);

  const [deliveryAdvance, setDeliveryAdvanceData] =
    useState<IDeliveryAdvance>(DEFAULT_DELIVERY_ADVANCE);

  const [cartAsyncInfo, setCartAsyncInfoData] =
    useState<IUseCartAsyncInformationResponse>(DEFAULT_CART_ASYNC_INFO);

  const [customerAsyncInfo, setCustomerAsyncInfoData] =
    useState<IUseCustomerAsyncInformationResponse>(DEFAULT_CUSTOMER_ASYNC_INFO);

  const [authorizationAsyncInfo, setAuthorizationAsyncInfoData] =
    useState<IUseAuthorizationAsyncInformationResponse>(DEFAULT_AUTHORIZATION_ASYNC_INFO);

  const [isModalButtonSticky, setIsModalButtonStickyStatus] = useState<boolean>(false);

  const [isStartCustomerFlow, setIsStartCustomerFlowData] = useState<boolean>(false);

  const [customerFlow, setCustomerFlowData] = useState<IUseCustomerResponse>(DEFAULT_CUSTOMER_FLOW);

  const [voucher, setVoucherData] = useState<IUseVoucherDiscountResponse>(DEFAULT_VOUCHER_DISCOUNT);

  const [checkoutErrors, setCheckoutErrorsData] =
    useState<IUseCheckoutErrors>(DEFAULT_CHECKOUT_ERRORS);

  const [configuredStickyStepperLabel, setConfiguredStickyStepperLabel] = useState('');

  const [product, setProductData] = useState<IProduct>(DEFAULT_PRODUCT as IProduct);

  const setCustomerId = (customerIdData: string) => {
    setCustomerIdData(customerIdData);
  };

  const setOwningData = (checkoutData: IPersonalDataObject) => {
    setCheckoutModalData({ ...checkoutData });
  };

  const setContactInfo = (contactData: IContactInfo) => {
    setContact({ ...contactData });
  };

  const setPortability = (portabilityData: IPortabilityObject) => {
    setPortabilityData({ ...portabilityData });
  };

  const setOldBillingAddress = (oldBillingAddressData: IAddressDataObject) => {
    setOldBilling({ ...oldBillingAddressData });
  };

  const setBillingAddress = (billingAddressData: IAddressDataObject) => {
    setOldBillingAddress(billingAddress?.city ? billingAddress : billingAddressData);
    setBilling({ ...billingAddressData });
  };

  const setBillingEmail = (billingEmailData: string) => {
    setBillingEmailData(billingEmailData);
  };

  const setOldShippingAddress = (oldShippingAddressData: IAddressDataObject) => {
    setOldShipping({ ...oldShippingAddressData });
  };

  const setShippingAddress = (shippingAddressData: IAddressDataObject) => {
    setOldShippingAddress(shippingAddress?.city ? shippingAddress : shippingAddressData);
    setShipping({ ...shippingAddressData });
  };

  const setFindCaller = (findCallerData: IFindCallerObject) => {
    setCaller({ ...findCallerData });
  };

  const setIsReadOnly = (dataRead: boolean) => {
    setReadOnly(dataRead);
  };

  const setIsLastCard = (isLastCardLocal: boolean) => {
    setLastCard(isLastCardLocal);
  };

  const setIsFlowReady = (isFlowReadyLocal: boolean) => {
    setFlowReady(isFlowReadyLocal);
  };

  const setIsStartCampaign = (isStartCampaignLocal: boolean) => {
    setIsStartCampaignData(isStartCampaignLocal);
  };

  const setIsStartAcceptCampaign = (isStartCampaignAcceptLocal: boolean) => {
    setIsStartAcceptCampaignData(isStartCampaignAcceptLocal);
  };

  const setTermsAndConditions = (termsAndConditionsLocal: ITermsAndConditionsCheck) => {
    setTermsAndConditionsData({ ...termsAndConditionsLocal });
  };

  const setInvoice = (invoiceLocal: IInvoiceObject) => {
    setInvoiceOption({ ...invoiceLocal });
  };

  const setCampaign = (campaignData: IUseCampaign) => {
    setCampaignData({ ...campaignData });
  };

  const setSlidesGoBack = (slidesBack: number) => {
    setSlidesGoBackData(slidesBack);
  };

  const setUpfrontSelection = (upfrontSelectionLocal: IUpfrontSelection) => {
    setUpfrontSelectionOption(upfrontSelectionLocal);
  };

  const setCurrentStepKey = (currentStepKeyLocal: string) => {
    setCurrentStepKeyData(currentStepKeyLocal);
  };

  const setIsDisabledBackButton = (disabledBackButton: boolean) => {
    setIsDisabledBack(disabledBackButton);
  };

  const setIsNewShippingAddress = (isNewShippingAddressData: boolean) => {
    setIsNewShippingAddressData(isNewShippingAddressData);
  };

  const setIsValidNumberPortability = (isValid: boolean) => {
    setIsValidNumberPortabilityData(isValid);
  };

  const setPortabilityStatus = (portabilityStatusData: IPortabilityStatus) => {
    setPortabilityStatusData(portabilityStatusData);
  };

  const setBillDeliveryDone = (billDelivery: boolean) => {
    setBillDeliveryDoneData(billDelivery);
  };

  const setUpfrontPayment = (upfrontPaymentLocal: IPayMean | undefined) => {
    setUpfrontPaymentData(upfrontPaymentLocal);
  };

  const setIsPreselectedCard = (isPreselectedC: boolean) => {
    setIsPreselectedCardData(isPreselectedC);
  };

  const setOfferId = (offerIdData: string) => {
    setOfferIdData(offerIdData);
  };

  const setAddress = (addressInfo: IInputs) => {
    setInfoAddress({ ...addressInfo });
  };

  const setSimOptions = (simOptionsData: ISimOptions) => {
    setSimOptionsData(simOptionsData);
  };

  const setAvailablePaymentMethods = (availablePaymentMethodsData: IAvailablePaymentMethods[]) => {
    setAvailablePaymentMethodsData(availablePaymentMethodsData);
  };

  const setPickupPoint = (pickupPointLocal: IPickUpLocation) => {
    setPickupPointData(pickupPointLocal);
  };

  const setSelectedPickupPoint = (selectedPickupPointLocal: IPickUpLocation) => {
    setSelectedPickupPointData(selectedPickupPointLocal);
  };

  const setRecharge = (rechargeData: IRechargeOption) => {
    setRechargeData({ ...rechargeData });
  };

  const setHideStickyBar = (hSB: boolean) => {
    setHideStickyBarData(hSB);
  };

  const setUpdateProductPriceCtrl = (updateProductPriceCtrlState: IUpdateProductPriceControl) => {
    setUpdateProductPriceCtrlData(updateProductPriceCtrlState);
  };

  const setIsLoadingCart = (isLoadC: boolean) => {
    setIsLoadingCartData(isLoadC);
  };

  const setPickupLocationStore = (pickuplocationStoreData: IPickUpLocationStore) => {
    setPickupLocationStoreData(pickuplocationStoreData);
  };

  const setSimTypeSelectedOption = (simTypeSelectedOptionData: IFormat) => {
    setSimTypeSelectedOptionData(simTypeSelectedOptionData);
  };

  const setIsStartRecognition = (isStartRecognitionData: boolean) => {
    setIsStartRecognitionData(isStartRecognitionData);
  };

  const setRecognitionCode = (recCodeData: string) => {
    setRecognitionCodeData(recCodeData);
  };

  const setIsStartAddress = (isStartAddressData: boolean) => {
    setIsStartAddressData(isStartAddressData);
  };

  const setDeliveryAdvance = (deliveryAdvanceData: IDeliveryAdvance) => {
    setDeliveryAdvanceData(deliveryAdvanceData);
  };

  const setBillingReplace = (bP: IUseDeliveryBillingResponse) => {
    setBillingReplaceData(bP);
  };

  const setIsStartDeliveryBase = (isDb: boolean) => {
    setIsStartDeliveryBaseData(isDb);
  };

  const setCartAsyncInfo = (cAInfo: IUseCartAsyncInformationResponse) => {
    setCartAsyncInfoData(cAInfo);
  };

  const setCustomerDeliveryBaseInfo = (cDBInfo: IUseCustomerDeliveryBaseResponse) => {
    setCustomerDeliveryBaseInfoData(cDBInfo);
  };

  const setCustomerAsyncInfo = (cAsyncInfo: IUseCustomerAsyncInformationResponse) => {
    setCustomerAsyncInfoData(cAsyncInfo);
  };

  const setAuthorizationAsyncInfo = (aAsyncInfo: IUseAuthorizationAsyncInformationResponse) => {
    setAuthorizationAsyncInfoData(aAsyncInfo);
  };

  const setIsStartAuthorizationAsyncInfo = (iSAsyncInfo: boolean) => {
    setIsStartAuthorizationAsyncInfoData(iSAsyncInfo);
  };

  const setIsModalButtonSticky = (isModalButtonStickyProps: boolean) => {
    setIsModalButtonStickyStatus(isModalButtonStickyProps);
  };

  const setIsStartCustomerFlow = (isStartCustomerFlowData: boolean) => {
    setIsStartCustomerFlowData(isStartCustomerFlowData);
  };

  const setCustomerFlow = (customerFlowData: IUseCustomerResponse) => {
    setCustomerFlowData(customerFlowData);
  };

  const setVoucher = (voucherData: IUseVoucherDiscountResponse) => {
    setVoucherData(voucherData);
  };

  const setProduct = (prodData: IProduct) => {
    updateProduct(prodData);
    setProductData(prodData);
  };

  const setCheckoutErrors = (cErr: IUseCheckoutErrors) => {
    setCheckoutErrorsData(cErr);
  };

  const printContext = () => {
    LoggerInstance.info('context', {
      customerId,
      owningData: {
        ...owningData,
      },
      portability: {
        ...portability,
      },
      portabilityStatus: {
        ...portabilityStatus,
      },
      findCaller: {
        ...findCaller,
      },
      termsAndConditions: {
        ...termsAndConditions,
      },
      invoice: {
        ...invoice,
      },
      billingAddress: {
        ...billingAddress,
      },
      shippingAddress: {
        ...shippingAddress,
      },
      consents: {
        ...consents,
      },
      contactInfo: {
        ...contactInfo,
      },
      upfrontSelection: {
        ...upfrontSelection,
      },
      simOptions: {
        ...simOptions,
      },
      billingEmail,
      isReadOnly: isReadOnly || false,
      isLastCard: isLastCard || false,
      isFlowReady: isFlowReady || true,
      isStartCampaign: isStartCampaign || true,
      isStartCampaignAccept: isStartCampaignAccept || true,
      winBackNumber: winBackNumber || '',
      address: {
        ...address,
      },
      pickupPoint: {
        ...pickupPoint,
      },
      selectedPickupPoint: {
        ...selectedPickupPoint,
      },
      offerId,
      availablePaymentMethods,
      updateProductPriceCtrl: {
        ...updateProductPriceCtrl,
      },
      upfrontPayment: {
        ...upfrontPayment,
      },
      pickupLocationStore: {
        ...pickupLocationStore,
      },
      deliveryAdvance: {
        ...deliveryAdvance,
      },
      billingReplace: {
        ...billingReplace,
      },
      cartAsyncInfo: {
        ...cartAsyncInfo,
      },
      customerDeliveryBaseInfo: {
        ...customerDeliveryBaseInfo,
      },
      customerAsyncInfo: {
        ...customerAsyncInfo,
      },
      authorizationAsyncInfo: {
        ...authorizationAsyncInfo,
      },
      customerFlow: {
        ...customerFlow,
      },
      voucher: {
        ...voucher,
      },
      product: {
        ...product,
      },
      campaign,
      recharge,
      simTypeSelectedOption,
      isValidNumberPortability,
      billDeliveryDone,
      currentStepKey,
      isLoadingCart,
      isStartRecognition,
      isStartAddress,
      isNewShippingAddress,
      isStartDeliveryBase,
      recognitionCode,
      isStartAuthorizationAsyncInfo,
      isStartCustomerFlow,
      configuredStickyStepperLabel,
    });
  };

  /**
   * This method is used to serialize context in local storage
   */
  const serializeContext = () => {
    const contextValue = getValueToEncode(
      btoa(
        encodeURIComponent(
          JSON.stringify({
            customerId,
            owningData: {
              ...owningData,
            },
            portability: {
              ...portability,
            },
            portabilityStatus: {
              ...portabilityStatus,
            },
            findCaller: {
              ...findCaller,
            },
            termsAndConditions: {
              ...termsAndConditions,
            },
            invoice: {
              ...invoice,
            },
            billingAddress: {
              ...billingAddress,
            },
            shippingAddress: {
              ...shippingAddress,
            },
            consents: {
              ...consents,
            },
            contactInfo: {
              ...contactInfo,
            },
            upfrontSelection: {
              ...upfrontSelection,
            },
            simOptions: {
              ...simOptions,
            },
            billingEmail,
            isReadOnly: isReadOnly || false,
            isLastCard: isLastCard || false,
            isFlowReady: isFlowReady || true,
            winBackNumber: winBackNumber || '',
            address: {
              ...address,
            },
            pickupPoint: {
              ...pickupPoint,
            },
            selectedPickupPoint: {
              ...selectedPickupPoint,
            },
            offerId,
            availablePaymentMethods,
            updateProductPriceCtrl: {
              ...updateProductPriceCtrl,
            },
            upfrontPayment: {
              ...upfrontPayment,
            },
            pickupLocationStore: {
              ...pickupLocationStore,
            },
            deliveryAdvance: {
              ...deliveryAdvance,
            },
            billingReplace: {
              ...billingReplace,
            },
            cartAsyncInfo: {
              ...cartAsyncInfo,
            },
            customerDeliveryBaseInfo: {
              ...customerDeliveryBaseInfo,
            },
            customerAsyncInfo: {
              ...customerAsyncInfo,
            },
            authorizationAsyncInfo: {
              ...authorizationAsyncInfo,
            },
            recharge: {
              ...recharge,
            },
            customerFlow: {
              ...customerFlow,
            },
            voucher: {
              ...voucher,
            },
            campaign: {
              ...campaign,
            },
            simTypeSelectedOption,
            isValidNumberPortability,
            billDeliveryDone,
            currentStepKey,
            isLoadingCart,
            isStartRecognition,
            isStartAddress,
            isNewShippingAddress,
            isStartDeliveryBase,
            recognitionCode,
            isStartAuthorizationAsyncInfo,
            isModalButtonSticky,
            isStartCustomerFlow,
            configuredStickyStepperLabel,
            isStartCampaign,
            isStartCampaignAccept,
          })
        )
      )
    );
    localStorage.setItem(CONTEXT_KEY, contextValue);
  };

  /**
   * This methos is used to delete the context key from local storage
   */
  const deleteSerializedContext = () => {
    localStorage.removeItem(CONTEXT_KEY);
  };

  /**
   * Method to check context exists
   */
  const checkInitContext = (): boolean => {
    const ctx = localStorage.getItem(CONTEXT_KEY);
    return !!ctx;
  };

  /**
   * Method to init context if exists
   */
  const initContext = (initContextData?: IInitContext): boolean => {
    const { customerData, countries, isLogged } = initContextData || {};
    let owningIndividual: IOwningIndividual;
    let isValid = false;
    if (customerData && countries) {
      isValid = !!isLogged;
      const cusData = Array.isArray(customerData) ? customerData?.[0] : customerData;
      owningIndividual = { ...retrieveOwningIndividual(cusData, countries) };
    }
    const ctx = getSerializeContext();
    setTimeout(() => {
      const localProduct = retrieveProduct() as IProduct;
      setCustomerId(ctx?.customerId || DEFAULT_CUSTOMER_ID);
      setOwningData(
        {
          ...ctx?.owningData,
          owningIndividual: {
            ...ctx?.owningData?.owningIndividual,
            ...owningIndividual,
          },
          isLogged: ctx?.owningData?.isLogged || isLogged,
          isValid: ctx?.owningData?.isValid || isValid,
        } || DEFAULT_DATA
      );
      setContactInfo(ctx?.contactInfo || DEFAULT_CONTACT_INFO);
      setPortability(ctx?.portability || DEFAULT_PORTABILITY);
      setPortabilityStatus(ctx?.portabilityStatus || DEFAULT_PORTABILITY_STATUS);
      setConsents(ctx?.consents || DEFAULT_CONSENTS);
      setFindCaller(ctx?.findCaller || DEFAULT_FIND_CALLER);
      setIsReadOnly(ctx?.isReadOnly || DEFAULT_READ_ONLY);
      setIsLastCard(ctx?.isLastCard || false);
      setIsFlowReady(ctx?.isFlowReady || true);
      setTermsAndConditions(
        { ...termsAndConditions, ...ctx?.termsAndConditions } || DEFAULT_TERMS_AND_CONDITIONS
      );
      setInvoice(ctx?.invoice || DEFAULT_INVOICE);
      setBillingEmail(ctx?.billingEmail || DEFAULT_BILLING_EMAIL);
      setBillingAddress(ctx?.billingAddress || DEFAULT_BILLING_ADDRESS);
      setShippingAddress(ctx?.shippingAddress || DEFAULT_SHIPPING_ADDRESS);
      setPickupPoint(ctx?.pickupPoint || DEFAULT_PICKUP_POINT);
      setSelectedPickupPoint(ctx?.selectedPickupPoint || DEFAULT_PICKUP_POINT);
      setCurrentStepKey(ctx?.currentStepKey || DEFAULT_CURRENT_STEP_KEY);
      setUpfrontSelection(ctx?.upfrontSelection || DEFAULT_UPFRONT_SELECTION);
      setSimOptions(ctx?.simOptions || DEFAULT_SIM_OPTIONS);
      setRecharge(ctx?.recharge || DEFAULT_RECHARGE_SELECTION);
      setAvailablePaymentMethods(ctx?.availablePaymentMethods || []);
      setUpdateProductPriceCtrl({ ...ctx?.updateProductPriceCtrl } || DEFAULT_UPDATE_PRODUCT_PRICE);
      setIsValidNumberPortability(
        ctx?.isValidNumberPortability || DEFAULT_IS_VALID_NUMBER_PORTABILITY
      );
      setBillDeliveryDone(ctx?.billDeliveryDone || DEFAULT_IS_VALID_NUMBER_PORTABILITY);
      setUpfrontPayment(ctx?.upfrontPayment || undefined);
      setIsLoadingCart(ctx?.isLoadingCart || true);
      setIsPreselectedCard(ctx?.isPreselectedCard || false);
      setWinBackNumber(ctx?.winBackNumber || DEFAULT_WINBACK_NUMBER);
      setPickupLocationStore(ctx?.pickupLocationStore || DEFAULT_PICKUP_LOCATION_STORE);
      setSimTypeSelectedOption(ctx?.simTypeSelectedOption || DEFAULT_SIM_TYPE_SELECTED_OPTION);
      setIsStartRecognition(ctx?.isStartRecognition || DEFAULT_IS_START_RECOGNITION);
      setIsStartAddress(ctx?.isStartAddress || DEFAULT_IS_START_ADDRESS);
      setDeliveryAdvance(ctx?.deliveryAdvance || DEFAULT_DELIVERY_ADVANCE);
      setRecognitionCode(ctx?.recognitionCode || DEFAULT_RECOGNITION_CODE);
      setBillingReplace(ctx?.billingReplace || DEFAULT_BILLING_REPLACE);
      setIsNewShippingAddress(ctx?.isNewShippingAddress || DEFAULT_IS_NEW_ADDRESS);
      setIsStartDeliveryBase(ctx?.isStartDeliveryBase || DEFAULT_IS_START_DELIVERY_BASE);
      setCartAsyncInfo(ctx?.cartAsyncInfo || DEFAULT_CART_ASYNC_INFO);
      setCustomerDeliveryBaseInfo(
        ctx?.customerDeliveryBaseInfo || DEFAULT_CUSTOMER_DELIVERY_BASE_INFO
      );
      setCustomerAsyncInfo(ctx?.customerAsyncInfo || DEFAULT_CUSTOMER_ASYNC_INFO);
      setAuthorizationAsyncInfo(ctx?.authorizationAsyncInfo || DEFAULT_AUTHORIZATION_ASYNC_INFO);
      setIsStartAuthorizationAsyncInfo(
        ctx?.isStartAuthorizationAsyncInfo || DEFAULT_IS_START_AUTHORIZATION_ASYNC_INFO
      );
      setIsModalButtonSticky(ctx?.isModalButtonSticky || false);
      setIsStartCustomerFlow(ctx?.isStartCustomerFlow || false);
      setIsStartCampaign(ctx?.isStartCampaign || false);
      setIsStartAcceptCampaign(ctx?.isStartCampaignAccept || false);
      setCustomerFlow(ctx?.customerFlow || DEFAULT_CUSTOMER_FLOW);
      setVoucher(ctx?.voucher || DEFAULT_VOUCHER_DISCOUNT);
      setConfiguredStickyStepperLabel(ctx?.configuredStickyStepperLabel || '');
      if (localProduct) setProduct(localProduct);
      setCampaign(ctx?.campaign || DEFAULT_CAMPAIGN);
    }, 0);
    return true;
  };

  return (
    <CheckoutContext.Provider
      /* eslint-disable-next-line react/jsx-no-constructed-context-values */
      value={{
        customerId,
        setCustomerId,
        owningData,
        setOwningData,
        contactInfo,
        setContactInfo,
        portability,
        setPortability,
        portabilityStatus,
        setPortabilityStatus,
        consents,
        setConsents,
        findCaller,
        setFindCaller,
        isReadOnly,
        setIsReadOnly,
        isLastCard,
        setIsLastCard,
        isFlowReady,
        setIsFlowReady,
        termsAndConditions,
        setTermsAndConditions,
        invoice,
        setInvoice,
        slidesGoBack,
        setSlidesGoBack,
        currentStepKey,
        setCurrentStepKey,
        serializeContext,
        deleteSerializedContext,
        printContext,
        checkInitContext,
        initContext,
        upfrontSelection,
        setUpfrontSelection,
        isDisabledBackButton,
        setIsDisabledBackButton,
        offerId,
        setOfferId,
        address,
        setAddress,
        pickupPoint,
        setPickupPoint,
        selectedPickupPoint,
        setSelectedPickupPoint,
        billingAddress,
        setBillingAddress,
        oldBillingAddress,
        setOldBillingAddress,
        oldShippingAddress,
        setOldShippingAddress,
        shippingAddress,
        setShippingAddress,
        isDelayedICCIDretrive,
        setIsDelayedICCIDretrive,
        ICCID,
        setICCID,
        simOptions,
        setSimOptions,
        availablePaymentMethods,
        setAvailablePaymentMethods,
        recharge,
        setRecharge,
        hideStickyBar,
        setHideStickyBar,
        keepsNumber,
        setKeepsNumber,
        updateProductPriceCtrl,
        setUpdateProductPriceCtrl,
        isValidNumberPortability,
        setIsValidNumberPortability,
        billDeliveryDone,
        setBillDeliveryDone,
        upfrontPayment,
        setUpfrontPayment,
        isLoadingCart,
        setIsLoadingCart,
        isPreselectedCard,
        setIsPreselectedCard,
        winBackNumber,
        setWinBackNumber,
        deliveryAdvance,
        setDeliveryAdvance,
        pickupLocationStore,
        setPickupLocationStore,
        simTypeSelectedOption,
        setSimTypeSelectedOption,
        isStartRecognition,
        setIsStartRecognition,
        isStartAddress,
        setIsStartAddress,
        recognitionCode,
        setRecognitionCode,
        billingReplace,
        setBillingReplace,
        isNewShippingAddress,
        setIsNewShippingAddress,
        isStartDeliveryBase,
        setIsStartDeliveryBase,
        cartAsyncInfo,
        setCartAsyncInfo,
        customerDeliveryBaseInfo,
        setCustomerDeliveryBaseInfo,
        customerAsyncInfo,
        setCustomerAsyncInfo,
        authorizationAsyncInfo,
        setAuthorizationAsyncInfo,
        isStartAuthorizationAsyncInfo,
        setIsStartAuthorizationAsyncInfo,
        isModalButtonSticky,
        setIsModalButtonSticky,
        isStartCustomerFlow,
        setIsStartCustomerFlow,
        customerFlow,
        setCustomerFlow,
        voucher,
        setVoucher,
        configuredStickyStepperLabel,
        setConfiguredStickyStepperLabel,
        checkoutErrors,
        setCheckoutErrors,
        product,
        setProduct,
        campaign,
        setCampaign,
        isStartCampaign,
        setIsStartCampaign,
        isStartCampaignAccept,
        setIsStartAcceptCampaign,
        billingEmail,
        setBillingEmail,
      }}
    >
      {children}
    </CheckoutContext.Provider>
  );
};

export const useCheckout = () => useContext(CheckoutContext);

