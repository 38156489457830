/* eslint-disable react-hooks/exhaustive-deps */
import { useContext, useEffect, useState } from 'react';
import { VfModal } from '@vfit/shared/components';
import { useQueryClient } from 'react-query';
import {
  CMS_CONFIG,
  CTC_POSITION,
  CoverageErrorType,
  ICoverageToolCongifg,
  IEcommerceCatalogResponse,
  getProductVersion,
  useCmsConfig,
  useVolaUtils,
} from '@vfit/business-data-access';
import { ButtonSlideCustom, CustomText } from '@vfit/shared/atoms';
import { IActionCustom, IActionType, ICommonData } from '@vfit/shared/models';
import { API, PAGES } from '@vfit/shared/data-access';
import { TrackingPageContext } from '@vfit/business-data-access';
import { resetData } from '@vfit/shared/data-access';
import { IProductCard } from '../../coverageToolModal.models';
import CoverageToolMap from '../../CoverageToolMap/coverageToolMap';
import { useCoverageToolModalProvider } from '../../coverageToolModal.context';
import { IInputs } from '../../CoverageTool/coverageTool.models';
import { IModalConfig } from './coverageToolMapCard.models';
import { ButtonDiv, ModalTitle, ModalWrap } from './coverageToolMapCard.style';
// eslint-disable-next-line import/no-cycle
import { EditAddress } from '../CoverageToolOtpCard/coverageToolOtpCard.style';
import { useCoverageToolFlow } from '../../coverageTool.hooks';

const CoverageToolMapCard = ({
  handleGoNextSlide,
  handleGoPrevSlide,
  handleOnClose,
}: ICommonData) => {
  const { coveragetool, tagging } = useCmsConfig(
    CMS_CONFIG[PAGES.BUSINESS_FIXED_LINE],
    API.CMS_BFL_GET_COVERAGE_TOOLS
  ) as ICoverageToolCongifg;
  const { koCoverageTool } = coveragetool;

  const [onConfirmAddress, setOnConfirmAddress] = useState<boolean>(false);
  const queryClient = useQueryClient();
  const { view, link, currentDataLayer, setCurrentDataLayer, descriptionVolaUrl } =
    useContext(TrackingPageContext);

  const { address, setAddress, setSlidesGoBack, isManual } = useCoverageToolModalProvider();

  const coverageProduct: IProductCard | undefined = queryClient.getQueryData('coverageToolProduct');
  const { formatUrlVola, openPopupVola, createUrlDesc, formatDynamicVolaUrl } = useVolaUtils();

  const catalog = queryClient.getQueryData([
    coverageProduct?.offerId,
    'ecommerceCatalogCoreOffer',
  ]) as IEcommerceCatalogResponse;

  const version = getProductVersion(
    coverageProduct?.offerId,
    coverageProduct?.version,
    coverageProduct?.landingprive?.isPrive,
    coverageProduct?.retarget
  );

  const { isSuccess, isLoading, errorType } = useCoverageToolFlow({
    onConfirmAddress,
    product: [
      {
        productId: coverageProduct?.offerId || '',
        productType: catalog?.productInfo?.[0]?.productType,
        productOfferingId: catalog?.productInfo?.[0]?.productOfferingId,
        version,
      },
    ],
    address: {
      addressCode: address.placeId,
      streetName: address.street,
      streetNr: address.streetNumber,
      city: address.city,
      stateOrProvince: address.stateOrProvince,
      postcode: address.postalCode,
    },
  });

  const [modalConfig, setModalConfig] = useState<IModalConfig | null>(null);

  const onClickEditAddress = () => {
    setModalConfig(null);
    setTimeout(() => {
      setSlidesGoBack(isManual ? 2 : 1);
    }, 200);
  };

  useEffect(() => {
    // TRACKING
    const datalayer = view(
      {
        event_name: ['tool_end'],
        page_section: tagging?.pageSection,
        page_subsection: tagging?.pageSubsection,
        page_type: tagging?.pageType,
        journey_name: 'coverage tool',
        journey_type: 'journey',
        event_category: [tagging?.eventCategory || ''],
      },
      coverageProduct?.offerId
    );
    setCurrentDataLayer({ ...currentDataLayer, coverageToolDataLayer: datalayer });
  }, []);

  useEffect(() => {
    if (isSuccess && onConfirmAddress && handleGoNextSlide) {
      queryClient.setQueryData('validAddress', address);
      setOnConfirmAddress(false);
      handleGoNextSlide({}, true);
    }
  }, [isSuccess]);

  useEffect(() => {
    const { city, street, streetNumber } = address;
    const dataToDisplay = [street || ' ', streetNumber || '2', '-', city || ' '].join(' ');
    if (errorType === CoverageErrorType.KO_COVERAGE) {
      setModalConfig({
        isOpen: true,
        onCloseAction: handleOnClose,
        title: koCoverageTool.title,
        description:
          koCoverageTool.description.replace('PLACEHOLDER', `${dataToDisplay}` || '') || '',
        actions: koCoverageTool?.actions,
      });
    }
  }, [errorType]);

  useEffect(() => {
    if (modalConfig?.isOpen) {
      // TRACKING
      const datalayer = view(
        {
          event_name: ['tool_fail'],
          page_type: 'coverage ko',
        },
        coverageProduct?.offerId
      );
      setCurrentDataLayer({ ...currentDataLayer, coverageToolDataLayer: datalayer });
    }
  }, [modalConfig?.isOpen]);

  const goPrevSlide = (data: IInputs) => {
    setAddress(data);
    setOnConfirmAddress(false);
    if (handleGoPrevSlide) {
      handleGoPrevSlide();
    }
  };

  const handleOnRetry = () => {
    if (handleGoPrevSlide) handleGoPrevSlide();
  };

  const handleClick = (action: IActionCustom, desc?: string) => {
    if (action?.type == IActionType.CALL_ME_NOW) {
      if (action?.url) {
        if (descriptionVolaUrl && desc)
          openPopupVola(formatDynamicVolaUrl(action?.url, desc), 'vola-ctc');
        else openPopupVola(formatUrlVola(action?.url, coverageProduct!), 'vola-ctc');
      }
      link(
        {
          ...currentDataLayer?.coverageToolDataLayer,
          link_name: `${currentDataLayer?.coverageToolDataLayer?.page_name}:CTC`,
        },
        coverageProduct?.offerId
      );
    } else onClickEditAddress();
  };

  return (
    <>
      <CoverageToolMap
        onConfirm={() => {
          resetData(queryClient, ['ecommerceCatalogWithAddress', 'createShoppingCart']);
          setOnConfirmAddress(true);
        }}
        onEditAddress={goPrevSlide}
        address={address}
        isLoading={isLoading}
        errorType={errorType}
        onReTry={handleOnRetry}
        offerId={coverageProduct?.offerId || ''}
      />
      <VfModal
        isOpen={modalConfig?.isOpen || false}
        handleClose={() => {
          setModalConfig(null);
          if (modalConfig?.onCloseAction) {
            modalConfig?.onCloseAction();
          }
        }}
      >
        <ModalWrap>
          <div>
            <ModalTitle>{modalConfig?.title}</ModalTitle>
            <CustomText
              text={modalConfig?.description || ''}
              textAlign="left"
              size={18}
              lineHeight={24}
              sizeMobile={18}
              lineHeightMobile={24}
            />
            {modalConfig?.subDesc && (
              <div>
                {modalConfig?.subDesc}
                <EditAddress onClick={onClickEditAddress}>Modifica indirizzo</EditAddress>
              </div>
            )}
          </div>
          <ButtonDiv>
            {modalConfig?.actions &&
              modalConfig?.actions?.map((b) => (
                <ButtonSlideCustom
                  label={b?.title}
                  onClick={() => {
                    const desc = createUrlDesc({
                      position: CTC_POSITION.COVERAGE_TOOL_KO,
                      productName: coverageProduct?.taggingProductName || coverageProduct?.title,
                      productPrice: coverageProduct?.taggingProductPrice,
                    });
                    handleClick(b, desc);
                  }}
                  style={b?.style}
                />
              ))}
          </ButtonDiv>
        </ModalWrap>
      </VfModal>
    </>
  );
};

export default CoverageToolMapCard;

