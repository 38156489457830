import { useQueryClient } from 'react-query';
import {
  IPageProps,
  retrieveCartCookie,
  IWidgetType,
  usePage,
  IN_ACTIVATION,
  useTranslate
} from '@vfit/consumer/data-access';
import { SoftManagerService } from '@vfit/consumer/providers';
import { Footer, Header } from '@vfit/shared/components';
import { useTrackingProvider } from '@vfit/shared/data-access';
import { isAcceptedCookieLawGroup, useFooter, useHeader } from '@vfit/shared/data-access';
import { StickyBannerHeader } from '@vfit/consumer/components';
import { ICartCookie } from '@vfit/shared/models';
import { useEffect, useState } from 'react';
import { withProtected } from '../Hoc/withProtected';
import Widgets from '../../Widgets/widgets';
import TaggingHub from './hub.tagging';
import BrightSky from '../../BrightSky/brightSky';

const Hub = ({ isApp }: IPageProps) => {
  const { page, pageNavigation, products } = usePage();
  const client = useQueryClient();
  const [isEnabledStickyBanner, setIsEnabledStickyBanner] = useState(false);
  const { isReady: isReadyTagging } = useTrackingProvider();
  const { translate } = useTranslate();
  const { elements, haveHeader, isHeaderSmall, haveFooter, isDisabledInitialTrack, isNewHeader } =
    page?.params || {};
  const { widgets, stickybanner, topheaderbanner } = elements || {};
  const { header, tagging } = useHeader(pageNavigation);
  const { footer } = useFooter(pageNavigation);
  const queryString = new URLSearchParams(window?.location?.search);
  const queryParamsDisableTrack = !!queryString?.get(IN_ACTIVATION) || !!queryString?.get('app');

  const pageProduct = SoftManagerService(client).getSoftProductsByProducts(
    products?.filter((el) => el.isShowedInHub) || []
  );

  client.setQueryData('pageDatalayer', {
    event: ['product_view'],
    opts: elements?.taggingOptions,
    pageProduct,
    visitorTrackingOpts: SoftManagerService(client).getUser(),
  });

  const checkStickyBanner = () => {
    const cookieCart = retrieveCartCookie() as ICartCookie;
    const showCookieCart = Boolean(cookieCart) && isAcceptedCookieLawGroup('4');
    const droppedCartWidget = widgets?.sliders?.some((e) => e.type === IWidgetType.DROPPED_CART);
    setIsEnabledStickyBanner(!(showCookieCart && droppedCartWidget));
  };

  useEffect(() => {
    checkStickyBanner();
  }, []);

  return (
    <div>
      {isReadyTagging && (
        <TaggingHub
          taggingOptions={elements?.taggingOptions}
          isDisabledInitialTrack={isDisabledInitialTrack || queryParamsDisableTrack}
          pageProduct={pageProduct}
        />
      )}
      {haveHeader && header && !isApp && (
        <Header
          onTrack={tagging}
          header={header}
          lite={isHeaderSmall}
          onTranslate={translate}
          isExtendedHeader={isNewHeader}
          topHeaderBanner={topheaderbanner}
        />
      )}
      {stickybanner && isEnabledStickyBanner && (
        <StickyBannerHeader bannerCustomConfig={stickybanner} />
      )}
      {widgets?.sliders && (
        <div className="noPadding">
          <Widgets widgets={widgets?.sliders} productsAll={products} tagging={tagging} />
        </div>
      )}
      {haveFooter && footer && !isApp && (
        <Footer onTrack={`${tagging}_FOOTER`} footer={footer} subChildren={<BrightSky />} />
      )}
    </div>
  );
};

const HubProtected = withProtected()(Hub);

export default HubProtected;
