import { Children, useCallback, useEffect, useState } from 'react';
import { VfModal } from '@vfit/shared/components';
import { LinkWithIcon } from '@vfit/shared/atoms';
import { useDeviceType } from '@vfit/shared/hooks';
import { IPartyPrivacyItem } from '@vfit/shared/models';
import { trackLink } from '@vfit/shared/data-access';
import {
  Container,
  Description,
  DetailButton,
  Subtitle,
  ListElTitle,
  Title,
  ListEl,
  CheckboxDiv,
  Checkbox,
  DocsDiv,
  DocCheck,
} from './consents.style';
import { IConsentsProps } from './consents.models';
import ConsentsCustomize from './components/ConsentsCustomize/consentsCustomize';
import { Check } from '@vfit/shared-icons';

const setModalHeight = (isTablet: boolean, isDesktop: boolean): number | undefined =>
  (isTablet && 563) || (isDesktop && 563) || undefined;

const Consents = ({
  partyPrivacyItems,
  title,
  description,
  subtitle,
  actionText,
  onUpdateConsents,
  config,
  trackView,
}: IConsentsProps) => {
  const { title: titleConfig, acceptAll, description: descriptionConfig, buttonText } = config;

  const checkBoxInitialValue: boolean =
    partyPrivacyItems.length > 0
      ? partyPrivacyItems.every((consent) => consent.selectedValue)
      : false;

  const [docCheck, setDocCheck] = useState<boolean>(checkBoxInitialValue);
  const [hasClickedCheckbox, setHasClickedCheckbox] = useState<boolean>(false);

  const [openModal, setOpenModal] = useState<boolean>(false);
  const { isTablet, isDesktop } = useDeviceType();

  const handleHideModal = useCallback(() => {
    setOpenModal(false);
  }, []);

  const handleAcceptAndCloseModal = useCallback(
    (cList: IPartyPrivacyItem[]) => {
      onUpdateConsents(cList, true);
      setOpenModal(false);
    },
    [onUpdateConsents]
  );

  const handleOnPersonalize = () => {
    setOpenModal(!openModal);
  };

  useEffect(() => {
    if (hasClickedCheckbox && partyPrivacyItems) {
      const newConsentList =
        partyPrivacyItems?.map((consent) => ({
          ...consent,
          selectedValue: docCheck,
        })) || [];
      if (newConsentList && newConsentList?.length > 0) onUpdateConsents?.(newConsentList, false);
    }
  }, [docCheck]);

  const handleCheckBox = () => {
    setHasClickedCheckbox(true);
    setDocCheck(!docCheck);
    let onOff = 'on';
    let checkoutConsents = 'true';

    if (docCheck) {
      onOff = 'off';
      checkoutConsents = 'false';
    }

    trackView?.({
      event_name: 'ui_interaction',
      event_label_track: `all consents ${onOff}`,
      event_category: 'users',
      event_action: 'click',
      tracking_type: 'link',
      checkout_consents: checkoutConsents,
    });
  };

  return (
    <Container>
      <Title>{title}</Title>
      <Description>{description}</Description>
      <Subtitle>{subtitle}</Subtitle>
      {Children.toArray(
        partyPrivacyItems.map((c) => (
          <ListEl>
            <ListElTitle>
              {c.selectedValue ? (
                <div className="check-size">
                  <Check />
                </div>
              ) : (
                <i>&#x2022;</i>
              )}
              {c.name}
            </ListElTitle>
            <div>{c.description}</div>
          </ListEl>
        ))
      )}

      <DocsDiv>
        <CheckboxDiv>
          <Checkbox>
            <input
              type="checkbox"
              checked={docCheck}
              onClick={handleCheckBox}
              onChange={() => {}}
            />
            <span className="checkmark" />
          </Checkbox>
        </CheckboxDiv>

        <DocCheck>{acceptAll}</DocCheck>
      </DocsDiv>

      <DetailButton>
        <LinkWithIcon
          size="2"
          onClick={() => {
            trackLink(actionText || '');
            handleOnPersonalize();
          }}
          text={actionText}
        />
      </DetailButton>

      <VfModal
        height={setModalHeight(isTablet, isDesktop)}
        isOpen={openModal}
        handleClose={handleHideModal}
      >
        <ConsentsCustomize
          title={titleConfig}
          description={descriptionConfig}
          buttonText={buttonText}
          partyPrivacyItems={partyPrivacyItems}
          handleClose={handleAcceptAndCloseModal}
        />
      </VfModal>
    </Container>
  );
};

export default Consents;
