import { useEffect, useState } from 'react';
import { API, getFromLocalStorageByKey, PAGES, setTrackLink } from '@vfit/shared/data-access';
import { ICommonData } from '@vfit/shared/models';
import { useTracking } from '@vfit/shared/data-access';
import { Skeleton } from '@vfit/shared/components';
import {
  CMS_CONFIG,
  IGenericErrorCMS,
  useCmsConfig,
  useRetrieveMultiLines,
} from '@vfit/consumer/data-access';
import {useQueryClient} from "react-query";
import { getUserInfo, getTagging } from '../../checkout.utils';
import { useCheckout } from '../../../iBuyFixed.context';
import { skeletonShape } from './components/ConsentsList/consentsList.style';
import ConsentsList from './components/ConsentsList/consentsList';

const ConsentCard = (props: ICommonData) => {
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const { checkoutErrors, setIsDisabledBackButton } = useCheckout();
  const queryClient = useQueryClient()
  const tagging = getTagging('consents', 'fixed', queryClient);
  const genericError = useCmsConfig(
    CMS_CONFIG[PAGES.CONSUMER],
    API.CMS_GET_GENERIC_ERROR
  ) as IGenericErrorCMS;

  const { trackView } = useTracking({
    event: ['checkout_step8'],
    event_label: 'consents',
    opts: tagging.opts,
    cartProduct: tagging.trackingProduct,
    pageProduct: tagging?.pageProductsInfo,
    visitorTrackingOpts: getUserInfo(),
  });
  setTrackLink(trackView, 'consents');

  const {
    error: retrieveMultilinesError,
    isSuccess: isSuccessRetrieveMultilines,
    isError: isErrorRetrieveMultilines,
    isLoading: isLoadingRetrieveMultilines,
    mutate: retrieveMultilinesMutation,
  } = useRetrieveMultiLines();

  const checkRetrieveMultilines = () => {
    const retrieveMultilines = getFromLocalStorageByKey('retrieveMultiLines');
    if (retrieveMultilines) setIsLoading(false);
    else retrieveMultilinesMutation();
  };

  useEffect(() => {
    if (isErrorRetrieveMultilines) {
      const { handleOnClose } = props || {};
      checkoutErrors?.showAndTrackError?.(genericError, () => handleOnClose?.(), {
        ...retrieveMultilinesError,
        isBlocking: true,
      });
    } else if (isSuccessRetrieveMultilines) {
      setIsLoading(false);
    }
  }, [isLoadingRetrieveMultilines, isErrorRetrieveMultilines, isSuccessRetrieveMultilines]);

  useEffect(() => {
    setIsDisabledBackButton(isLoading);
  }, [isLoading]);

  useEffect(() => {
    checkRetrieveMultilines();
  }, []);

  return (
    <>
      {isLoading && <Skeleton {...skeletonShape} />}
      {!isLoading && <ConsentsList {...props} />}
    </>
  );
};

export default ConsentCard;
