import {
  IAddressDataObject,
  IFindCallerObject,
  IInputsCoverageTool,
  IInvoiceObject,
  IPersonalDataObject,
  IPickUpLocation,
  IPortabilityObject,
} from '@vfit/shared/models';
import {
  IContactInfo,
  IConsentsObject,
  ITermsAndConditionsCheck,
  IUpfrontSelection,
  IRechargeOption,
  IUpdateProductPriceControl,
  IPortabilityStatus,
} from './Checkout/checkout.models';
import { IUseCustomerDeliveryBaseResponse } from './Checkout/hooks/UseCustomerDeliveryBase/useCustomerDeliveryBase.models';
import { IUseCustomerAsyncInformationResponse } from './Checkout/hooks/UseCustomerAsyncInformation/useCustomerAsyncInformation.models';
import { IUseAuthorizationAsyncInformationResponse } from './Checkout/hooks/UseAuthorizationAsyncInformation/useAuthorizationAsyncInformation.models';
import { IUseCustomerResponse } from './Checkout/hooks/UseCustomerFlow/useCustomerFlow.models';
import { IUseVoucherDiscountResponse } from './Checkout/hooks/UseVoucherDiscount/useVoucherDiscount.models';
import { IUseCheckoutErrors } from './Checkout/hooks/UseCheckoutErrors/useCheckoutErrors.models';
import { IUseCampaign } from './Checkout/hooks/UseForceCampaignFlow/useForceCampaignFlow.models';

export const CONTEXT_KEY = 'ctxk';

export const DEFAULT_CUSTOMER_ID = '';

export const DEFAULT_DATA: IPersonalDataObject = {
  owningIndividual: {
    nation: '',
    fiscalCode: '',
    firstName: '',
    lastName: '',
    contactMedium: [
      {
        type: 'email',
        preferred: true,
        emailAddress: '',
      },
      { type: 'phone', preferred: false, phoneNumber: '' },
    ],
    identification: [
      {
        identificationType: '',
        nationality: '',
        identificationNumber: '',
        expirationDate: '',
      },
    ],
  },
  isValid: false,
  isLogged: false,
};

export const DEFAULT_CONTACT_INFO: IContactInfo = {
  checkContactProblem: true,
};

export const DEFAULT_PORTABILITY_MIGRATION = {
  firstOperator: '',
  firstCode: '',
  firstCodeValid: false,
  secondOperator: '',
  secondCode: '',
  secondCodeValid: false,
  noCode: false,
};

export const DEFAULT_PORTABILITY: IPortabilityObject = {
  portabilityNumber: '',
  portabilityWant: undefined,
  portabilityMigration: {
    currentProvider: '',
    currentPlan: '',
    transferBalance: false,
  },
};

export const DEFAULT_PORTABILITY_STATUS: IPortabilityStatus = {
  isStartPortIn: false,
  isStartFinalPortIn: false,
  isSuccessCheck: false,
  isLoadingCheck: false,
  isErrorCheck: false,
  isSuccess: false,
  isLoading: false,
  isError: false,
  errorMessage: '',
};

export const DEFAULT_CONSENTS: IConsentsObject = {
  partyPrivacyItems: [],
};

export const DEFAULT_BILLING_ADDRESS: IAddressDataObject = {
  city: '',
  postalCode: '',
  stateOrProvince: '',
  street: '',
  streetNumber: '',
  href: '',
  id: '',
  country: '',
  displayCountry: '',
  formattedAddress: '',
  formattedAddress1: '',
  addressStatus: '',
  externalId: '',
  homeZone: '',
  istatCode: '',
};

export const DEFAULT_SHIPPING_ADDRESS: IAddressDataObject = {
  city: '',
  postalCode: '',
  stateOrProvince: '',
  street: '',
  streetNumber: '',
  href: '',
  id: '',
  country: '',
  displayCountry: '',
  formattedAddress: '',
  formattedAddress1: '',
  addressStatus: '',
  externalId: '',
  homeZone: '',
  istatCode: '',
};

export const DEFAULT_FIND_CALLER: IFindCallerObject = {
  activeSimMobile: false,
  individualRef: {
    msisdn: '',
    email: '',
  },
  selectedContactMedium: {
    field: '',
    fieldType: '',
  },
  showOtp: false,
  verifyOtp: '',
  customerHasActiveSubs: false,
};

export const DEFAULT_READ_ONLY = false;

export const DEFAULT_LAST_CARD = false;

export const DEFAULT_IS_FLOW_READY = false;

export const DEFAULT_IS_DELAYED_ICCID_RETRIVE = false;

export const DEFAULT_ICCID = null;

export const DEFAULT_SIM_OPTIONS = {
  cartId: '',
  simOptions: [],
};

export const DEFAULT_TERMS_AND_CONDITIONS: ITermsAndConditionsCheck = {
  consensus: false,
  optionToReconsider: '',
  isSuccessTermsAndConditions: false,
  isLoadingTermsAndConditions: false,
  isErrorTermsAndConditions: false,
};

export const DEFAULT_INVOICE: IInvoiceObject = {
  option: '',
};

export const DEFAULT_UPFRONT_SELECTION: IUpfrontSelection = {
  option: '',
};

export const DEFAULT_RECHARGE_SELECTION: IRechargeOption = {
  option: 'Yes',
};

export const DEFAULT_SLIDES_GO_BACK = -1;

export const DEFAULT_ADDRESS: IInputsCoverageTool = {
  placeId: '',
  city: '',
  postalCode: '',
  stateOrProvince: '',
  street: '',
  streetNumber: '',
};

export const DEFAULT_DELIVERY_BASE = {
  isSuccess: false,
  isLoading: false,
  isError: false,
  errorMessage: '',
  deliveryOptions: [],
  identificationDetails: [],
};

export const DEFAULT_DELIVERY_ADVANCE = {
  isSuccess: false,
  isLoading: false,
  isError: false,
  deliveryOptions: [],
  identificationDetails: [],
};

export const DEFAULT_PICKUP_POINT: IPickUpLocation = {
  storeId: '',
  locationAddress: {
    id: '',
    city: '',
    country: '',
    displayCountry: '',
    stateOrProvince: '',
    displayStateOrProvince: '',
    longitude: '',
    latitude: '',
    postalCode: '',
    formattedAddress1: '',
    postalOfficeID: '',
    postalOfficeDescription: '',
  },
  aerialDistance: 1.521,
};

export const DEFAULT_IS_DISABLED_BACK_BUTTON = false;

export const DEFAULT_KEEPS_NUMBER = undefined;

export const DEFAULT_IS_VALID_NUMBER_PORTABILITY = false;

export const DEFAULT_WINBACK_NUMBER = '';

export const DEFAULT_OFFER_ID = '';

export const DEFAULT_UPDATE_PRODUCT_PRICE: IUpdateProductPriceControl = {
  isUpdatePrice: false,
  isPriceUpdated: true,
};

export const DEFAULT_PICKUP_LOCATION_STORE = {
  startDeliveryLocateAndPickupPoint: () => {},
  global: {
    isLoading: false,
    isSuccess: false,
    isError: false,
  },
  searchPickupLocations: {
    isLoading: false,
    isSuccess: false,
    isError: false,
  },
  searchPostalOffice: {
    isLoading: false,
    isSuccess: false,
    isError: false,
  },
  searchLocateStore: {
    isLoading: false,
    isSuccess: false,
    isError: false,
  },
};

export const DEFAULT_BILLING_REPLACE = {
  validateAddress: {
    isLoading: false,
    isSuccess: false,
    isError: false,
  },
  createAddress: {
    isLoading: false,
    isSuccess: false,
    isError: false,
  },
  replaceAddress: {
    isLoading: false,
    isSuccess: false,
    isError: false,
  },
  billingPaper: {
    isLoading: false,
    isSuccess: false,
    isError: false,
  },
  billingEbill: {
    isLoading: false,
    isSuccess: false,
    isError: false,
  },
};

export const DEFAULT_CART_ASYNC_INFO = {
  simTypeSelection: {
    isLoading: false,
    isSuccess: false,
    isError: false,
  },
};

export const DEFAULT_CUSTOMER_DELIVERY_BASE_INFO: IUseCustomerDeliveryBaseResponse = {
  deliveryBase: {
    isLoading: false,
    isError: false,
    isSuccess: false,
    identificationDetails: [],
    deliveryOptions: [],
    errorMessage: '',
  },
  modifySimDevice: {
    isLoading: false,
    isSuccess: false,
    isError: false,
  },
};

export const DEFAULT_CUSTOMER_ASYNC_INFO: IUseCustomerAsyncInformationResponse = {
  retrieveMultilines: { isLoading: false, isSuccess: false, isError: false },
  termsAndConditions: { isLoading: false, isSuccess: false, isError: false },
  customerInfo: { isLoading: false, isSuccess: false, isError: false, data: undefined },
};

export const DEFAULT_AUTHORIZATION_ASYNC_INFO: IUseAuthorizationAsyncInformationResponse = {
  authorization: { isLoading: false, isSuccess: false, isError: false },
  payMean: { isLoading: false, isSuccess: false, isError: false },
};

export const DEFAULT_CUSTOMER_FLOW: IUseCustomerResponse = {
  findcaller: {
    isLoading: false,
    isSuccess: false,
    isError: false,
  },
  updateProductChatacteristics: {
    isLoading: false,
    isSuccess: false,
    isError: false,
  },
  patchCustomer: {
    isLoading: false,
    isSuccess: false,
    isError: false,
  },
  postCustomer: {
    isLoading: false,
    isSuccess: false,
    isError: false,
  },
  associateCustomer: {
    isLoading: false,
    isSuccess: false,
    isError: false,
  },
  customerInfo: {
    isLoading: false,
    isSuccess: false,
    isError: false,
  },
  payment: { isLoading: false, isSuccess: false, isError: false, data: undefined },
  billingAccount: { isLoading: false, isSuccess: false, isError: false },
  paymean: { isLoading: false, isSuccess: false, isError: false, data: undefined },
  resetPatchAndPost: () => {},
  acceptedFallbackOffer: false,
};

export const DEFAULT_CHECKOUT_ERRORS: IUseCheckoutErrors = {};

export const DEFAULT_VOUCHER_DISCOUNT: IUseVoucherDiscountResponse = {
  voucherId: '',
  bo_array: [],
  isApplyVoucher: false,
  isAppliedVoucher: false,
  validateVoucher: {
    isLoading: false,
    isSuccess: false,
    isError: false,
  },
  voucher: {
    isLoading: false,
    isSuccess: false,
    isError: false,
  },
  shoppingCart: {
    isLoading: false,
    isSuccess: false,
    isError: false,
  },
};

export const DEFAULT_CAMPAIGN: IUseCampaign = {
  campaign: {
    isLoading: false,
    isSuccess: false,
    isError: false,
    isEligible: false,
    isAccepted: false,
    isRejected: false,
    campaignProduct: undefined,
  },
  accept: {
    isLoading: false,
    isSuccess: false,
    isError: false,
    isAccepted: false,
  },
  updateCart: {
    isLoading: false,
    isSuccess: false,
    isError: false,
  },
};

export const DEFAULT_SIM_TYPE_SELECTED_OPTION = undefined;

export const DEFAULT_IS_START_RECOGNITION = false;

export const DEFAULT_IS_START_ADDRESS = false;

export const DEFAULT_IS_START_DELIVERY_BASE = false;

export const DEFAULT_IS_NEW_ADDRESS = false;

export const DEFAULT_IS_START_AUTHORIZATION_ASYNC_INFO = false;

export const DEFAULT_IS_START_CAMPAIGN = false;

export const DEFAULT_IS_START_ACCEPT_CAMPAIGN = false;

export const DEFAULT_IS_START_CUSTOMER_FLOW = false;

export const DEFAULT_RECOGNITION_CODE = '';

export const DEFAULT_PRODUCT = {};

export const DEFAULT_BILLING_EMAIL = '';

