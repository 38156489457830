import { useEffect } from 'react';
import { API, PAGES } from '@vfit/shared/data-access';
import { CMS_CONFIG, IPortabilityCMS, useCmsConfig } from '@vfit/consumer/data-access';
import { removeCurrency } from '@vfit/consumer/hooks';
import { useTracking } from '@vfit/shared/data-access';
import { GenericCard } from '@vfit/consumer/components';
import { ICommonData } from '@vfit/shared/models';
import {useQueryClient} from "react-query";
import { organizePortabilityInternetCard } from './portabilityInternetCard.utils';
import { useCheckout } from '../../../iBuyFixed.context';
import { getTagging, getUserInfo } from '../../checkout.utils';
import { ID_FLOWS } from '../../checkout.constants';
import { retrieveProduct } from '../../../iBuyFixed.utils';

const PortabilityInternetCard = ({ handleOnChangeEnableGoNext }: ICommonData) => {
  const { setCurrentStepKey } = useCheckout();
  const dataFromCms = useCmsConfig(
    CMS_CONFIG[PAGES.CONSUMER],
    API.CMS_GET_PORTABILITY
  ) as IPortabilityCMS;
  const { title, description } = organizePortabilityInternetCard(dataFromCms);
  const queryClient = useQueryClient();

  const productTracking = retrieveProduct();
  const taggingFixed = getTagging('portability', 'fixed', queryClient);

  useTracking({
    event: ['checkout_step4.5'],
    event_label: 'portability info message',
    opts: taggingFixed.opts,
    cartProduct: {
      ...taggingFixed.trackingProduct,
      cart_total: productTracking?.price && removeCurrency(productTracking.price),
    },
    pageProduct: taggingFixed?.pageProductsInfo,
    visitorTrackingOpts: getUserInfo(),
  });

  useEffect(() => {
    setCurrentStepKey(ID_FLOWS.PORTABILITY_INTERNET);
    if (handleOnChangeEnableGoNext) handleOnChangeEnableGoNext(true);
  }, []);

  return <GenericCard title={title} description={description} />;
};

export default PortabilityInternetCard;
