import {
  ALL_EVENTS,
  checkBypassApiCallFromSIA,
  createPayloadMobile,
  errorMock,
  IProduct,
  useGetCountry,
  useShoppingCartMutation,
} from '@vfit/consumer/data-access';
import { useEffect, useState } from 'react';
import { ICountryObject } from '@vfit/shared/models';
import { getFromLocalStorageByKey } from '@vfit/shared/data-access';
import { ITrackError } from '@vfit/shared/data-access';

export function useShoppingCartInfo(
  p: IProduct,
  customerId: string | undefined,
  isUserLogged: boolean,
  isDisabledCreateCart?: boolean
): {
  isCompletedSilentLogin: boolean;
  isLoading: boolean;
  isError: boolean;
  errorMessage: string;
  errorCart: ITrackError | null;
} {
  const localProduct: IProduct = getFromLocalStorageByKey('tProd');
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [isError, setIsError] = useState<boolean>(false);
  const [errorMessage] = useState<string>('');
  const [isCompletedSilentLogin, setIsCompletedSilentLogin] = useState(false);
  const {
    isLoading: isCountriesLoading,
    refetch: refetchCountry,
    isSuccess: isSuccessGetCountry,
    isError: isErrorGetCountry,
  } = useGetCountry({ enabled: false });
  const shoppingCart = getFromLocalStorageByKey('shoppingCart');
  const shoppingCartMutation = useShoppingCartMutation();
  const product = p || localProduct;

  /**
   * Triggering shoppingCart Mutation if the product matches the serviceability
   */
  useEffect(() => {
    if (
      isCompletedSilentLogin &&
      !isDisabledCreateCart &&
      !shoppingCartMutation.isLoading &&
      !shoppingCartMutation.isSuccess &&
      isSuccessGetCountry &&
      product?.offerId &&
      product?.planId?.toString()
    ) {
      const payload = createPayloadMobile(
        product?.offerId,
        product?.planId.toString(),
        isUserLogged,
        customerId || '',
        product?.isRequiredPortability,
        product?.isRequiredPortability
          ? {
              dummyNumber: product?.portabilityDummyNumber,
              dummyOperator: product?.portabilityDummyOperator,
            }
          : undefined
      );
      if (payload) shoppingCartMutation.mutate(payload);
      else {
        setIsError(true);
      }
    }
  }, [isCompletedSilentLogin, isSuccessGetCountry]);

  /**
   * Checking if the shoppingCart or deleteShoppingCart mutations are getting an error
   */
  useEffect(() => {
    if (shoppingCartMutation.isError || isErrorGetCountry) {
      setIsError(true);
      setIsLoading(false);
    }
  }, [shoppingCartMutation.isError, isErrorGetCountry]);

  useEffect(() => {
    if (isDisabledCreateCart) {
      if (!isCountriesLoading) {
        setIsLoading(false);
      }
    } else if (shoppingCartMutation.isSuccess && !!shoppingCart && !isCountriesLoading) {
      setIsLoading(false);
    }
  }, [!!shoppingCart, shoppingCartMutation.isSuccess, isCountriesLoading]);

  useEffect(() => {
    const listener = () => {
      setIsCompletedSilentLogin(true);
    };
    if (!checkBypassApiCallFromSIA()) {
      const countries: ICountryObject[] = getFromLocalStorageByKey('getCountry');
      if (!countries) refetchCountry();
    }
    if (typeof document !== 'undefined') {
      document.addEventListener(ALL_EVENTS.SILENT_LOGIN_COMPLETE, listener);
    }
    return () => {
      document.removeEventListener(ALL_EVENTS.SILENT_LOGIN_COMPLETE, listener);
    };
  }, []);

  return {
    isCompletedSilentLogin,
    isLoading,
    isError,
    errorMessage,
    errorCart: shoppingCart?.error
      ? errorMock(`shoppingCart`, shoppingCart?.error, '', '', '', true)
      : null,
  };
}
