import { LoggerInstance } from '../logger';
import { ENV, FallBackEnv } from './model';

const fallBackTemp: FallBackEnv = {
  NODE_ENV: '',
  NX_GOOGLE_MAPS_API_KEY: '',
  NEXT_PUBLIC_LOGGER_LEVEL: '',
  PROD: '',
  UAT1: '',
  UAT2: '',
  UAT6: '',
  UAT7: 'ALL',
  UAT8: '',
  UAT9: '',
  AEM_BUSINESS_ENVIRO: '',
  AMPLIFY_DIFF_DEPLOY: '',
  AMPLIFY_MONOREPO_APP_ROOT: '',
  NEXT_PUBLIC_CMS: '',
  NEXT_PUBLIC_FOLDER_MEDIA: '',
  NEXT_PUBLIC_NEXT_ENVIRO: '',
  NEXT_PUBLIC_DXL_PREFIX: '',
  NEXT_PUBLIC_TEST_QA: 'false',
  IS_DEV: 'false',
  NX_YEXT_API_KEY: '',
  NEXT_PUBLIC_YEXT_BUSINESS_ID: '',
  NEXT_PUBLIC_YEXT_EXP_KEY: '',
  NEXT_PUBLIC_YEXT_EXP_VER: '',
  NEXT_PUBLIC_YEXT_REDIRECT_URL: '',
  NX_APP_DYNAMICS_API_KEY: '',
  NEXT_PUBLIC_WEBAPI_SERVERLESS: '',
  NEXT_PUBLIC_APP_TYPE: '',
  NEXT_PUBLIC_UAT_TESTING: '',
  NEXT_PUBLIC_DXL_APP_PREFIX: '',
  NEXT_PUBLIC_DXL_BS_APP_PREFIX: '',
  NEXT_PUBLIC_DXL_CONSUMER_CLOUD: '',
  NEXT_PUBLIC_UTAG_BASE_URL: '',
  NEXT_PUBLIC_DXL_VBI_PREFIX: '',
  NEXT_PUBLIC_RESHAPING_RELEASE_URL: '',
  NEXT_PUBLIC_AWSAPI_CONSUMER_PREFIX: '',
  NEXT_PUBLIC_CMS_CONTRACT_DOWNLOAD: '',
  NEXT_PUBLIC_VR_URL: '',
  NEXT_PUBLIC_ENV: '',
  NEXT_PUBLIC_SHOW_APPOINTMENT: '',
  NEXT_PUBLIC_GS_APP_URL: '',
  NEXT_PUBLIC_DXL_VBI_CLOUD_PREFIX: '',
  NX_MOCK_API_KEY: '',
  NEXT_PUBLIC_MOCK_API_KEY_ENABLED: '',
  NEXT_PUBLIC_DXL_GATEWAY_CLOUD: '',
  NEXT_PUBLIC_MOCK_APP: '',
  NEXT_PUBLIC_MOCK_WINBACK: '',
  NEXT_PUBLIC_CMS_APP: '',
  NEXT_PUBLIC_CMS_APP_CHANNEL: 'web',
  NEXT_PUBLIC_SHOW_SPEED_UP: 'false',
  NEXT_PUBLIC_TECH_APPOINTMENT_REFETCH_CREATE_SLOT: 'false',
  NEXT_PUBLIC_NEW_ABROAD: 'FALSE',
  NEXT_PUBLIC_MEDIA_QUERY_DEFAULT_STATE: '',
  NEXT_PUBLIC_CMS_ENV:'',
  NEXT_PUBLIC_FWVAPI: '',
  NEXT_PUBLIC_FW_POST_FORM: ''
};

export const handleEnv = (env: ENV) => {
  LoggerInstance.info(`PROCESS.ENV.${env} IS: ${process.env?.[env]}`);
  if (process.env?.[env]) return process.env[env];
  LoggerInstance.info(`PROCESS.ENV.${env} IS: ${fallBackTemp[env] || false}`);
  return fallBackTemp[env] || false;
};

export default handleEnv;
