import {
  checkIsGoBackSIA,
  CMS_CONFIG,
  ErrorCodes,
  getHubPath,
  goToHubProduct,
  IGenericErrorCMS,
  IProduct,
  isPersistData,
  resetKeyListAfterSubmit,
  resetKeyListOnRechargeMobile,
  TPO_TKN,
  updateProduct,
  useCmsConfig,
} from '@vfit/consumer/data-access';
import { useQueryClient } from 'react-query';
import { useEffect, useState } from 'react';
import {
  resetData,
  errorManager,
  ErrorService,
  retrieveBasePageName,
  tracking,
} from '@vfit/shared/data-access';
import { API, getFromLocalStorageByKey, getHome, PAGES } from '@vfit/shared/data-access';
import { ECodeIdentification, IDeliveryOptions } from '@vfit/shared/models';
import CheckoutModal from '../Checkout/checkout.modal';
import { updateProductPrice, updateProductShoppingCartUrl } from './shoppingCart.utils';
import { useCheckout } from '../iBuyMobile.context';
import { ENABLE_DEBUG } from '../iBuyMobile.utils';
import useShoppingCart from './shoppingCart.hook';
import { getTagging, getTrackError } from '../Checkout/checkout.utils';
import EnableDebug from '../Debug/enableDebug/enableDebug';

const ShoppingCart = () => {
  const { product, customerDeliveryBaseInfo, setProduct } = useCheckout();
  const queryString = window.location.search;
  const hub = product ? getHubPath(product) : '';
  const queryClient = useQueryClient();
  const [isLoadingCart, setIsLoadingCart] = useState<boolean>(true);
  const [isErrorOnPayment, setIsErrorOnPayment] = useState(false);
  const [isErrorOnPaymentShowErrorModal, setIsErrorOnPaymentShowErrorModal] = useState(false);
  const [isOnResetUser, setIsOnResetUser] = useState(false);
  const alreadySubmitterOrder = getFromLocalStorageByKey('submitOrder');
  const isNotFirstFlow = getFromLocalStorageByKey('shoppingCart');
  const recognitionMethod = localStorage.getItem('recognition') || '';
  const isEsim = localStorage.getItem('isEsim') === '1';
  const deliveryOptions: IDeliveryOptions[] =
    customerDeliveryBaseInfo?.deliveryBase?.deliveryOptions || [];
  const haveDI = deliveryOptions?.find((el) => el.code === ECodeIdentification.DI && el.isSelected);
  const isEsimFullyDigital = isEsim && recognitionMethod === ECodeIdentification.VR && haveDI;
  const { errorCart, isLoadingShoppingCartInfo, isErrorShoppingCart, errorMessageShoppingCart } =
    useShoppingCart();

  const genericError = useCmsConfig(
    CMS_CONFIG[PAGES.CONSUMER],
    API.CMS_GET_GENERIC_ERROR
  ) as IGenericErrorCMS;

  const checkErrorOnPayment = () => {
    if (
      queryString.includes(ErrorCodes.ERROR_ON_PAYMENT) ||
      isPersistData() ||
      queryString.includes(ErrorCodes.MODE_RESET_USER)
    ) {
      const isErrPayment = queryString.includes(ErrorCodes.ERROR_ON_PAYMENT);
      const isRstUser = queryString.includes(ErrorCodes.MODE_RESET_USER);
      setIsErrorOnPayment(isErrPayment);
      setIsErrorOnPaymentShowErrorModal(isErrPayment);
      setIsOnResetUser(isRstUser);
      if (isRstUser) {
        resetData(
          queryClient,
          resetKeyListOnRechargeMobile.filter((key) => key !== 'ctxk')
        );
      }
      setIsLoadingCart(false);
    } else if (alreadySubmitterOrder) {
      resetData(queryClient, [...resetKeyListAfterSubmit, ...resetKeyListOnRechargeMobile]);
    } else if (isNotFirstFlow) {
      resetData(queryClient, resetKeyListOnRechargeMobile);
    }
  };

  const trackErrorCart = () => {
    const errorObj = errorCart ? getTrackError(errorCart, 'shopping cart') : {};
    const taggingProduct = getTagging('');
    const pageName = `${retrieveBasePageName(
      taggingProduct?.trackingProduct,
      taggingProduct?.trackingProduct?.cart_product_name
    )}`;
    tracking(
      {
        ...errorObj,
        event_name: ['page_error'],
        event_category: 'error',
        page_name: `${pageName}:page_error`,
        page_type: 'error_page',
        product_name: taggingProduct?.pageProductsInfo.product_name,
      },
      'view'
    );
  };

  useEffect(() => {
    if (isErrorShoppingCart) {
      const closeModalAction = () => {
        resetData(queryClient, ['shoppingCart']);
        const hubPath = product?.hubPath || '';
        window.location.href = `${getHome()}/${hubPath}`;
      };
      trackErrorCart();
      errorManager.handleError(ErrorService.getSeverityErrorHigh(), {
        title: genericError?.genericerror?.title || '',
        message: errorMessageShoppingCart,
        disableTrack: true,
        actionText: genericError?.genericerror?.buttonLabel || '',
        onClose: closeModalAction,
        actionEvent: closeModalAction,
        ...(errorCart && { trackError: getTrackError(errorCart, 'shopping cart') }),
        opts: {
          product_name: product?.slug || '',
        },
      });
    }
  }, [isErrorShoppingCart]);

  useEffect(() => {
    if (product?.isLoaded && !isLoadingShoppingCartInfo) {
      setIsLoadingCart(false);
    }
  }, [product]);

  useEffect(() => {
    if (!isLoadingShoppingCartInfo) {
      const prod = product as IProduct;
      setProduct({
        ...prod,
        ...(prod.isWinback ? {} : updateProductPrice(prod)),
        shoppingCartUrl: window.location.href,
        isLoaded: true,
      });
      setTimeout(() => {
        updateProductShoppingCartUrl(product);
      }, 1000);
    }
  }, [isLoadingShoppingCartInfo]);

  useEffect(() => {
    const prod = product as IProduct;
    setProduct({
      ...prod,
      ...updateProductPrice(prod),
    });
  }, [isEsimFullyDigital]);

  useEffect(() => {
    checkErrorOnPayment();
    if (product) {
      updateProduct({
        ...product,
        ...(hub && { hubPath: hub }),
      });
    }
  }, []);

  if (!product || Object.keys(product)?.length === 0) return null;

  if (product?.isTpoOffer && !product?.forceCampaign && !localStorage.getItem(TPO_TKN)) {
    errorManager.handleError(ErrorService.getSeverityErrorHigh(), {
      title: 'Ops',
      onClose: () => {
        window.location.href = '/';
      },
      message: 'Si è verificato un problema. Riprovare in seguito',
      disableCloseModalOnClickAction: true,
    });
    return null;
  }

  return (
    <>
      {ENABLE_DEBUG && <EnableDebug />}
      <CheckoutModal
        isOpen
        handleClose={goToHubProduct}
        backgroundImage={product?.image || ''}
        isLoadingCart={isLoadingCart}
        isErrorOnPayment={isErrorOnPayment}
        isErrorOnPaymentShowErrorModal={isErrorOnPaymentShowErrorModal}
        isOnResetUser={isOnResetUser}
        hideCards={checkIsGoBackSIA()}
      />
    </>
  );
};

export default ShoppingCart;
