import React, { useEffect } from 'react';
import { useQueryClient } from 'react-query';
import { IPageProps, IWidgetType, usePage, retrieveAppSession, useTranslate } from '@vfit/consumer/data-access';
import { Footer, Header } from '@vfit/shared/components';
import { SoftManagerService } from '@vfit/consumer/providers';
import { useTrackingProvider } from '@vfit/shared/data-access';
import { AppMobile, useFooter, useHeader } from '@vfit/shared/data-access';
import { StickyBannerHeader } from '@vfit/consumer/components';
import Widgets from '../../Widgets/widgets';
import { withProtected } from '../Hoc/withProtected';
import TaggingBlank from './blank.tagging';
import BrightSky from '../../BrightSky/brightSky';

const Blank = ({ isApp }: IPageProps) => {
  const { page, pageNavigation, products } = usePage();
  const client = useQueryClient();
  const { translate } = useTranslate();
  const { elements, haveHeader, isHeaderSmall, haveFooter, isDisabledInitialTrack, isNewHeader } =
    page?.params || {};
  const { isReady: isReadyTagging } = useTrackingProvider();
  const { widgets, taggingOptions, stickybanner, topheaderbanner } = elements || {};
  const { header, tagging } = useHeader(pageNavigation);
  const { footer } = useFooter(pageNavigation);
  const firstWidgetType = page?.params?.elements?.widgets?.sliders?.[0]?.type;
  const haveHub = firstWidgetType === IWidgetType.HUB || firstWidgetType === IWidgetType.HUB_CUSTOM;

  const pageProduct = SoftManagerService(client).getSoftProductsByProducts(
    products?.filter((el) => el.isShowedInHub) || []
  );

  client.setQueryData('pageDatalayer', {
    event: [taggingOptions?.event || 'view'],
    event_label: taggingOptions?.eventLabel || '',
    opts: taggingOptions,
    pageProduct,
    cartProduct: undefined,
    visitorTrackingOpts: SoftManagerService(client).getUser(),
  });

  useEffect(() => {
    if (AppMobile.checkIsApp()) {
      retrieveAppSession();
    }
  }, []);

  return (
    <div>
      {isReadyTagging && (
        <TaggingBlank
          productsAll={products}
          taggingOptions={taggingOptions}
          isDisabledInitialTrack={isDisabledInitialTrack}
        />
      )}
      {haveHeader && header && !isApp && (
        <Header
          onTrack={tagging}
          header={header}
          lite={isHeaderSmall}
          disablePaddingTop={isHeaderSmall && !haveHub}
          onTranslate={translate}
          isExtendedHeader={isNewHeader}
          topHeaderBanner={topheaderbanner}
        />
      )}
      {stickybanner && <StickyBannerHeader bannerCustomConfig={stickybanner} />}
      {widgets?.sliders && <Widgets widgets={widgets?.sliders} tagging={tagging} />}
      {haveFooter && footer && !isApp && (
        <Footer onTrack={`${tagging}_FOOTER`} footer={footer} subChildren={<BrightSky />} />
      )}
    </div>
  );
};

const BlankProtected = withProtected()(Blank);

export default BlankProtected;
