
interface AddonOfferingConfiguration {
  offering: {
    id: string;
    name: string;
  }
}

interface BundledAddonConfiguration {
  addonOfferingConfiguration: AddonOfferingConfiguration[]
  offeringId: string;
}

interface BundledAddOnGroupConfiguration {
  bundledAddOnConfiguration: BundledAddonConfiguration[]
}

interface MobileOfferingConfiguration {
  id: string;
  offeringType: IOfferingType;
  bundledAddOnConfiguration: BundledAddonConfiguration[];
  bundledAddOnGroupConfiguration?: BundledAddOnGroupConfiguration[]
}

interface BundledDataSimOfferingConfiguration {
  mobileOfferingConfiguration: MobileOfferingConfiguration[]
}

interface InternetOfferingConfiguration {
  id: string;
  offeringType: IOfferingType;
  bundledAddOnConfiguration: BundledAddonConfiguration[];
  bundledAddOnGroupConfiguration?: BundledAddOnGroupConfiguration[];
}

interface BundledInternetOfferingConfiguration {
  internetOfferingConfiguration: InternetOfferingConfiguration[]
}

interface FixedVoiceOfferingConfiguration {
  id: string;
  offeringType: IOfferingType;
  bundledAddOnConfiguration: BundledAddonConfiguration[];
  bundledAddOnGroupConfiguration?: BundledAddOnGroupConfiguration[];
}

interface BundledFixedVoiceOfferingConfiguration {
  fixedVoiceOfferingConfiguration: FixedVoiceOfferingConfiguration[]
}

interface MultiPlayResponseForAddons {
  bundledFixedVoiceOfferingConfiguration: BundledFixedVoiceOfferingConfiguration[];
  bundledInternetOfferingConfiguration: BundledInternetOfferingConfiguration[];

  bundledDataSimOfferingConfiguration: BundledDataSimOfferingConfiguration[];
}

export enum IOfferingType  {
  FIXED_VOICE= 'fixedVoiceOffering',
  SIM_DATA= 'mobileOffering',
  INTERNET= 'internetOffering'

}

export type IGetAddonResponse = Partial<MultiPlayResponseForAddons>
