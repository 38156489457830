import { useEffect, useState } from 'react';
import { useQueryClient } from 'react-query';
import { API, getHome, openPopup, PAGES } from '@vfit/shared/data-access';
import { CMS_CONFIG, IGenericErrorCMS, useCmsConfig, usePage } from '@vfit/consumer/data-access';
import {
  ECodeIdentification,
  ICommonData,
  IDeliveryOptions,
  IIdentificationDetails,
  IOptionalText,
} from '@vfit/shared/models';
import { resetData, ErrorService, errorManager } from '@vfit/shared/data-access';
import { useCheckout } from '../../../../../iBuyMobile.context';
import { getRecognitionPrice } from '../../recognitionCard.utils';
import { DEFAULT_PICKUP_POINT } from '../../../../../iBuyMobile.context.data';
import RecognitionSimMethodSelection from '../RecognitionSimMethodSelection/recognitionSimMethodSelection';
import RecognitionSimCourier from '../RecognitionSimCourier/recognitionSimCourier';
import RecognitionSimVideoRecognition from '../RecognitionSimVideoRecognition/recognitionSimVideoRecognition';
import RecognitionEsim from '../RecognitionEsim/recognitionEsim';

const RecognitionCardMain = ({ handleOnChangeEnableGoNext }: ICommonData) => {
  const { product: pageProduct } = usePage();
  const queryClient = useQueryClient();
  const [isError, setIsError] = useState(false);
  const {
    portability,
    product,
    setPickupPoint,
    customerDeliveryBaseInfo,
    setRecognitionCode,
    simOptions,
    isModalButtonSticky,
  } = useCheckout();
  const isMMP = portability?.portabilityWant || false;
  const selectedSimOption = simOptions?.simOptions.find((simOption) => simOption.selection);
  const isEsim = selectedSimOption?.value?.toLowerCase() === 'esim' || false;
  const identificationDetails: IIdentificationDetails | IIdentificationDetails[] =
    customerDeliveryBaseInfo?.deliveryBase?.identificationDetails;
  const deliveryOptions: IDeliveryOptions[] =
    customerDeliveryBaseInfo?.deliveryBase?.deliveryOptions || [];
  const deliveryOptionShipping = deliveryOptions?.find((el) => el.code === 'S');
  const shippingOption = deliveryOptionShipping?.shippingOptions?.[0];
  const recognitionPrice = getRecognitionPrice(shippingOption?.price?.amount || 0);
  const price = recognitionPrice == '0' ? '' : (recognitionPrice as string);

  const genericError = useCmsConfig(
    CMS_CONFIG[PAGES.CONSUMER],
    API.CMS_GET_GENERIC_ERROR
  ) as IGenericErrorCMS;

  const getDefaultItem = (): IIdentificationDetails | undefined => {
    if (Array.isArray(identificationDetails)) {
      const foundedElement = identificationDetails.find((el) => el.isSelected);
      if (foundedElement) {
        return foundedElement;
      }
      return undefined;
    }
    return identificationDetails?.isSelected ? identificationDetails : undefined;
  };

  const showBlockedError = () => {
    setIsError(true);
    const closeModalAction = () => {
      const hubPath = pageProduct?.hubPath || '';
      window.location.href = `${getHome()}/${hubPath}`;
    };
    errorManager.handleError(ErrorService.getSeverityErrorHigh(), {
      title: genericError?.genericerror?.title || '',
      message: genericError?.genericerror?.description || '',
      actionText: genericError?.genericerror?.buttonLabel || '',
      onClose: closeModalAction,
      actionEvent: closeModalAction,
      secondButtonText: product?.removeCTC ? undefined : product?.genericErrorCallMeNow?.label,
      isButtonSticky: isModalButtonSticky,
      secondActionEvent: () =>
        openPopup(product?.genericErrorCallMeNow?.url || '', closeModalAction),
      opts: {
        product_name: product?.slug || '',
      },
    });
  };

  const resetDeliveryData = () => {
    setPickupPoint(DEFAULT_PICKUP_POINT);
    resetData(queryClient, [
      'deliveryDetailsIsEvolvedMobile',
      'deliveryDetailsIsUltraMobile',
      'deliveryDetailsIsAdvancedMobile',
      'postDeliveryServiceDetails',
      'deliveryAddress',
      'deliveryCreatedAddress',
      'deliveryDetailsIsEditAddress',
      'individual',
      'linkContactAndCustomer',
    ]);
  };

  const handleSelect = (item: IOptionalText | undefined) => {
    const recognitionCode = item?.value || '';
    const identificationDetailsPrev: IIdentificationDetails[] =
      identificationDetails as IIdentificationDetails[];
    const indexVR = identificationDetailsPrev.findIndex((el) => el.code === ECodeIdentification.VR);
    const indexCO = identificationDetailsPrev.findIndex((el) => el.code === ECodeIdentification.CO);
    identificationDetailsPrev[indexVR] = {
      ...identificationDetailsPrev[indexVR],
      isSelected: (item && item?.value === ECodeIdentification.VR) || false,
    };
    identificationDetailsPrev[indexCO] = {
      ...identificationDetailsPrev[indexCO],
      isSelected: (item && item?.value === ECodeIdentification.CO) || false,
    };
    resetDeliveryData();
    setRecognitionCode(recognitionCode);
    localStorage.setItem('recognition', recognitionCode);
    handleOnChangeEnableGoNext?.(true);
  };

  const renderSimEsimRecognition = () => {
    let haveCourier;
    let haveVR;
    const haveDI = deliveryOptions?.find(
      (el) => el.code === ECodeIdentification.DI && el.isSelected
    );

    if (Array.isArray(identificationDetails)) {
      haveCourier = identificationDetails.find((el) => el.code === ECodeIdentification.CO);
      haveVR = identificationDetails.find((el) => el.code === ECodeIdentification.VR);
    } else {
      haveCourier =
        (identificationDetails as IIdentificationDetails)?.code === ECodeIdentification.CO;
      haveVR = (identificationDetails as IIdentificationDetails)?.code === ECodeIdentification.VR;
    }

    if (haveVR && haveCourier && !haveDI) {
      return (
        <RecognitionSimMethodSelection
          isLoading={false}
          isMNP={isMMP}
          handleSelect={handleSelect}
          price={price}
          defaultItem={getDefaultItem()}
        />
      );
    }
    if (haveCourier && !haveDI) {
      setRecognitionCode('CO');
      localStorage.setItem('recognition', ECodeIdentification.CO);
      return (
        <RecognitionSimCourier isMNP={isMMP} price={price} isAutoWallet={product?.isAutoWallet} />
      );
    }
    if (haveVR || (!haveVR && haveDI)) {
      setRecognitionCode('VR');
      localStorage.setItem('recognition', ECodeIdentification.VR);
      if (isEsim) return <RecognitionEsim isMNP={isMMP} />;
      return <RecognitionSimVideoRecognition isMNP={isMMP} />;
    }
    return null;
  };

  const checkIdentificationDetails = () => {
    if (Array.isArray(identificationDetails)) {
      if (
        identificationDetails.length === 0 ||
        (identificationDetails.length === 1 &&
          identificationDetails?.[0]?.code === ECodeIdentification.NA)
      ) {
        return showBlockedError();
      }
    } else if (identificationDetails?.code === ECodeIdentification.NA) {
      return showBlockedError();
    }
    if (Array.isArray(identificationDetails)) {
      const haveNA =
        identificationDetails.length === 0 &&
        identificationDetails?.[0].code === ECodeIdentification.NA;
      const haveCourier = identificationDetails.find((el) => el.code === ECodeIdentification.CO);
      const haveVR = identificationDetails.find((el) => el.code === ECodeIdentification.VR);
      if (haveNA) {
        handleOnChangeEnableGoNext?.(false);
      } else if ((haveCourier && !haveVR) || (!haveCourier && haveVR)) {
        localStorage.setItem(
          'recognition',
          haveCourier && !haveVR ? ECodeIdentification.CO : ECodeIdentification.VR
        );
        handleOnChangeEnableGoNext?.(true);
      } else handleOnChangeEnableGoNext?.(false);
    }
    return null;
  };

  useEffect(() => {
    checkIdentificationDetails();
  }, [identificationDetails]);

  if (isError) {
    return null;
  }

  return <div>{renderSimEsimRecognition()}</div>;
};

export default RecognitionCardMain;
