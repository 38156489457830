import { useQueryClient } from 'react-query';
import { IPageProps, usePage, useTranslate } from '@vfit/consumer/data-access';
import { SoftManagerService } from '@vfit/consumer/providers';
import { Footer, Header } from '@vfit/shared/components';
import { useTrackingProvider } from '@vfit/shared/data-access';
import { useFooter, useHeader } from '@vfit/shared/data-access';
import { withProtected } from '../Hoc/withProtected';
import Widgets from '../../Widgets/widgets';
import TaggingHubDevice from './hubDevice.tagging';
import BrightSky from '../../BrightSky/brightSky';

const HubDevice = ({ isApp }: IPageProps) => {
  const { page, pageNavigation, devices } = usePage();
  const client = useQueryClient();
  const { elements, haveHeader, isHeaderSmall, haveFooter, isDisabledInitialTrack, isNewHeader } =
    page?.params || {};
  const { isReady: isReadyTagging } = useTrackingProvider();
  const { translate } = useTranslate();
  const { widgets } = elements || {};
  const { header, tagging } = useHeader(pageNavigation);
  const { footer } = useFooter(pageNavigation);
  const allVisibleDevices = devices?.filter((device) => device?.devicedetail?.isSellable) || [];

  const pageDevices = SoftManagerService(client).getSoftDevicesByDevices(allVisibleDevices);

  client.setQueryData('pageDatalayer', {
    event: ['product_view'],
    opts: elements?.taggingOptions,
    pageDevices,
    visitorTrackingOpts: SoftManagerService(client).getUser(),
  });

  return (
    <div>
      {isReadyTagging && (
        <TaggingHubDevice
          allDevices={devices}
          taggingOptions={elements?.taggingOptions}
          isDisabledInitialTrack={isDisabledInitialTrack}
        />
      )}
      {haveHeader && header && !isApp && (
        <Header
          onTrack={tagging}
          header={header}
          lite={isHeaderSmall}
          onTranslate={translate}
          isExtendedHeader={isNewHeader}
          topHeaderBanner={elements?.topheaderbanner}
        />
      )}
      {widgets?.sliders && (
        <div className="noPadding">
          <Widgets widgets={widgets?.sliders} allDevices={allVisibleDevices} tagging={tagging} />
        </div>
      )}
      {haveFooter && footer && !isApp && (
        <Footer onTrack={`${tagging}_FOOTER`} footer={footer} subChildren={<BrightSky />} />
      )}
    </div>
  );
};

const HubDeviceProtected = withProtected()(HubDevice);

export default HubDeviceProtected;
