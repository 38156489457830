/**
 * Method to merge 2 json in 1 json
 * @param json1
 * @param json2
 */
export const mergeJSONDiff = (json1: any, json2: any) => {
  // Function for override values
  function assignValue(obj: any, keys: any, value: any) {
    let currentObj = obj;
    // eslint-disable-next-line no-plusplus
    for (let i = 0; i < keys.length - 1; i++) {
      const key = keys[i];
      currentObj = currentObj[key];
    }
    if(keys[keys.length - 1] && currentObj[keys[keys.length - 1]])
      currentObj[keys[keys.length - 1]] = value;
  }

  // Function to iterate over json2 and overwrite the corresponding values in json1
  function override(obj1: any, obj2: any) {
    // eslint-disable-next-line no-restricted-syntax
    for (const key in obj2) {
      if (typeof obj2[key] === 'object') {
        override(obj1, obj2[key]);
      } else {
        const keys = key.split('.');
        assignValue(obj1, keys, obj2[key]);
      }
    }
  }

  override(json1, json2);
};

/**
 * Method to get the diff between json1 and json2
 * @param json1
 * @param json2
 */
export const getJSONDiff = (json1: any, json2: any) => {
  const diff: any = {};

  const compareObjects = (obj1: any, obj2: any, comparePath: any) => {
    // eslint-disable-next-line guard-for-in,no-restricted-syntax
    for (const key in obj1) {
      const currentPath = (comparePath ? `${comparePath}.` : '') + key;
      if (typeof obj1[key] === 'object' && typeof obj2[key] === 'object') {
        compareObjects(obj1[key], obj2[key], currentPath);
        // eslint-disable-next-line no-prototype-builtins
      } else if (!obj2?.hasOwnProperty(key) || obj1[key] !== obj2[key]) {
        diff[currentPath] = obj1[key];
      }
    }
  };

  compareObjects(json1, json2, '');
  return diff;
};
