import { IAddonDetailsCMS, IDevice, IProduct, ISku } from '@vfit/consumer/data-access';
import { QueryClient } from 'react-query';
import { checkWindow } from '@vfit/shared/data-access';
import {
  getUserInfo,
  getProductLabelPromo,
  getPageProductsInfo,
  getProductDeviceInfo,
  getOrganizedSoftProductsByProducts,
  getOrganizedSoftDevicesByDevices,
  createTechAppointmentMockCookie,
  encryptData, getCartProductsInfoWithAddon, retrieveDetailsCartAddons,
} from './utils';
import { ISoftEvents } from './interfaces';

export const SoftManagerService = (queryClient: QueryClient) => {
  const getSoftProductsByProducts = (products: IProduct[]) =>
    getOrganizedSoftProductsByProducts(queryClient, products);

  const getSoftDevicesByDevices = (devices: IDevice[]) =>
    getOrganizedSoftDevicesByDevices(queryClient, devices);

  const getPromoLabel = (slug: string): string => getProductLabelPromo(slug);

  const getUser = () => getUserInfo(queryClient);

  const getEncryptedData = (value: string) => encryptData(value);

  const getPageProduct = (product: IProduct) => getPageProductsInfo(product);
  const retrieveCartAddons = (addons : string[]) => retrieveDetailsCartAddons(addons, queryClient);

  const getCartProductsWithAddon = (product?: IProduct, addonList?: IAddonDetailsCMS[]) => getCartProductsInfoWithAddon(product, addonList);

  const getProductDevice = (device: IDevice, selectedModel?: ISku) =>
    getProductDeviceInfo(device, selectedModel);

  const addCustomOffer = (product: IProduct) => {
    const customAddEvent = new CustomEvent(ISoftEvents.ADD_CUSTOM_OFFER, { detail: product });
    document.dispatchEvent(customAddEvent);
  };

  const setIsAbTest = (value: boolean) => {
    const customSetAbTestEvent = new CustomEvent(ISoftEvents.SET_AB_TEST, { detail: value });
    document.dispatchEvent(customSetAbTestEvent);
  };

  const setDisableRedirect = (value: string) => {
    if (!checkWindow()) return;
    if (value.toString() === '0') localStorage.removeItem('disableRed');
    else localStorage.setItem('disableRed', value.toString());
  };

  const setMockTechAppointment = (redirectUrl?: string): void =>
    createTechAppointmentMockCookie(redirectUrl);

  return {
    getSoftProductsByProducts,
    getSoftDevicesByDevices,
    addCustomOffer,
    getPageProduct,
    retrieveCartAddons,
    getCartProductsWithAddon,
    getProductDevice,
    getUser,
    getEncryptedData,
    getPromoLabel,
    setIsAbTest,
    setDisableRedirect,
    setMockTechAppointment,
  };
};
