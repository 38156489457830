import React from 'react';
import { useQueryClient } from 'react-query';
import { colors } from '@vfit/shared/themes';
import { IPageProps, IWidgetType } from '@vfit/consumer/data-access';
import { SoftManagerService } from '@vfit/consumer/providers';
import { useTrackingProvider } from '@vfit/shared/data-access';
import { VfModal } from '@vfit/shared/components';
import Widgets from '../../Widgets/widgets';
import TaggingHubApp from './hubApp.tagging';
import { NewLoader } from './hubApp.style';
import { Loader } from './components/loader/loader';
import { withProtectedWithoutCookie } from '../Hoc/withProtectedWithoutCookie';
import ActivationLandingModal from '../../ActivationLandingModal/activationLandingModal';
import { useHubApp } from './hubApp.hook';
import { IActivationLandingModalCMSData } from '../../ActivationLandingModal/activationLandingModal.models';

const HubApp = (data: IPageProps) => {
  const { page } = data || {};
  const client = useQueryClient();
  const { isDisabledInitialTrack } = page?.params || {};
  const { isReady: isReadyTagging } = useTrackingProvider();
  const { offer, isLoading, sliders, showActivationModal, handleCloseModal } = useHubApp(data);
  const { elements } = page?.params || {};
  const { taggingOptions } = elements || {};
  const aemData = client.getQueryData('getHubAppActivation') as {
    activationlandingpro: IActivationLandingModalCMSData;
  };

  client.setQueryData('pageDatalayer', {
    event: [taggingOptions?.event || 'view'],
    event_label: taggingOptions?.eventLabel || '',
    opts: taggingOptions,
    visitorTrackingOpts: SoftManagerService(client).getUser(),
  });

  if (isLoading)
    return (
      <NewLoader>
        <Loader color={colors.$e60000} />
      </NewLoader>
    );

  return (
    <div>
      {isReadyTagging && !isLoading && (
        <TaggingHubApp
          taggingOptions={taggingOptions}
          isDisabledInitialTrack={isDisabledInitialTrack}
          catalogElements={
            sliders?.find((e) => e.type === IWidgetType.ICON_TITLE_DESCRIPTION)?.elements || []
          }
        />
      )}
      {sliders && sliders?.length > 0 && <Widgets widgets={sliders} page={page} />}
      <VfModal isOpen={showActivationModal} handleClose={handleCloseModal} isModalFullContainer>
        <ActivationLandingModal
          activationLandingAEMData={aemData?.activationlandingpro || {}}
          offer={{
            id: offer?.id || '',
            price: offer?.price || '',
            title: offer?.title || '',
          }}
          activationData={offer?.section}
          onClose={handleCloseModal}
        />
      </VfModal>
    </div>
  );
};

const HubAppProtected = withProtectedWithoutCookie()(HubApp);

export default HubAppProtected;
