import { useContext, useEffect } from 'react';
import { ErrorAction, ErrorSeverity, HubPostCoverageContext, errorManager } from '@vfit/business-data-access';
import { PAGES } from '@vfit/shared/data-access';
import { useRouter } from 'next/router';
import { getProductVersion } from '@vfit/business-data-access';
import { IProductCardProps } from '../WidgetSliderProductsHub/components/ProductCard/productCard.models';
import { useCoverageToolFlow } from '../CoverageToolModal/coverageTool.hooks';
import { useCardFunctionality } from '../WidgetSliderProductsHub/components/ProductCard/productCard.hook';

const ProductCardWrapper = (ProductCard: React.FC<IProductCardProps>) => (props: IProductCardProps) => {
  const { isHubPostCoverage } = useContext(HubPostCoverageContext);
  const {
    handleClick,
    postCoverageData,
    setPostCoverageData
  } = useCardFunctionality();
  const { isSuccess, errorType } = useCoverageToolFlow(postCoverageData);
  const { push } = useRouter();

  useEffect(() => {
    if (isSuccess) {
      setPostCoverageData({
        onConfirmAddress: false,
        product: [],
        address: {}
      });
      push(PAGES.BUSINESS_SHOPPING_CART);
    }
  }, [isSuccess]);

  useEffect(() => {
    if (errorType) {
      errorManager.handleError(ErrorSeverity.HIGH, {errorAction: ErrorAction.PAGE, errorPage: PAGES.GENERIC_ERROR})
    }
  }, [errorType]);


  if (isHubPostCoverage) {
    const { product } = props;
    const productVersion = getProductVersion(
      product?.offerId,
      product?.version,
      product?.landingprive?.isPrive,
      product.retarget
    );
    return (<ProductCard {...{...props, productVersion, handleClickPostCoverage: handleClick}} />);
  }

  return (
    <ProductCard {...props} />
  );
};
export default ProductCardWrapper;
