import { useQueryClient } from 'react-query';
import {
  checkBypassApiCallFromSIA,
  useCreateAddress,
  useLinkContactAndCustomer,
  useIndividual,
  getAddressValidatedInDeliveryByCoords,
  usePostDeliveryV1,
  useDeliveryEvolvedV1,
  useValidateAddress,
  errorMock,
} from '@vfit/consumer/data-access';
import { useEffect, useState } from 'react';
import {
  IAddressDataObject,
  ILocateStoreResponse,
  IPickUpLocation,
  IValidateAddressRequest,
} from '@vfit/shared/models';
import { INextError, resetData } from '@vfit/shared/data-access';
import { getFromLocalStorageByKey } from '@vfit/shared/data-access';
import { IUseDeliveryPost, IUseDeliveryPostResponse } from './useDeliveryPostFlow.models';
import { useCheckout } from '../../../iBuyMobile.context';

const DEFAULT_DELIVERY_POST: IUseDeliveryPostResponse = {
  individual: {
    isLoading: false,
    isSuccess: false,
    isError: false,
  },
  linkContact: {
    isLoading: false,
    isSuccess: false,
    isError: false,
  },
  validateAddress: {
    isLoading: false,
    isSuccess: false,
    isError: false,
  },
  createAddress: {
    isLoading: false,
    isSuccess: false,
    isError: false,
  },
  deliveryEvolved: {
    isLoading: false,
    isSuccess: false,
    isError: false,
  },
  postDelivery: {
    isLoading: false,
    isSuccess: false,
    isError: false,
  },
};

export const useDeliveryPostFlow = ({
  pickupPoint,
  shippingAddress,
}: IUseDeliveryPost): IUseDeliveryPostResponse => {
  const queryClient = useQueryClient();
  const { simOptions } = useCheckout();
  const [postReturn, setPostReturn] = useState<IUseDeliveryPostResponse>(DEFAULT_DELIVERY_POST);
  const [deliveryPayload, setDeliveryPayload] = useState<{
    shippingAddress: IAddressDataObject | undefined;
    pickupPoint: IPickUpLocation | ILocateStoreResponse | undefined;
  }>({
    shippingAddress: undefined,
    pickupPoint: undefined,
  });
  const deliveryBase = getFromLocalStorageByKey('deliveryDetailsIsBaseMobile');
  const selectedSimOption = simOptions?.simOptions.find((simOption) => simOption.selection);
  const isEsim = selectedSimOption?.value?.toLowerCase() === 'esim' || false;

  const {
    mutate: mutatePostDelivery,
    error: postDeliveryError,
    isError: isErrorPostDelivery,
    isSuccess: isSuccessPostDelivery,
    isLoading: isLoadingPostDelivery,
  } = usePostDeliveryV1();
  const {
    refetch: refetchDeliveryEvolved,
    error: deliveryEvolvedError,
    isError: isErrorDeliveryEvolved,
    isSuccess: isSuccessDeliveryEvolved,
    isLoading: isLoadingDeliveryEvolved,
  } = useDeliveryEvolvedV1(deliveryPayload?.shippingAddress, deliveryPayload?.pickupPoint, isEsim, {
    enabled: false,
  });
  const {
    isSuccess: isSuccessIndividual,
    error: individualError,
    isError: isErrorIndivdual,
    isLoading: isLoadingIndividual,
    refetch: refetchIndividual,
  } = useIndividual({
    enabled: false,
  });
  const {
    mutate: validateAddressMutation,
    error: validateAddressError,
    data: validatedShippingAddress,
    isLoading: isLoadingValidateAddress,
    isSuccess: isSuccessValidateAddress,
    isError: isErrorValidateAddress,
  } = useValidateAddress();
  const {
    mutate: createAddressMutation,
    error: createAddressError,
    isSuccess: isSuccessCreateAddress,
    isError: isErrorCreateAddress,
    isLoading: isLoadingCreateAddress,
    data: createdAddressData,
  } = useCreateAddress();
  const {
    mutate: linkContactMutate,
    error: linkContactError,
    isSuccess: isSuccessLinkContact,
    isError: isErrorLinkContact,
    isLoading: isLoadingLinkContact,
  } = useLinkContactAndCustomer();

  const callLinkContact = () => {
    const addressId = createdAddressData?.id;
    linkContactMutate(addressId);
  };

  const checkIndividual = () => {
    const individual = getFromLocalStorageByKey('individual');
    return !!individual;
  };

  useEffect(() => {
    if (isLoadingPostDelivery) {
      setPostReturn((prevState) => ({
        ...prevState,
        postDelivery: {
          isLoading: true,
          isSuccess: false,
          isError: false,
          error: undefined,
        },
      }));
    } else if (isErrorPostDelivery) {
      setPostReturn((prevState) => ({
        ...prevState,
        postDelivery: {
          isLoading: false,
          isSuccess: false,
          isError: true,
          error: errorMock('postDelivery', postDeliveryError),
        },
      }));
    } else if (isSuccessPostDelivery) {
      setPostReturn((prevState) => ({
        ...prevState,
        postDelivery: {
          isLoading: false,
          isSuccess: true,
          isError: false,
          error: undefined,
        },
      }));
      resetData(queryClient, ['deliveryDetailsIsEvolvedMobile']);
    }
  }, [isErrorPostDelivery, isLoadingPostDelivery, isSuccessPostDelivery]);

  useEffect(() => {
    if (isLoadingDeliveryEvolved) {
      setPostReturn((prevState) => ({
        ...prevState,
        deliveryEvolved: {
          isLoading: true,
          isSuccess: false,
          isError: false,
          error: undefined,
        },
      }));
    } else if (isErrorDeliveryEvolved) {
      setPostReturn((prevState) => ({
        ...prevState,
        deliveryEvolved: {
          isLoading: false,
          isSuccess: false,
          isError: true,
          error: errorMock('deliveryEvolved', deliveryEvolvedError as INextError),
        },
      }));
    } else if (isSuccessDeliveryEvolved) {
      setPostReturn((prevState) => ({
        ...prevState,
        deliveryEvolved: {
          isLoading: false,
          isSuccess: true,
          isError: false,
          error: undefined,
        },
      }));
      mutatePostDelivery({
        isEsim,
        shippingAddress: deliveryPayload?.shippingAddress,
        pickupPoint: deliveryPayload?.pickupPoint,
      });
    }
  }, [isSuccessDeliveryEvolved, isErrorDeliveryEvolved, isLoadingDeliveryEvolved]);

  useEffect(() => {
    if (deliveryPayload?.shippingAddress || deliveryPayload?.pickupPoint) {
      refetchDeliveryEvolved();
    }
  }, [deliveryPayload]);

  useEffect(() => {
    if (isLoadingLinkContact) {
      setPostReturn((prevState) => ({
        ...prevState,
        linkContact: {
          isLoading: true,
          isSuccess: false,
          isError: false,
          error: undefined,
        },
      }));
    } else if (isErrorLinkContact) {
      setPostReturn((prevState) => ({
        ...prevState,
        linkContact: {
          isLoading: false,
          isSuccess: false,
          isError: true,
          error: errorMock('linkContact', linkContactError),
        },
      }));
    } else if (isSuccessLinkContact) {
      setPostReturn((prevState) => ({
        ...prevState,
        linkContact: {
          isLoading: false,
          isSuccess: true,
          isError: false,
          error: undefined,
        },
        postDelivery: {
          isLoading: true,
          isSuccess: false,
          isError: false,
          error: undefined,
        },
      }));
      setDeliveryPayload({
        shippingAddress: createdAddressData as unknown as IAddressDataObject,
        pickupPoint,
      });
    }
  }, [isSuccessLinkContact, isErrorLinkContact, isLoadingLinkContact]);

  useEffect(() => {
    if (isLoadingIndividual) {
      setPostReturn((prevState) => ({
        ...prevState,
        individual: {
          isLoading: true,
          isSuccess: false,
          isError: false,
          error: undefined,
        },
      }));
    } else if (isErrorIndivdual) {
      setPostReturn((prevState) => ({
        ...prevState,
        individual: {
          isLoading: false,
          isSuccess: false,
          isError: true,
          error: errorMock('individual', individualError),
        },
      }));
    } else if (
      isSuccessIndividual &&
      isSuccessCreateAddress &&
      !isSuccessPostDelivery &&
      !isLoadingPostDelivery
    ) {
      setPostReturn((prevState) => ({
        ...prevState,
        linkContact: {
          isLoading: true,
          isSuccess: false,
          isError: false,
          error: undefined,
        },
        individual: {
          isLoading: false,
          isSuccess: true,
          isError: false,
          error: undefined,
        },
      }));
      callLinkContact();
    }
  }, [isSuccessIndividual, isErrorIndivdual, isLoadingIndividual]);

  useEffect(() => {
    if (isLoadingCreateAddress) {
      setPostReturn((prevState) => ({
        ...prevState,
        createAddress: {
          isLoading: true,
          isSuccess: false,
          isError: false,
          error: undefined,
        },
      }));
    } else if (isErrorCreateAddress) {
      setPostReturn((prevState) => ({
        ...prevState,
        createAddress: {
          isLoading: false,
          isSuccess: false,
          isError: true,
          error: errorMock('createAddress', createAddressError),
        },
      }));
    } else if (isSuccessCreateAddress) {
      if (checkIndividual()) {
        setPostReturn((prevState) => ({
          ...prevState,
          linkContact: {
            isLoading: true,
            isError: false,
            error: undefined,
            isSuccess: false,
          },
          individual: {
            isLoading: false,
            isSuccess: true,
            isError: false,
            error: undefined,
          },
          createAddress: {
            isLoading: false,
            isSuccess: true,
            isError: false,
            error: undefined,
          },
        }));
        callLinkContact();
      } else {
        setPostReturn((prevState) => ({
          ...prevState,
          individual: {
            isLoading: true,
            isSuccess: false,
            isError: false,
            error: undefined,
          },
          createAddress: {
            isLoading: false,
            isSuccess: true,
            isError: false,
            error: undefined,
          },
        }));
        refetchIndividual();
      }
    }
  }, [isSuccessCreateAddress, isErrorCreateAddress, isLoadingCreateAddress]);

  useEffect(() => {
    if (isLoadingValidateAddress) {
      setPostReturn((prevState) => ({
        ...prevState,
        validateAddress: {
          isLoading: true,
          isSuccess: false,
          isError: false,
          error: undefined,
        },
      }));
    } else if (isErrorValidateAddress) {
      setPostReturn((prevState) => ({
        ...prevState,
        validateAddress: {
          isLoading: false,
          isSuccess: false,
          isError: true,
          error: errorMock('validateAddress', validateAddressError),
        },
      }));
    } else if (isSuccessValidateAddress) {
      if (!validatedShippingAddress?.validAddressList?.[0]) {
        setPostReturn((prevState) => ({
          ...prevState,
          validateAddress: {
            isLoading: false,
            isSuccess: false,
            isError: true,
            error: errorMock('validateAddress'),
          },
        }));
      } else {
        setPostReturn((prevState) => ({
          ...prevState,
          validateAddress: {
            isLoading: false,
            isSuccess: true,
            isError: false,
            error: undefined,
          },
        }));
        createAddressMutation({
          address: validatedShippingAddress?.validAddressList?.[0],
          isDelivery: true,
        });
      }
    }
  }, [isSuccessValidateAddress, isErrorValidateAddress, isLoadingValidateAddress]);

  const checkCustomerShippingAddress = () => {
    const customerAddressInDeliveryByCoords = getAddressValidatedInDeliveryByCoords(
      deliveryBase,
      shippingAddress
    );
    if (pickupPoint?.storeId || customerAddressInDeliveryByCoords) {
      setDeliveryPayload({
        shippingAddress: customerAddressInDeliveryByCoords as unknown as IAddressDataObject,
        pickupPoint,
      });
    } else {
      validateAddressMutation({
        placeObject: shippingAddress as unknown as IValidateAddressRequest,
        isDelivery: true,
      });
    }
  };

  useEffect(() => {
    if (!checkBypassApiCallFromSIA()) {
      checkCustomerShippingAddress();
    }
  }, []);

  return postReturn;
};
