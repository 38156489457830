import {
  ICMSOperatorsList,
  IContractualSummary,
  IHeroSlider,
  ISlides,
  IWidgetType,
} from '@vfit/consumer/data-access';
import {
  IAccordion,
  IActionSlide,
  IDetail,
  IDivider,
  IWarningInfo,
  ECodeIdentification,
  ITrackOrder,
  IKeyword,
  IBannerCMS,
  IActionCustom,
  IActionSunrise,
} from '@vfit/shared/models';

// region CMS fetcher

export interface IApiCms {
  key: string;
  api: string;
}

export interface ICMSConfigPage {
  apis: IApiCms[];
}

export interface ICMSConfig {
  [key: string]: ICMSConfigPage;
}

export interface IAllDataCMS {
  [key: string]: any;
}

// endregion

// region ShoppingCart

export interface ISpecs {
  icon?: string;
  action?: string;
  text?: string;
  description?: string;
  title?: string;
  modalTitle?: string;
  frontTitle?: string;
  modalContent?: string;
}

export interface IErrorCms {
  error0?: string;
  error1?: string;
  error2?: string;
  error3?: string;
  message?: string;
  title?: string;
  notFoundAddress?: string;
  buttonLabel: string;
}

export interface IBestOfferChoice {
  text: string;
  editAddress: string;
  textForMap: string;
}

export interface ICardDetail {
  buttonLabel: string;
  description: string;
  image: string;
  title: string;
  specs: ISpecs[];
}

export interface INextStep {
  description: string;
  detailLinkLabel: string;
  modal: IModalCMS;
}

export interface IChecout {
  title: string;
  nextSteps: INextStep[];
}

export interface ITechnology {
  id: number;
  title: string;
  detail: IDetail;
  bestOfferChoice: IBestOfferChoice;
  card: ICardDetail;
  visibility: IVisibilityDevice;
  checkoutGa: IChecout;
  checkoutGnp: IChecout;
  droppedCartIadd?: IDroppedCartIadd;
}

export interface ITechs {
  adsl: ITechCard;
  ftth: ITechCard;
  fttc: ITechCard;
  FWAOUTDOOR: ITechCard;
  FWAOUTDOOR5G: ITechCard;
  FWAINDOOR: ITechCard;
  FTTHTIM: ITechCard;
  FWAINDOOR5G: ITechCard;
}

export interface IVisibilityDevice {
  showInFtth: string;
  showInFttc: string;
  showInAdsl: string;
  showInFwa: string;
  showInFtthTim: string;
  showInFwaIndoor: string;
  showInFwaOutdoor: string;
  showInFwaOutdoor5G: string;
  showInFwaIndoor5G: string;
}

export interface IDeviceItem {
  device: ITechnology;
}

export interface IDevices {
  [key: string]: IDeviceItem;
}

export interface ITechCard {
  technology: ITechnology;
  droppedCard: IDroppedCartCms;
}

export interface INextStepsTyp {
  title: string;
  stepsType: {
    portVr: IStepsTypeTyp;
    portCo: IStepsTypeTyp;
    newVr: IStepsTypeTyp;
    newCo: IStepsTypeTyp;
    esimFd: IStepsTypeTyp;
    portEsimFd: IStepsTypeTyp;
  };
}

export interface IStepsTypeTyp {
  steps: [
    {
      description: string;
    }
  ];
  modalTitle: string;
  modalDescription: string;
}

// endregion

// region CoverageTool

export interface ICardCoverageTool {
  description: string;
  descriptionOnError: string;
  button: string;
  title: string;
  alertError: string;
  buttonCallsYou: string;
  buttonEditAddress: string;
  subtitle: string;
  address: string;
  addressModify: string;
  showOffers: string;
  editLabel: string;
  message: string;
  actionText: string;
  action: IActionSlide;
  clickToCall: IActionSlide;
  customAction: IActionSlide;
}

export interface ISelectInputCoverageTool {
  civicNumber: string;
  address: string;
  municipality: string;
  province: string;
  cap: string;
}

export interface IConfirmationMap {
  buttonEdit: string;
  buttonSubmit: string;
}

export interface ILockIn {
  idFallbackFromApp: string;
  error: ICardCoverageTool;
  notAddress: ICardCoverageTool;
  modalErrorNoOffer: ICardCoverageTool;
  modalErrorNoCoverage: ICardCoverageTool;
  modalErrorShoppingCart: ICardCoverageTool;
}

export interface ICoverageToolCongifg {
  coveragetool: {
    addressInput: ICardCoverageTool;
    confirmationMap: IConfirmationMap;
    errorMessage: IErrorCms;
    geolocalization: ICardCoverageTool;
    koCoverageTool: ICardCoverageTool;
    koTechnicalModal: ICardCoverageTool;
    manualInput: ICardCoverageTool;
    selectInput: ISelectInputCoverageTool;
    lockIn: ILockIn;
    modalConfig: { enableButtonSticky: string };
  };
}

// endregion

// region Checkout ClientData

export interface ICardPersonalInfo {
  description: string;
  descriptionReadOnly: string;
  fcMissingLabel: string;
  title: string;
  titleReadOnly: string;
  url: string;
  subtitle: string;
  buttonLabel: string;
  errorMessage: IErrorCms;
  textAction: string;
  lableFiscalCode: string;
  labelName: string;
  bottomText: string;
  allFieldsAreRequired: string;
}

export interface ICodeResponse {
  description: string;
  alertWarn: string;
  title: string;
  otpError: string;
  alertSuccess: string;
}

export interface IClientRecognition {
  description: string;
  codeNewLabel: string;
  title: string;
  contactNotAccessLabel: string;
  contactNotAccessUrl: string;
  telephoneVerify: string;
  emailVerify: string;
  smsVerify: string;
  codeMissingLabel: string;
  codeNotAccessLabel: string;
  codeResponse: ICodeResponse;
  redirect: {
    buttonLabel: string;
    description: string;
    title: string;
    url: string;
  };
}

export type IOtpPortabilityMobile = Omit<IClientRecognition, 'redirect'>;

export interface IContactAddress {
  description: string;
  title?: string;
  subtitle?: string;
  deliveryAddress: string;
  civicNumber: string;
  checkboxLabel?: string;
}

export const DEFAULT_CONTACT_ADDRESS: IContactAddress = {
  description: '',
  deliveryAddress: '',
  civicNumber: '',
};

export interface IRedirect {
  buttonLabel: string;
  description: string;
  title: string;
  url: string;
}

export interface IClientRegistered {
  description: string;
  checkTelephone: string;
  smallInfo: string;
  checkEmail: string;
  title: string;
  editNumber: string;
  editEmail: string;
}

export interface IClientNumber {
  buttonLabel: string;
  description: string;
  addNewNumberLabel: string;
  title: string;
  errorMessage: IErrorCms;
}

export interface IClientNotLogged {
  description: string;
  missingPasswordLabel: string;
  buttonAccessLabel: string;
  buttonCallLabel: string;
  title: string;
  areYouClientLabel: string;
}

export interface ITextIcon {
  text: string;
  icon: string;
}

export interface IMobileCard {
  description: string;
  textBold: string;
  textMnp: string;
  text: string;
  title: string;
  list: IList[];
  elements: IList[];
  elementsMnp: IList[];
}

export interface IMobileSimCard {
  card: IMobileCard;
  modalInfo: IModalCMS;
  choice: IOptionsCMS[];
}

export interface IPersonalInfoCmsMobile {
  personalinfomobile: {
    simType: IMobileSimCard;
    recognitionVideo: IMobileCard;
    recognitionVideoEsim: IMobileCard;
    recognitionMethodSelection: IMobileCard;
    recognitionMethodSelectionEsim: IMobileCard;
    recognitionCourier: IMobileCard;
    recognitionCourierEsim: IMobileCard;
    recognitionCourierAutowallet: IMobileCard;
    recognitionEsim: IMobileCard;
    firstCard: ICardPersonalInfo;
    fiscalCode: ICardPersonalInfo;
    contactAddress?: IContactAddress;
    clientRecognition: IClientRecognition;
    redirect: IRedirect;
    newDocument: ICardPersonalInfo;
    contactInfoRecap: IClientRegistered;
    clientLogged: {
      editNumber: IClientNumber;
      newNumber: IClientNumber;
      editEmail: ICardPersonalInfo;
      newEmail: ICardPersonalInfo;
      personalInfo: ICardPersonalInfo;
      documentPreFilled: ICardPersonalInfo;
    };
    clientNotLogged: IClientNotLogged;
    selectInput: ISelectInput;
  };
}

export interface IPersonalInfoCMS {
  personalinfo: {
    clientNotLogged: IClientNotLogged;
    selectInput: ISelectInput;
    generalInfo: ICardPersonalInfo;
    fiscalCode: ICardPersonalInfo;
    contactAddress?: IContactAddress;
    clientRecognition: IClientRecognition;
    redirect: IRedirect;
    preFilledDocument: ICardPersonalInfo;
    newDocument: ICardPersonalInfo;
    clientRegistered: IClientRegistered;
    clientLogged: {
      editNumber: IClientNumber;
      newNumber: IClientNumber;
      editEmail: ICardPersonalInfo;
      newEmail: ICardPersonalInfo;
      personalInfo: ICardPersonalInfo;
      documentPreFilled: ICardPersonalInfo;
    };
    lockIn: {
      personalInfo: {
        surnameLabel: string;
        buttonLabel: string;
        description: string;
        title: string;
        nameLabel: string;
      };
      modalNewUser: {
        buttonModifyNumber: string;
        description: string;
        title: string;
        action: IActionSlide;
      };
    };
  };
}

// endregion

// region Checkout Portability
export interface IChoice {
  option1: string;
  option2: string;
  option3: string;
}

export interface IChoicePortability {
  option1: IOptionsCMS;
  option2: IOptionsCMS;
}

export interface IOperatorInfo {
  skipMigrationCodeCheckboxText: string;
  description: string;
  title: string;
  choice: IChoice;
  providerOne: string;
  providerTwo: string;
  smallInfo: string;
  findMigrationLabel: string;
}

export interface ICardPortability {
  description: string;
  title: string;
  bottomText?: string;
  choice: IChoice;
  choicePortability: IChoicePortability;
  operatorInfo: IOperatorInfo;
  operatorSelect: IOperatorInfo;
  providerTitles: IOperatorInfo;
  label?: string;
  disclaimer?: string;
}

export interface IPortabilityMigration extends ICardPortability {
  actionLabel: string;
  modalTitle: string;
  modalDescription: string;
  checkboxText: string;
}

export interface IModalCMS {
  noCode: string;
  buttonLabel: string;
  description: string;
  title: string;
  linkLabel: string;
  codeNotFound: string;
  message: string;
  list: IList[];
  textLink: string;
}

export interface IPortabilityCMS {
  portability: {
    general: ICardPortability;
    telephone: ICardPortability;
    lineInfo: ICardPortability;
    provider: ICardPortability;
    modal: IModalCMS;
    selectInput: ISelectInput;
    errorModal: IModalCMS;
    modalInfo: IModalCMS;
    activationFixedLine: ICardPortability;
    errorOnPortability: IErrorCms;
    operatorList: {
      elements: IOperator[];
    };
  };
}

export interface IWinBackCMS {
  winbacknumber: {
    title: string;
    description: string;
    disclaimer: string;
    placeholder: string;
    otp: {
      title: string;
      description: string;
      telephoneVerify: string;
      codeMissingLabel: string;
      codeNewLabel: string;
      codeNotAccessLabel: string;
      contactNotAccessUrl: string;
      codeResponse: {
        title: string;
        description: string;
        otpError: string;
        alertWarn: string;
        alertSuccess: string;
      };
    };
    errorPopup: {
      title: string;
      message: string;
      ctaLabel: string;
      secondButtonLabel: string;
    };
    expiredPopup: {
      enableClickToCall: string;
      buttonRedirect: string;
      title: string;
      buttonText: string;
      message: string;
      ctaText: string;
      urlClickToCall: string;
    };
  };
  portabilitymobile: IPortabilityMobileCMS;
}

export interface IWinBackCampaignConfigDetail {
  widgets?: {
    sliders: ICarouselCMS[];
  };
  campaigndetails?: {
    expirationDate: string;
  };
}

export interface IWinBackCampaignConfig {
  [campaignId: string]: IWinBackCampaignConfigDetail;
}

interface paragraph {
  title: string;
  text?: string;
  subParagraphs?: { title: string; text: string }[];
}

export interface IModalICCID {
  title: string;
  paragraphs: paragraph[];
}

export interface ISimSerial {
  title: string;
  subTitle: string;
  question1: string;
  question2: string;
  placeholder: string;
  checkboxText: string;
  label: string;
  modal: IModalICCID;
}

export interface IPortabilityMobileCMS {
  portabilitymobile: {
    general: ICardPortability;
    telephone: ICardPortability;
    lineInfo: ICardPortability;
    provider: ICardPortability;
    modal: IModalCMS;
    selectInput: ISelectInput;
    errorModal: IModalCMS;
    modalInfo: IModalCMS;
    activationFixedLine: ICardPortability;
    errorOnPortability: IErrorCms;
    migrationData: IPortabilityMigration;
    simSerial: ISimSerial;
    otp: IOtpPortabilityMobile;
    errorNumber: IErrorCms;
    operatorList: {
      elements: IOperator[];
    };
    operatorsOrder: {
      elements: IOperator[];
    };
    howToModal: ICardPortability;
  };
}

interface IOperator {
  value: string;
  displayValue: string;
}

// endregion

// region TermsAndConditions
export interface ITermsAndCondition {
  termsandconditions: TermsAndConditionsObj;
  termsandconditionsmo: Omit<TermsAndConditionsObj, 'activationDetail'>;
  consents: ConsentsObj;
  consentsmobile: ConsentsObj;
}

type ConsentsObj = {
  card: {
    description: string;
    title: string;
    terms: {
      title: string;
      acceptAll: string;
      customLinkLabel: string;
    };
  };
  modal: {
    buttonLabel: string;
    description: string;
    title: string;
    list: IList[];
  };
};

type TermsAndConditionsObj = {
  card: {
    description: string;
    conditions: string;
    title: string;
    activation: {
      description: string;
      title: string;
      detailLinkLabel: string;
      urlLink: string;
      choice: {
        option1: string;
        option2: string;
      };
    };
    modalError: {
      title: string;
      message: string;
    };
  };
  modals: IAccordion[];
  activationDetail: {
    title: string;
    descriptions: IAccordion[];
    accordion: IAccordion[];
  };
  withdrawal?: {
    title?: string;
    description?: string;
    imageUrl?: string;
    labelButtonSubmit?: string;
    labelButtonSecond?: string;
  };
};

// endregion

// region Checkout DeliveryInformation

export interface IDeliveryCard {
  description: string;
  linkLabel: string;
  title: string;
  linkLabelEdit: string;
  modalTextLabel: string;
  modalCtaLabel: string;
  billingCta: string;
  modalTitle: string;
  modalDescription: string;
  modalButtonLabel: string;
  subtitle: string;
  simShippingLabel: string;
  buttonLabel: string;
  options: IOptionsCMS[];
  bottomTitle: string;
  afterEdit: string;
  address: string;
  buttonGoNext: string;
  personalInfo: string;
}

export interface IDeliveryMessage {
  description: string;
  title: string;
  message: string;
  shippingLabelChange: string;
  shippingLabel: string;
  buttonLabel: string;
}

export interface IDeliveryInformation {
  deliveryinformation: {
    outdoor: IDeliveryMessage;
    indoor: IDeliveryMessage;
    resume: IDeliveryMessage;
    addressDelivery: IDeliveryCard;
    addressBilling: IDeliveryCard;
    addressChange: IDeliveryCard;
    commonLabel: {
      address: string;
      linkLabel: string;
      addressModify: string;
      addressModifyPP: string;
      modalCtaLabel: string;
      linkLabelEdit: string;
      descriptionNote: string;
      modalTextLabel: string;
      placeholderNotes: string;
      afternotesModifySubtitle: string;
      civicNumber: string;
      personalInfo: string;
      modalButtonLabel: string;
      installationAddress: string;
    };
    disableAddNotes: string;
    errorCards: {
      migrationCode: IDeliveryMessage;
      addressResponse: IDeliveryMessage;
      portability: IDeliveryMessage;
    };
  };
}

export interface IDeliveryInformationMobile {
  deliveryinformationm: {
    courrier: IDeliveryMessage;
    selfService: {
      sim: IDeliveryMessage;
      eSim: IDeliveryMessage;
      eSimFullyDigital: IDeliveryMessage;
    };
    commonLabel: {
      linkLabel: string;
      addressModify: string;
      addressModifyPP: string;
      modalCtaLabel: string;
      linkLabelEdit: string;
      descriptionNote: string;
      modalTextLabel: string;
      address: string;
      placeholderNotes: string;
      afternotesModifySubtitle: string;
      civicNumber: string;
      personalInfo: string;
      modalButtonLabel: string;
      afterEdit: string;
    };
    disableAddNotes: string;
    addressBilling: IDeliveryCard;
    addressBillingEsim: IDeliveryCard;
    addressChange: IDeliveryCard;
    addressShipping: IDeliveryCard;
    addressShippingEsim: IDeliveryCard;
    errorCards: {
      migrationCode: IDeliveryMessage;
      addressResponse: IDeliveryMessage;
      portability: IDeliveryMessage;
    };
  };
}

// endregion

// region Checkout Payments

export interface IPaymentsCard {
  description: string;
  privacyLabel: string;
  dataLabel: string;
  title: string;
  cvvLabel: string;
  manualInputLabel: string;
  image: string;
  buttonLabel: string;
  icon: string;
  changeLabel: string;
  notFound: IErrorCms;
}

export interface IOptionsPayments {
  description: string;
  title: string;
}

export interface IPaymentsChoiceMobile {
  bankAccount: IOptionsPayments;
  wallet: IOptionsPayments;
  creditCard: IOptionsPayments;
  debitCard: IOptionsPayments;
  prepaidCard: IOptionsPayments;
  payPal: IOptionsPayments;
}

export interface IRechargeCard {
  description: string;
  title: string;
  elements: IOptionsCMS[];
}

export interface IPayments {
  paymentsmobile: {
    description: string;
    title: string;
    choice: IPaymentsChoiceMobile;
    creditCard: IPaymentsCard;
    scanCard: IPaymentsCard;
    scanCardManual: IPaymentsCard;
    iban: IPaymentsCard;
    detailCVV: IPaymentsCard;
    paymentError: IPaymentsCard;
    orderNotCompletedError: IPaymentsCard;
    paymentSelectionError: IPaymentsCard;
    userRegistered: IPaymentsCard;
    selectInput: ISelectInput;
    errorMessage: IErrorCms;
    errorUpdatePaymentRecharge: IPaymentsCard;
    errorRetriveCart: IPaymentsCard;
    modal: IModalCMS;
    rechargeCard: IRechargeCard;
    defaultChoice: string;
    upFrontPayment: {
      descriptionCreditRemaining: string;
      title: string;
      descriptionCC: string;
      paymentMethod: IList[];
      modal: IModalCMS;
    };
    warningPriceChange: IWarningInfo;
    warningWaiting: IWarningInfo;
    warningWaitingPayment: IWarningInfo;
    paymentMethod: {
      titleCard: string;
      titleIban: string;
      expirationLabel: string;
    };
    loadingOrder: {
      title: string;
      subtitle: string;
      description: string;
    };
  };
}

export interface IFwaPurchaseCard {
  description: string;
  labelCostChoice: string;
  title: string;
  choice: IChoiceFwa;
}

export interface IChoiceFwa {
  oneTime: {
    description: string;
    title: string;
  };
  installments: {
    description: string;
    title: string;
  };
}

// endregion

// region Checkout BillingInformation
export interface IBilllignCardCMS {
  description: string;
  title: string;
  editBillingAddress: string;
  choice: {
    option1: {
      description: string;
      linkAddressLabel: string;
      icon: string;
      title: string;
    };
    option2: {
      description: string;
      title: string;
      icon: string;
      linkAddressLabel: string;
      linkEcoLabel: string;
    };
  };
}

export interface IBillingAddress {
  buttonLabel: string;
  description: string;
  title: string;
}

export interface IBillingEmail extends IBillingAddress {
  alertText: string;
  inputLabel: string;
}

export interface IBillingCard {
  billinginformation: {
    title: string;
    card: IBilllignCardCMS;
    address: IBillingAddress;
    selectInput: ISelectInput;
    errorMessage: IErrorCms;
    email: IBillingEmail;
  };
}

// endregion

// region ThankYouMobile

export interface IThankYouPageCard {
  description: string;
  secondaryImageMobile: string;
  notReceivedLabel: string;
  newMessageLink: string;
  contactLabel: string;
  buttonLabel: string;
  secondaryImage: string;
  primaryImage: string;
  primaryImageMobile: string;
  title: string;
  qrCodeImg: string;
  secondaryTitle: string;
  primaryTitle: string;
}

export interface ICardOrder {
  description: string;
  primaryImage: string;
  primaryImageMobile: string;
  secondaryImageMobile: string;
  secondaryImage: string;
  title: string;
  confirmationNumber: IThankYouPageCard;
  confirmationMail: IThankYouPageCard;
}

export interface ITechAppointmentCardSunrise {
  title: string;
  description: string;
  buttonLabel: string;
  showCard: string;
  buttonUrl: string;
}

export interface IThankYouPage {
  thankyoupage: Omit<ThankYouPageObj, 'nextSteps'>;
  thankyoupagemobile: ThankYouPageObj;
}

type ThankYouPageObj = {
  cardOrder: ICardOrder;
  cardApp: IThankYouPageCard;
  cardQrCode: IThankYouPageCard;
  nextSteps: INextStepsTyp;
  takeAppointment: ITechAppointmentCardSunrise;
  trackOrder?: ITrackOrder;
};

// endregion

// region COMMON

export interface IVoucherDiscountSchema {
  vouchersdiscount: {
    applyVoucher: {
      title: string;
      subtitle: string;
      mainActionLabel: string;
      image: string;
      placeholder: string;
      secondaryActionLabel: string;
      pills: {
        color: string;
        label: string;
      };
    };
    appliedVoucher: {
      recurrenceDisclaimer: string;
      subtitle: string;
      image: string;
      title: string;
    };
  };
}
export interface IError {
  buttonLabel: string;
  description: string;
  title: string;
}

export interface IGenericErrorCMS {
  genericerror: IError;
}

export interface IDisclaimer {
  text: string;
}

export interface IPanicMode {
  enablePanicModeFixed: string;
  enablePanicModeMobile: string;
}

export interface INewMop {
  enableNewMopFixed: string;
  enableNewMopMobile: string;
}

export interface IGlobalConfigCMS {
  globalconfig?: {
    modals: {
      enableButtonSticky: string;
    };
    cookies: {
      cartCookie: {
        durationHours: string;
      };
    };
    panicMode: IPanicMode;
    newMop: INewMop;
  };
}

export interface IShortCharacteristics {
  title: string;
  list: IList[];
}

export interface IHelp {
  enable: string;
  url: string;
  label?: string;
}

export interface IElement {
  id: string;
  label: string;
}

export interface IOperators {
  elements?: IElement[];
}

export interface IOperatorGroup extends IElement {
  operator?: {
    operators?: string;
  }[];
}

export interface IRedirectFWA {
  url: string;
  type: string;
  title: string;
  card: {
    description: string;
    title: string;
  };
  alternativeOfferRedirect: {
    enablePopUp: string;
    labelRedirect: string;
  };
}

export interface ICheckoutProductDetail {
  topImage: {
    lottie: string;
    iconMobile: string;
    icon: string;
  };
  footer: string;
  title: string;
  titleLockInHub: string;
  description: string;
  addressLabel: string;
  urlRedirectR02: string;
  urlClickToCallOtp: string;
  urlClickToCallNoCoverage: string;
  sendEmailHelp: IHelp;
  callMeNowHelp: IHelp;
  divider: IDivider;
  redirectFWA: IRedirectFWA;
  genericErrorCallMeNow: IHelp;
  paymentErrorCallMeNow: IHelp;
  buttonAcceptAndGoNext: string;
  buttonGoNext: string;
  buttonOfferDetail: { showLabel: string; hideLabel: string };
  pdfContractSummary: IContractualSummary;
  stickyBanner: IBannerCMS;
  topStikyLabel?: string;
}

export interface ICheckoutMap {
  description: string;
  enableMap: string;
  title: string;
  shortCharacteristics: {
    title: string;
    list: ISpecs[];
  };
}

export interface IOfferItem {
  description: string;
  title: string;
}

export interface ILoaders {
  coverageTool: string;
  shoppingCart: string;
  yourAddressCard: string;
  choosePayment: string;
}

export interface IOptionsCMS {
  text: string;
  title: string;
  description?: string;
  type: string;
  value: string;
}

export interface IList {
  description: string;
  text: string;
  title: string;
  titleOverlay: string;
  action: string;
  icon: string;
  value: string;
  detailLinkLabel: string;
  choice: IChoice;
  elements: ITextIcon[];
  portability: ITextIcon[];
}

export interface IListRecognitionSimMethodsSelections extends IList {
  value: ECodeIdentification;
}

export interface ISelectInput {
  lastName: string;
  docExpirationDate: string;
  birthdate: string;
  fiscalCode: string;
  username: string;
  docNumber: string;
  city: string;
  email: string;
  firstName: string;
  telephone: string;
  contact: string;
  country: string;
  password: string;
  docType: string;
  gender: string;
  nation: string;
  contactPlaceholder: string;
  operator: string;
  contract: string;
  migrationCode: string;
  civicNumber: string;
  address: string;
  municipality: string;
  province: string;
  cap: string;
  note: string;
  string: string;
  expirationDate: string;
  cvv: string;
  iban: string;
  type: string;
  user: string;
  issuingCountry: string;
  birthNation: string;
}

export interface IDroppedCartIaddDetail {
  text: string;
  icon: string;
}
export interface IDroppedCartIadd {
  title: string;
  description: string;
  ctaLabel: string;
  detailsLabel: string;
  details: IDroppedCartIaddDetail[]
}
export interface IDroppedCart {
  category?: string;
  title: string;
  titleAlternative?: string;
  description: string;
  specs: {
    value: string;
  }[];
  ctaLabel: string;
  ctaLabelAlternative?: string;
  alternativeOffer?: string;
  image?: string;
  imageMobile?: string;
  disableDroppedCart?: string;
  lockIn?: {
    descriptionLockIn?: string;
    titleLockIn?: string;
  };
}

export interface IDroppedCartCms {
  droppedcart: IDroppedCart;
}

export interface IDroppedCartHomepageCms {
  droppedcarthomepage: IDroppedCart;
}

export interface IVoucherApply {
  buttonConfirm: string;
  description: string;
  showDetailButton: string;
  inputPlaceholder: string;
  title: string;
  ctc: { url: string; label: string };
}
export interface IVoucherSpecs {
  productName: string;
  productDescription: string;
  priceDetails: string;
  oldPrice: string;
  voucherRemaning: string;
  newPrice: string;
  specifications?: string;
}

export interface IVoucherInsertionData {
  vouchersinsertion: {
    voucherSpecs: IVoucherSpecs;
    voucherApply: IVoucherApply;
  };
}

export interface IBlacklistID {
  id: string;
}

export interface IDigitalOperatorsConfig {
  operatorsconfig?: {
    operators?: IOperator;
  };
  operatorsconfiggroup?: {
    elements?: IOperatorGroup[];
  };
  blacklist?: {
    elements?: IBlacklistID[];
  };
}

export interface ILoadingCard {
  title: string;
  subtitle: string;
  loader: string;
}

// endregion

// region PRODUCT CMS

export interface IQueryParam {
  key: string;
  value: string;
}

export interface IHubProductDetail {
  frontLabel: string;
  offerCtaLabel: string;
  offerCtaTopLabel: string;
  marketingLabel: string;
  queryParam: IQueryParam[];
  shortCharacteristics: IShortCharacteristics;
  action?: IActionSlide;
}

export interface ITargetAction {
  label: string;
  title: string;
  description: string;
  action?: IActionSlide;
}

export interface IPdpProductDetail {
  productDescription: string;
  backUrl: string;
  offerDetailLabel: string;
  offerCTALabel: string;
  backLabel: string;
  disclaimer: IDisclaimer;
  inflactionText: string;
  divider: IDivider;
  action?: IActionSlide;
  targetAction?: ITargetAction;
  stickyBanner?: IBannerCMS;
  topStikyLabel?: string;
  noHeader?: string;
  noFooter?: string;
  enableCustomHeader?: string;
  enableNewHeader?: string;
}

export interface IProductCMS {
  offerdetail: IOfferDetail;
  offerdetailabtest: Partial<IOfferDetail>;
  tagging: ITaggingCMS;
  coexistence: ICoexistenceCMS;
  disclaimer: IDisclaimer;
  widgets: {
    sliders: ICarouselCMS[];
  };
  widgetscheckout?: {
    sliders: ICarouselCMS[];
  };
  widgetslockinapp?: {
    sliders: ICarouselCMS[];
  };
  widgetslockinmm?: {
    sliders: ICarouselCMS[];
  };
  widgetslockinhub?: {
    sliders: ICarouselCMS[];
  };
}

export interface IHomepageDetail {
  overlayImageMobile: string;
  bgImage: string;
  video: string;
  videoMobile: string;
  overlayImage: string;
  bgImageMobile: string;
  action: IActionSlide;
}

export interface IInflaction {
  text: string;
  popup: string;
  color: string;
  disclaimer?: string;
}

export interface IProductSeo {
  canonicalUrl: string;
  title: string;
  ogImageUrl: string;
  ogDescription: string;
}

export interface IFullBanner {
  title?: string;
  description: string;
  imageBackgroundDesktop?: string;
  imageBackgroundMobile?: string;
  backgroundGradient?: string;
  image?: string;
  customAction?: IActionCustom;
  disclaimer?: string;
}

export interface IProductRef {
  hubKey: string;
  id: string;
}

export interface IBannerShoppingCart {
  color: string;
  text: string;
  textColor: string;
}

export interface ILockInConfigCMS {
  lottie: string;
  image: string;
  imageMobile?: string;
  title: string;
  message: string;
  isMap: string;
  shortCharacteristicsLockIn?: IShortCharacteristics;
  banner?: IBannerShoppingCart;
}

export enum IFamilyPlusActionType {
  FAMILYPLUS_ACCEPT = '1',
  FAMILYPLUS_CONTINUE = '2',
  FAMILYPLUS_CTC = '3',
  FAMILYPLUS_REDIRECT = '4',
}

export interface IFamilyPlusAction {
  label: string;
  url: string;
  type: IFamilyPlusActionType;
}

export interface IFamilyPlusCMS {
  buttonAction?: IFamilyPlusAction;
  secondButtonAction?: IFamilyPlusAction;
  offerDetailsFamilyPlus?: IOfferItem[];
  title?: string;
  conditions?: IOfferItem[];
  description?: string;
  price?: string;
  recurrence?: string;
  offerPropositionTitle?: string;
  offerPropositionDescription?: string;
  labelShowDetails?: string;
  propositionItems?: { value: string; id: string }[];
  disableSecondActionInOfferDetails?: string;
  noCampaignTitle?: string;
  noCampaignMessage?: string;
  noCampaignActionLabel?: string;
  noCampaignActionCtcLabel?: string;
  noCampaignActionCtcUrl?: string;
  noCustomerTitle?: string;
  noCustomerMessage?: string;
  noCustomerActionLabel?: string;
}

export interface INoOfferErrorPopUp {
  title?: string;
  description?: string;
  buttonLabel?: string;
  url?: string;
}

export interface IDigitalBundleCMS {
  title?: string;
  description?: string;
  showDetailsLabel?: string;
  disableSecondAction?: string;
  firstAction?: IActionSunrise;
  secondAction?: IActionSunrise;
  loadingCard?: ILoadingCard;
  noOfferError?: INoOfferErrorPopUp;
}

export interface IErrorPartnerOffer {
  title: string;
  description: string;
  action: IActionSlide;
}

export interface IPartnerOfferCms {
  isPartnerOffer: string;
  errorPartnerOffer: IErrorPartnerOffer;
}

export type IPartnerOffer = Omit<IPartnerOfferCms, 'isPartnerOffer'> & {
  isPartnerOffer: boolean;
};

export interface ICvmConfigCms {
  idCvm: string;
  priceDescription: string;
  price: string;
  subprice: string;
  recurrence: string;
  originalPrice: string;
  stickyOfferDescription?: string;
  titleHubCard: string;
  mainAction: IActionCustom;
  secondAction: IActionCustom;
}

export interface IVoucherConfigCms {
  discountTitle: string;
  message: string;
  voucherToApply: string;
  enableAutoApply: string;
  codeApplied: string;
  description: string;
}

export interface ICartPopupCMS {
  htmlDesktop?: string;
  htmlTablet?: string;
  htmlMobile?: string;
  timerToDisplay?: string;
  isEnabledAlwaysShown?: string;
  isEnabled?: string;
}

export type ICartPopup = Omit<
  ICartPopupCMS,
  'isEnabledAlwaysShown' | 'timerToDisplay' | 'isEnabled'
> & {
  timerToDisplay?: number;
  isEnabledAlwaysShown?: boolean;
  isEnabled?: boolean;
};

export interface IAvaiableItem {
  elements: string;
}

export interface IOfferAvaiability {
  avaiableOperators?: IAvaiableItem[];
  avaiableGroups?: IAvaiableItem[];
}

export interface IOfferDetail {
  nextSteps?: INextStepsTyp;
  cartPopup?: ICartPopupCMS;
  id: number;
  planIdMobile: string;
  slug: string;
  offerImage: string;
  isTpoOffer: string;
  offerImageMobile: string;
  offerDescription: string;
  offerName: string;
  offerType: string;
  campaignId: string;
  portabilityDummyOperator: string;
  portabilityDummyNumber: string;
  familyPlusId: string;
  offerCategory: IOfferCategory;
  offerActivate: IOfferActive;
  panicMode: IActionSlide;
  ctcPdp: {
    urlCtc: string;
  };
  offerPrice: IOfferPrice;
  offerPriceVoucher?: IOfferPrice;
  offerVisibility: IOfferVisibility;
  hubProductDetail: IHubProductDetail;
  pdpProductDetail: IPdpProductDetail;
  checkoutProductDetail: ICheckoutProductDetail;
  partnerOffer: IPartnerOfferCms;
  checkoutMap: ICheckoutMap;
  fwaPurchaseCard: IFwaPurchaseCard;
  offerPriceDescription: IOfferItem[];
  offerCharacteristics: IOfferItem[];
  modalStickyFooterWallet: {
    offerPriceDescription: IOfferItem[];
    offerCharacteristics: IOfferItem[];
  };
  modalStickyVoucher: {
    offerPriceDescription: IOfferItem[];
    offerCharacteristics: IOfferItem[];
  };
  enableAbTest: string;
  enableVR: string;
  disableBackOnCoverage: string;
  enableRecurringPayment: string;
  offerLockInWeb: {
    lockInMM: ILockInConfigCMS;
    lockInHub: ILockInConfigCMS;
  };
  offerLockInApp: ILockInConfigCMS;
  homepageDetail: IHomepageDetail;
  seo: IProductSeo;
  loaders: ILoaders;
  // this is the old filed that canot be canceled
  inflaction: IInflaction;
  inflactionPdp: IInflaction;
  inflactionCartView: IInflaction;
  // this is the new that will deprecate the old one, once the editor will migrate all the content
  inflactionArray: IInflaction[];
  inflactionPdpArray: IInflaction[];
  inflactionCartViewArray: IInflaction[];
  offerAlternative: IOfferAlternative;
  offersLockIn: IOfferAlternative;
  productRef: IProductRef;
  operatorListBronzePlus: ICMSOperatorsList;
  familyPlus: IFamilyPlusCMS;
  portabilityOperatorsFirstAction?: IActionSlide;
  addon: {
    addonList: {
      id: string;
    }[];
  };
  common: {
    legacyCtc: {
      title: string;
      description: string;
      buttonLabel: string;
      url: string;
    };
  };
  cvmConfig: ICvmConfigCms[];
  vouchers: IVoucherConfigCms;
  isEsimOnly?: string;
  globalPromo: {
    isGlobalPromoActive: string;
  };
  digitalBundleConfig: IDigitalBundleCMS;
  offerAvaiability: IOfferAvaiability;
  landingPagePriceConfig?: ILandingPagePriceConfigCms;
  landingActivationConfig?: {
    clickToCallLanding: {
      url: string;
    };
  };
  landingCTC?: string;
}

export interface IOfferAlternative {
  message: string;
  offersId: IOfferId[];
  map: {
    labelCta: string;
    modalTitle: string;
    modalContent: string;
  };
}

export interface IOfferId {
  id: string;
  technology?: string;
  showMessage?: string;
}

export interface ICoexistenceCMS {
  url: string;
}

export interface IDetailsPageCMS {
  detailspage: {
    common: {
      viewDetails?: string;
      goBackLabel?: string;
      productTopPriceLabel?: string;
    }
    price: {
      title?: string;
      recurrencyLabel?: string;
      immediateLabel?: string;
      firstInvoiceLabel?: string;
      totalWithPeriodLabel?: string;
      priceNotice?: string;
    }
  }
}

export interface IAddonCMS {
  [key: string]: IAddonDetailsCMS;
}

export interface IAddonDetailsCMS {
  cmsId?: string;
  addondetails?: {
    id?: string;
    name?: string;
    common?: {
      recurringLabel?: string;
      ctaRemoveLabel?: string;
      priceActivationLabel?: string;
      priceRecurring?: string;
      ctaCloseLabel?: string;
      ctaAddLabel?: string;
      ctaAddedLabel?: string;
    };
    card?: {
      description?: string;
      popUpCtaLabel?: string;
      cardImage?: string;
      title?: string;
    };
    summary?: {
      description?: string;
      topPrice?: string;
      benefit?: string;
    };
    detailPopup?: {
      popupTitle?: string;
      popupDescription?: string;
    };
  };
}

export interface IAddonCommonsCMS {
  addoncommons: {
    addonStep: {
      skipCtaLabel: string;
      topText: string;
    };
    language: string;
    removePopup: {
      description: string;
      icon?: string;
      title: string;
      continueCtaLabel: string;
      removeCtaLabel: string;
    };
  };
}

export interface ITaggingCMS {
  parameters: string;
}

export interface ICarouselCMSButton {
  label: string;
  localUrl?: string;
  url?: string;
}

export interface ICarouselCMS {
  type: IWidgetType;
  ref?: string;
  description?: string;
  descriptionMobile?: string;
  image?: string;
  imageMobile?: string;
  title: string;
  elements: ISlides[];
  divider?: IDivider;
  disclaimer?: string;
  tagging?: ITaggingCMS;
  customAction?: IActionSlide;
  button?: ICarouselCMSButton;
  isNoAnimation?: boolean;
  isShort?: string;
  isOnlyWithAppSession?: string;
}

export interface IOfferCategory {
  id: string;
  isLandingClub?: string;
  topTab: string;
  tags: IKeyword[];
  isAutoWallet: string;
  removeCtc: string;
  isFamilyPlusProduct: string;
  forceCampaign: string;
  enableLockInFlow: string;
  isLockInProduct: string;
  isRequiredPortability: string;
  isCvm: string;
  noCookie: string;
  disableClickDetail: string;
  enableVouchers: string;
  bypassLockInMM: string;
  offerTypes: string;
  offerCategory: string;
  fallbackToBtl: string;
  fallbackCtcEnabled: string;
  skipMpn: string;
}

export interface IOfferActive {
  type: string;
  title: string;
  urlForRedirect: string;
}

export interface IOfferPrice {
  description: string;
  detail: string;
  hasEmptySpace: string;
  frontLabel: string;
  descriptionActivation: string;
  descriptionEsimFd: string;
  descriptionActivationShipping: string;
  descriptionActivationRecurring: string;
  descriptionActivationRecurringShipping: string;
  descriptionRecurring: string;
  descriptionRecurringShipping: string;
  price: string;
  originalPrice: string;
  priceActivationRecurring: string;
  priceActivation: string;
  priceRecurring: string;
  subpriceActivationRecurring: string;
  subpriceActivation: string;
  subpriceRecurring: string;
  recurrence: string;
  activationCost: IActivationCost;
  topStikyLabel?: string;
}

export interface IActivationCost {
  payInstallments: string;
  payOneTime: string;
  monthly: string;
}

export interface IOfferVisibility {
  showInPromo: string;
  showInSolutions: string;
  showInHP: string;
  showInRelated: string;
  showInHub: string;
  disablePdp: string;
}

// endregion

// region HOMEPAGE

export interface IHomePageSlide {
  heroslider: IHeroSlider;
  widgets: {
    sliders: ICarouselCMS[];
  };
}

export interface IPageContent {
  coexistence: ICoexistenceCMS;
  widgets: {
    sliders: ICarouselCMS[];
  };
}

// endregion

// region CHECKOUT

export interface IMapCms {
  storemap: IStoreMap;
}

export interface IStoreMap {
  common: IStoreMapItems;
  postalOffice: IStoreMapItems;
  vfStore: IStoreMapItems;
  pickupPoint: IStoreMapItems;
}

export interface IStoreMapItems {
  subtitle: string;
  title: string;
  switchLabelDX?: string;
  switchLabelSX?: string;
  ctaModifyLable?: string;
  ctaSaveLable?: string;
  titleAddressChange: string;
  descriptionMobile: string;
  ctaLabel: string;
  subtitleAddressChange: string;
  descriptionFWA: string;
  descriptionFTTH: string;
}

// endregion

// region LANDING

export interface IBillingPageCms {
  fatturazione2830: {
    topImage: {
      alt: string;
      image: string;
      imageMobile: string;
    };
    pageTexts: {
      bottom: string;
      top: string;
    };
    formLabels: {
      bottonLabel: string;
      input: string;
      label: string;
    };
    modals: {
      success: {
        description: string;
        title: string;
      };
      error: {
        descriptionAlreadyRequested?: string;
        descriptionNotFound?: string;
        title: string;
      };
    };
  };
}
export interface IVideoRecognition {
  videorecognition: {
    barCodeAcquisition: {
      subtitle: string;
      description: string;
      image: string;
      imageMobile: string;
      title: string;
      labels: {
        anchor: string;
        button: string;
        input: string;
      };
    };
    simInfoModal: {
      modalContent: string;
      title: string;
    };
    flowImages: {
      background: string;
      backgroundMobile: string;
      logo: string;
    };
    errorModal: {
      serialNotCorrect: {
        description: string;
        button: string;
        title: string;
      };
    };
    qrOverlay: {
      title: string;
      description: string;
      qrImage: string;
    };
  };
}

export interface IBrightSkyCms {
  brightsky: {
    imagePath: string;
    redirectLabel: string;
    isVisible: 'true' | 'false';
    text: string;
    redirectUrl: string;
    mode: 'popup' | 'redirect';
    marketId: string;
    srcScript: string;
  };
}

export interface IGlobalPromo {
  label: string;
  color: string;
  cartDisclaimer: string;
  popupTitle: string;
  popupDescription: string;
  detailPageDescription: string;
  detailPageDisclaimer: string;
  characteristcs: IOfferItem[];
  droppedCartLabel: string;
  slides: ISlides[];
  marketingTagLabel?: string;
}

export interface IGlobalPromoCMS {
  globalpromo: IGlobalPromo;
}

export interface IStickyWhatsapp {
  customAction?: IActionSlide;
  description?: string;
  image?: string;
  isVisible?: string;
  stickyIcon?: string;
  stickyTitle?: string;
  title?: string;
  footerDescription?: string;
  footerNumberUrl?: string;
  footerIcon?: string;
  footerNumberLabel?: string;
  footerDescriptionMobile?: string;
}

export interface IProductCharacteristicsTable {
  title: string;
  description: string;
  table: {
    title: string;
    value: string;
  }[];
  buttonLabel: string;
  buttonLink: string;
}

export interface ILandingPagePriceCms {
  label?: string;
  amount?: string;
  oldAmount?: string;
  recurrence?: string;
}

export interface ILandingPagePriceConfigCms {
  oneShot?: ILandingPagePriceCms;
  tryAndBy?: ILandingPagePriceCms;
  fixedPrice?: ILandingPagePriceCms;
  isTryAndBy?: string;
}
