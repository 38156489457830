import {
  CMS_CONFIG,
  handleFirstCharacterOfSentence,
  IAddonDetailsCMS,
  IDetailsPageCMS,
  retrieveProductTechnology,
  useCmsConfig,
} from '@vfit/consumer/data-access';
import {
  API,
  openPopup,
  PAGES,
  setTrackLink,
  setTrackView,
  trackLink,
  ITracking,
  useTracking,
} from '@vfit/shared/data-access';
import { ButtonSlide, Svg } from '@vfit/shared/atoms';
import React, { useState } from 'react';
import { ProductDetail } from '@vfit/shared/components';
import { useQueryClient } from 'react-query';
import { SoftManagerService } from '@vfit/consumer/providers';
import { getLocalAddress } from '@vfit/consumer/ibuyfixed';
import { removeCurrency } from '@vfit/consumer/hooks';
import { IProductDetailExtended } from './productDetailExtended.models';
import * as S from './productDetailExtended.style';
import { ProductBlock } from './components/ProductBlock/productBlock';
import { AddonsBlock } from './components/AddonsBlock/addonsBlock';
import { getUserInfo } from '../../../../ibuyfixed/src/lib/Checkout/checkout.utils';
import { PriceBlock } from './components/PriceBlock/priceBlock';
import { evaluateLabels } from './productDetailExtended.utils';

const ProductDetailExtended = ({
  product,
  addons,
  addonState,
  setAddonState,
  supportModule,
}: IProductDetailExtended) => {
  const [isDetailMode, setDetailMode] = useState(false);
  const queryClient = useQueryClient();
  const { retrieveCartAddons, getCartProductsWithAddon } = SoftManagerService(queryClient);
  const detailsPageCMS = useCmsConfig(
    CMS_CONFIG[PAGES.CONSUMER],
    API.CMS_GET_DETAILS_PAGE
  ) as IDetailsPageCMS;
  const detailsPageLabels = evaluateLabels(detailsPageCMS);
  const addonList: IAddonDetailsCMS[] = retrieveCartAddons(addons);
  const trackingOpt: ITracking = {
    event: ['view'],
    event_label: 'offer details',
    opts: {
      product_target_segment: 'consumer',
      page_section: 'fixed offers',
      page_subsection: 'checkout',
      journey_type: 'journey',
      journey_name: 'checkout',
      product_technology: retrieveProductTechnology() || '',
    },
    cartProduct: {
      cart_product_quantity: '1',
      cart_product_id: `${product?.offerId}`,
      cart_product_name: product?.slug,
      cart_product_category: 'fixed',
      cart_product_price:
        `${product?.cartPrices?.offerPrice.amount}` ||
        (product.price ? removeCurrency(product.price) : ''),
      ...getCartProductsWithAddon(product, addonList),
    },
    visitorTrackingOpts: getUserInfo(),
    disableInitialTrack: false,
  };

  const { trackView } = useTracking({
    ...(trackingOpt || {
      event: ['ui_interaction'],
    }),
  });
  setTrackLink(trackView, 'offer details');
  setTrackView(trackView);

  const removeAddon = (addonToRemove: string) => {
    const addonMecId =
      addonList.find((addon) => addon?.cmsId === addonToRemove)?.addondetails?.id || '';
    trackLink(`rimuovi ${addonMecId}`, 'users', 'click', 'cart_remove');
    setAddonState({
      ...addonState,
      addonMecId,
      addonCmsId: addonToRemove,
      callRemoveAddon: true,
    });
  };

  const clickToCall = () => {
    trackView({
      event_name: 'ui_interaction',
      event_label_track: ':click on cta',
      event_category: 'users',
      event_action: 'click',
      tracking_type: 'link',
    });
    if (product.callMeNowHelp?.url) openPopup(product.callMeNowHelp?.url);
  };

  return (
    <>
      {!isDetailMode && (
        <S.BlocksContainer>
          <ProductBlock product={product} addonList={addonList} setDetailMode={setDetailMode} />

          {addonList && addonList.length > 0 && (
            <AddonsBlock
              addonList={addonList}
              isLoadingUpdate={addonState.isLoadingUpdate}
              removeAddon={removeAddon}
            />
          )}

          <PriceBlock product={product} labels={detailsPageLabels} />

          <S.BigText>{supportModule?.supportmodules?.needHelp?.title}</S.BigText>
          <ButtonSlide
            label={supportModule?.supportmodules?.needHelp?.ctaSupportCall}
            onClick={clickToCall}
            buttonColor="#fff"
            labelColor="#262626"
            borderColor="#262626"
          />
        </S.BlocksContainer>
      )}
      {isDetailMode && (
        <>
          <S.Breadcrumb onClick={() => setDetailMode(false)}>
            <Svg name="chevronLeft" width={16} height={16} />
            <S.GoBackText>{detailsPageLabels.goBackLabel}</S.GoBackText>
          </S.Breadcrumb>
          <ProductDetail
            address={
              getLocalAddress()?.displayedAddress
                ? `${
                    product?.shoppingCart?.addressLabel || ''
                  }<br/><b>${handleFirstCharacterOfSentence(
                    getLocalAddress()?.displayedAddress || ''
                  )}</b>`
                : ''
            }
            trackingOpt={{ event: ['view'], disableInitialTrack: true }}
            modalInfo={{
              title: supportModule?.supportmodules?.needHelp?.title || '',
              ctaName: supportModule?.supportmodules?.needHelp?.ctaSupportCall || '',
            }}
            details={{
              title: product.title,
              conditions: product.conditions,
              callMeNowHelp: product.callMeNowHelp,
              description: product.description,
              offerDetails: product.offerDetails,
              price: `${product.cartPrices?.offerPrice.amount}` || product.price,
              recurrence: product.recurrence,
              voucherPromoLabel: product?.voucherPromoLabel,
              voucherPromoMessage: product?.voucherPromoMessage,
              voucherPromoDetail: product?.voucherPromoDetail,
            }}
          />
        </>
      )}
    </>
  );
};

export default ProductDetailExtended;
