import { useEffect, useState } from 'react';
import { API, getFromLocalStorageByKey, PAGES, setTrackView } from '@vfit/shared/data-access';
import {
  CMS_CONFIG,
  getCurrentUserType,
  ICustomerReferenceDataResponse,
  IPersonalInfoCMS,
  IUserType,
  useCmsConfig,
} from '@vfit/consumer/data-access';
import { ICommonData, ICountryObject, IPersonalDataObject } from '@vfit/shared/models';
import { useTracking } from '@vfit/shared/data-access';
import {useQueryClient} from "react-query";
import DocumentId from './components/DocumentId/documentId';
import { DEFAULT_CUSTOMER_REFERENCE, organizeDocument } from './documentCard.utils';
import { useCheckout } from '../../../iBuyFixed.context';
import { ID_FLOWS } from '../../checkout.constants';
import { getTagging, getUserInfo } from '../../checkout.utils';

const DocumentIdCard = ({ handleOnChangeEnableGoNext }: ICommonData) => {
  const [isValidForm, setIsValidForm] = useState(false);
  const { owningData, findCaller, setCurrentStepKey, setOwningData } = useCheckout();
  const documentIdCardCMS = useCmsConfig(
    CMS_CONFIG[PAGES.CONSUMER],
    API.CMS_GET_PERSONAL_DATA
  ) as IPersonalInfoCMS;
  const queryClient = useQueryClient();
  const tagging = getTagging('document', 'fixed', queryClient);

  const { trackView } = useTracking({
    event: ['checkout_step2'],
    event_label: 'document',
    opts: tagging?.opts,
    cartProduct: tagging?.trackingProduct,
    pageProduct: tagging?.pageProductsInfo,
    visitorTrackingOpts: getUserInfo(),
  });
  setTrackView(trackView);

  const isRecognized = owningData?.isLogged || findCaller?.customerHasActiveSubs;
  const countries: ICountryObject[] = getFromLocalStorageByKey('getCountry');
  const customerReference: ICustomerReferenceDataResponse =
    getFromLocalStorageByKey('customerReference') || DEFAULT_CUSTOMER_REFERENCE;

  const onChangeData = (data: IPersonalDataObject) => {
    setOwningData(data);
  };

  const checkReadonly = (): boolean => {
    const customerData = getFromLocalStorageByKey('customerData');
    if (customerData) {
      const selectedDate = new Date(
        customerData?.[0].owningIndividual?.identification[0].expirationDate || ''
      );
      const yesterday: Date = new Date();
      if (
        (getCurrentUserType() === IUserType.NEXT_USER_ACTIVE ||
          getCurrentUserType() === IUserType.LOGGED_USER) &&
        selectedDate <= yesterday
      ) {
        return false;
      }
      if (
        (getCurrentUserType() === IUserType.NEXT_USER_ACTIVE ||
          getCurrentUserType() === IUserType.LOGGED_USER) &&
        selectedDate > yesterday
      ) {
        return true;
      }
    }
    return false;
  };

  useEffect(() => {
    setTimeout(() => {
      handleOnChangeEnableGoNext?.(isValidForm);
    }, 1000);
  }, [isValidForm, owningData]);

  useEffect(() => {
    handleOnChangeEnableGoNext?.(false);
    const oldCF = owningData?.owningIndividual?.fiscalCode;
    localStorage.setItem('customerDataCf', oldCF);
    setCurrentStepKey(ID_FLOWS.DOCUMENT_ID);
  }, []);

  return (
    <DocumentId
      configCms={{
        ...organizeDocument(documentIdCardCMS, isRecognized),
      }}
      data={owningData}
      onChangeData={onChangeData}
      identificationType={
        customerReference?.identificationType?.map((item: any) => item.displayName) || ['']
      }
      nationality={(countries as ICountryObject[])?.map((item: any) => item.displayName) || ['']}
      isLoading={false}
      setIsValidForm={(valid: boolean) => setIsValidForm(valid)}
      isReadonly={checkReadonly()}
    />
  );
};

export default DocumentIdCard;
