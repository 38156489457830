import { checkWindow, isAcceptedCookieLawGroup } from '@vfit/shared/data-access';
import { useEffect } from 'react';
import { DefaultPrivacyLevel } from '@datadog/browser-rum';
import { IDatadog } from './datadog.models';

export const DatadogWrapper = ({ beforeSend }: IDatadog) => {
  useEffect(() => {
    if (process.env?.['NEXT_PUBLIC_DATADOG_ENABLED']?.toLowerCase() !== 'true') return;
    if (!checkWindow() || window?.['datadog_service'] || !isAcceptedCookieLawGroup('2')) return;

    (async () => {
      if (!process.env?.['NX_DATADOG_APPLICATION_ID'] || !process.env?.['NX_DATADOG_CLIENT_TOKEN'])
        return;

      const datadogRum = await import('@datadog/browser-rum').then((lib) => lib.datadogRum);

      if (!window?.['datadogRumInitialized']) {
        datadogRum.init({
          applicationId: process.env?.['NX_DATADOG_APPLICATION_ID'],
          clientToken: process.env?.['NX_DATADOG_CLIENT_TOKEN'],
          site: process.env?.['NX_DATADOG_SITE'] ?? 'datadoghq.eu',
          service: process.env?.['NEXT_PUBLIC_DATADOG_SERVICE'] ?? 'web-landing',
          env: process.env?.['NEXT_PUBLIC_DATADOG_ENV'] ?? 'test',
          version: process.env?.['NEXT_PUBLIC_GIT_VERSION'] ?? '',
          sessionSampleRate: Number(process.env?.['NEXT_PUBLIC_DATADOG_SESSION_SAMPLE'] ?? 0),
          sessionReplaySampleRate: Number(process.env?.['NEXT_PUBLIC_DATADOG_SESSION_REPLAY'] ?? 0),
          trackResources: true,
          trackLongTasks: true,
          trackUserInteractions: Boolean(process.env?.['NEXT_PUBLIC_TRACK_USER_INTERACTIONS']) ?? true,
          defaultPrivacyLevel:
            (process.env?.['NEXT_PUBLIC_DEFAULT_PRIVACY_LEVEL'] as DefaultPrivacyLevel) ?? 'mask-user-input',
          ...(beforeSend && beforeSend),
        });
        window['datadog_service'] = datadogRum;
        window['datadogRumInitialized'] = true;
      }
    })();
  }, []);
  return null;
};
