import {
  GetCustomerResponse,
  ISilentLoginResponse,
  LOGGED_USER_KEYS,
} from '@vfit/consumer/data-access';
import { useQueryClient } from 'react-query';
import { getFromLocalStorageByKey } from '@vfit/shared/data-access';
import { useCheckout } from '../iBuyMobile.context';
import { useShoppingCartInfo } from './hooks/useShoppingCartInfo';

const useShoppingCart = () => {
  const { product } = useCheckout();
  const queryClient = useQueryClient();
  const silentLogin: ISilentLoginResponse = queryClient.getQueryData(
    'silentLogin'
  ) as ISilentLoginResponse;
  const IS_DISABLED_CREATE_CART = product?.isFamilyPlusProduct || product?.forceCampaign;
  const customerDataList: GetCustomerResponse[] = getFromLocalStorageByKey('customerData');
  const customerData = customerDataList?.[0];
  const isLoggedUser = LOGGED_USER_KEYS.includes(silentLogin?.flowId) || false;
  const {
    errorCart,
    isCompletedSilentLogin,
    isLoading: isLoadingShoppingCartInfo,
    isError: isErrorShoppingCart,
    errorMessage: errorMessageShoppingCart,
  } = useShoppingCartInfo(product, customerData?.id, isLoggedUser, IS_DISABLED_CREATE_CART);

  return {
    errorCart,
    isLoadingShoppingCartInfo: isLoadingShoppingCartInfo || !isCompletedSilentLogin,
    isErrorShoppingCart,
    errorMessageShoppingCart,
  };
};

export default useShoppingCart;
