import { useEffect, useState } from 'react';
import { API, PAGES } from '@vfit/shared/data-access';
import { ICommonData, IOptionalText } from '@vfit/shared/models';
import {
  CMS_CONFIG,
  useCmsConfig,
  IPortabilityCMS,
  errorMock,
  IGenericErrorCMS,
} from '@vfit/consumer/data-access';
import { LoggerInstance, useTracking } from '@vfit/shared/data-access';
import { CheckboxCards } from '@vfit/consumer/components';
import { Skeleton } from '@vfit/shared/components';
import {useQueryClient} from "react-query";
import { organizePortabilityOperatorsCard, skeletonShape } from './portabilityOperatorsCard.utils';
import { getTagging, getUserInfo } from '../../checkout.utils';
import { useCheckout } from '../../../iBuyFixed.context';
import { ID_FLOWS } from '../../checkout.constants';

const PortabilityOperatorsCard = ({ handleOnChangeEnableGoNext }: ICommonData) => {
  const {
    checkoutErrors,
    isModalButtonSticky,
    portability,
    portabilityStatus,
    setSlidesGoBack,
    setPortability,
    setPortabilityStatus,
    setCurrentStepKey,
  } = useCheckout();
  const queryClient = useQueryClient();
  const [selectedItem, setSelectedItem] = useState<IOptionalText>();
  const genericError = useCmsConfig(
    CMS_CONFIG[PAGES.CONSUMER],
    API.CMS_GET_GENERIC_ERROR
  ) as IGenericErrorCMS;

  const portabilityOperatorsCardCMS = useCmsConfig(
    CMS_CONFIG[PAGES.CONSUMER],
    API.CMS_GET_PORTABILITY
  ) as IPortabilityCMS;
  const { title, description, items } = organizePortabilityOperatorsCard(
    portabilityOperatorsCardCMS
  );

  const taggingFixed = getTagging('portability', 'fixed', queryClient);
  useTracking({
    event: ['checkout_step4.3'],
    event_label: 'carrier information',
    opts: taggingFixed.opts,
    cartProduct: taggingFixed.trackingProduct,
    pageProduct: taggingFixed?.pageProductsInfo,
    visitorTrackingOpts: getUserInfo(),
  });

  const handleSelect = (item: IOptionalText | undefined) => {
    setPortability({
      ...portability,
      portabilityOperators: item?.value,
    });
    if (item && handleOnChangeEnableGoNext) handleOnChangeEnableGoNext(true);
  };

  useEffect(() => {
    if (portabilityStatus?.isErrorCheck) {
      // TODO add error in portability hook
      LoggerInstance.log('Error: portability_error');
      checkoutErrors?.showAndTrackError?.(
        genericError,
        () => {
          setPortabilityStatus({
            ...portabilityStatus,
            isLoadingCheck: false,
            isSuccessCheck: false,
            isErrorCheck: false,
          });
          setPortability({
            ...portability,
          });
          setSlidesGoBack(3);
        },
        errorMock(
          'portability',
          { url: 'portabilityOperators', status: '500', statusText: '500' },
          portabilityStatus?.errorMessage
        ),
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        isModalButtonSticky,
        true
      );
    }
  }, [portability]);

  useEffect(() => {
    setCurrentStepKey(ID_FLOWS.PORTABILITY_INFORMATIONS);
    setSelectedItem(undefined);
    setPortabilityStatus({
      ...portabilityStatus,
      isStartPortIn: false,
    });
    setPortability({
      ...portability,
      portabilityOperators: '',
    });
  }, []);

  if (portabilityStatus?.isLoadingCheck) {
    return <Skeleton {...skeletonShape} />;
  }

  return (
    <CheckboxCards
      title={title}
      description={description}
      items={items}
      value={portability.portabilityOperators}
      selectedItem={selectedItem}
      setSelectedItem={setSelectedItem}
      onSelectItem={handleSelect}
    />
  );
};

export default PortabilityOperatorsCard;

