import { useEffect, useState } from 'react';
import {
  CMS_CONFIG,
  getBackAndBlockinErrors,
  IGenericErrorCMS,
  productSpecificError,
  useCmsConfig,
} from '@vfit/consumer/data-access';
import { API, getHome, PAGES, setTrackLink, resetData, useTracking } from '@vfit/shared/data-access';
import { ICMSApiError } from '@vfit/shared/models';
import { useQueryClient } from 'react-query';
import { getUserInfo, getTagging, getTrackError } from '../../../../../../checkout.utils';
import { ID_FLOWS } from '../../../../../../checkout.constants';
import { useCheckoutAutoWalletFlow } from '../../../../../../hooks/UseCheckoutAutoWalletFlow/useCheckoutAutoWalletFlow';
import { useCheckout } from '../../../../../../../iBuyMobile.context';

const AutoWalletSubmitCard = () => {
  const [loadingForFinalPortIn, setLoadingForFinalPortIn] = useState(true);
  const {
    checkoutErrors,
    product,
    portabilityStatus,
    setSlidesGoBack,
    setIsLastCard,
    setIsDisabledBackButton,
    setHideStickyBar,
  } = useCheckout();
  const { isLoading, isSuccess, isError, error } = useCheckoutAutoWalletFlow();
  const orderErrors = useCmsConfig(
    CMS_CONFIG[PAGES.CONSUMER],
    API.CMS_GET_INSTANT_ORDER_ERROR_MOBILE
  ) as ICMSApiError;
  const genericError = useCmsConfig(
    CMS_CONFIG[PAGES.CONSUMER],
    API.CMS_GET_GENERIC_ERROR_MOBILE
  ) as IGenericErrorCMS;
  const client = useQueryClient();
  const { buttonGenericError } = productSpecificError(product);
  const taggingMobile = getTagging('checkout');
  const isDisabledRedirect = localStorage.getItem('disableRed');
  const { goBackErrorOnPayment, blockingFormattedError, blockingErrors } =
    getBackAndBlockinErrors(orderErrors);

  const { trackView } = useTracking({
    event: ['order_loading'],
    event_label: 'verifying_payment_information',
    opts: taggingMobile?.opts,
    cartProduct: taggingMobile?.trackingProduct,
    pageProduct: taggingMobile?.pageProductsInfo,
    visitorTrackingOpts: getUserInfo(),
  });
  setTrackLink(trackView, 'verifying_payment_information');

  const goToError = () => {
    const goBackAutoWallet = () => {
      resetData(client, ['paymentMethodPut', 'getShoppingCartUpdatePayment']);
      setIsLastCard(false);
      setIsDisabledBackButton(false);
      setHideStickyBar(false);
      setSlidesGoBack(2);
    };
    const foundedErrors = goBackErrorOnPayment?.filter(
      (el) => el.error?.toLowerCase() === error?.errorType?.toLowerCase()
    );
    const foundedError = error?.errorCode
      ? foundedErrors?.find((el) => el.errorCode == error.errorCode)
      : foundedErrors?.find((el) => !el.errorCode) || foundedErrors?.[0];
    trackView(
      getTrackError(
        { ...error, isBlocking: foundedError?.isBlocking === 'true' },
        ID_FLOWS.UPFRONT_PAYMENT
      )
    );
    checkoutErrors?.showAndTrackError?.(
      genericError,
      goBackAutoWallet,
      error,
      foundedError?.title,
      foundedError?.message,
      goBackAutoWallet,
      'Riprova'
    );
  };

  useEffect(() => {
    if (!isLoading && isSuccess && !isError) {
      if (!isDisabledRedirect) {
        window.open(`${getHome()}/${PAGES.THANK_YOU}`, '_self');
      }
    } else if (!isLoading && isError) {
      if (blockingFormattedError.includes(error?.errorType?.toLowerCase() as string)) {
        const foundedErrors = blockingErrors.filter(
          (el) => el.error?.toLowerCase() === error?.errorType?.toLowerCase()
        );
        const foundedError = error?.errorCode
          ? foundedErrors?.find((el) => el.errorCode == error.errorCode)
          : foundedErrors?.find((el) => !el.errorCode) || foundedErrors?.[0];
        trackView(
          getTrackError(
            { ...error, isBlocking: foundedError?.isBlocking === 'true' },
            ID_FLOWS.UPFRONT_PAYMENT
          )
        );
        checkoutErrors?.showAndTrackError?.(
          genericError,
          () => {
            if (!isDisabledRedirect) {
              window?.location?.reload();
            }
          },
          error,
          foundedError?.title,
          foundedError?.message,
          () => {
            if (!isDisabledRedirect) {
              window?.location?.reload();
            }
          },
          buttonGenericError
        );
      } else {
        goToError();
      }
    }
  }, [isLoading, isError, isSuccess, loadingForFinalPortIn]);

  useEffect(() => {
    if (portabilityStatus?.isSuccess) {
      setLoadingForFinalPortIn(false);
    }
  }, [portabilityStatus]);

  return null;
};

export default AutoWalletSubmitCard;

