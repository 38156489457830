import { createContext, ReactNode, useEffect, useMemo, useState } from 'react';
import { useQueryClient } from 'react-query';

export interface IRetarget {
  validityStartTime: string;
  validityDuration: string;
  label: string;
}
interface IRetargetContext {
  setOfferTimer: (version: string, productId: string, retarget?: IRetarget) => any;
  checkRetargetActive: ({
    productId,
    selectedTimer,
    version,
    retarget,
  }: {
    productId?: string;
    selectedTimer?: ITimer;
    version?: string;
    retarget?: IRetarget

  }) => ITimerStatus;
  retargetInformation: ITimer[];
  versionAEM?: string;
}
interface ITimer {
  offerId?: string;
  version: string;
  timer: {
    start: string;
    end: string;
  };
}

const initialState: IRetargetContext = {
  setOfferTimer: () => {},
  checkRetargetActive: () => ITimerStatus.NOT_SAVED,
  retargetInformation: [],
  versionAEM: '',
};

export enum ITimerStatus {
  SAVED = 'SAVED',
  NOT_SAVED = 'NOT_SAVED',
  ACTIVE = 'ACTIVE',
  EXPIRED = 'EXPIRED',
}

export const RetargetContext = createContext(initialState);

export const RetargetContextProvider = ({
  children,
  versionAEM,
  isShoppingCartPage = false,
}: {
  children: ReactNode;
  versionAEM?: string;
  isShoppingCartPage?: boolean;
}) => {
  const [retargetInformation, setRetargetInformation] = useState<ITimer[]>([]);
  const queryClient = useQueryClient();

  useEffect(() => {
    // retrive all local storage data
    const validKeyRetarget = Object.keys(window.localStorage).filter(
      (k) => k.includes('product_retarget_') && window.localStorage[k]
    );

    const retargetLocalData = validKeyRetarget
      .map((k) => {
        const productRetargetInfo = JSON.parse(window.localStorage[k]);
        const retargetStatus = checkRetargetActive({ selectedTimer: productRetargetInfo });
        // checkIfExpired
        if (retargetStatus === ITimerStatus.EXPIRED) window.localStorage.removeItem(k);

        // remove old data if expired/active && not shopping cart
        if (
          (retargetStatus === ITimerStatus.EXPIRED || retargetStatus === ITimerStatus.ACTIVE) &&
          !isShoppingCartPage
        )
          queryClient.removeQueries([productRetargetInfo.offerId, 'ecommerceCatalogCoreOffer']);

        return window.localStorage?.[k] && JSON.parse(window.localStorage[k]);
      })
      .filter(Boolean);
    setRetargetInformation([...retargetLocalData]);
  }, []);

  const minToMs = (min: string) => parseInt(min, 10) * 60000;

  const updateFinishTimer = (offer: ITimer, retarget: IRetarget): ITimer => {
    const date = new Date().getTime();
    const newFinishOfferDate = date + minToMs(retarget?.validityDuration || '0');
    const obj = { ...offer, timer: { ...offer.timer, end: newFinishOfferDate.toString() } };
    return obj;
  };

  const checkRetargetActive = ({
    productId,
    selectedTimer,
    version,
    retarget
  }: {
    productId?: string;
    selectedTimer?: ITimer;
    version?: string;
    retarget?: IRetarget
  }): ITimerStatus => {
    const element = selectedTimer || retargetInformation?.find((e) => e?.version === version && e?.offerId === productId)!;
    const date = new Date().getTime();
    const timerStart = parseInt(element?.timer?.start, 10);
    const timerEnd = parseInt(element?.timer?.end, 10);

    if ((date > timerStart && date < timerEnd) || retarget?.validityStartTime === "0") {
      return ITimerStatus.ACTIVE;
    }
    if (date < timerStart) return ITimerStatus.SAVED;
    if (date > timerEnd) return ITimerStatus.EXPIRED;
    return ITimerStatus.NOT_SAVED;
  };

  // function to set retarget information in local storage
  const setOfferTimer = async (version: string, productId: string, retarget?: IRetarget) => {
    const date = new Date().getTime();
    const startTimer = date + minToMs(retarget?.validityStartTime || '0');
    const finishTimer = startTimer + minToMs(retarget?.validityDuration || '0');

    if (typeof retarget === 'undefined') return;

    const currentOffer = retargetInformation?.find(
      (e) => e?.version === version && e?.offerId === productId
    );

    let newTimer = null;

    switch (checkRetargetActive({ productId, version })) {
      case ITimerStatus.ACTIVE:
        newTimer = updateFinishTimer(currentOffer!, retarget);
        break;
      case ITimerStatus.SAVED:
        break;
      default:
        newTimer = {
          offerId: productId || '',
          version,
          timer: {
            start: startTimer.toString(),
            end: finishTimer.toString(),
          },
        };
    }

    if (newTimer) {
      // add/update timer
      setRetargetInformation([...retargetInformation, ...[newTimer]]);

      window.localStorage.setItem(
        `product_retarget_${productId}_${version}`,
        JSON.stringify(newTimer)
      );
    }
  };

  const value = useMemo(
    () => ({ setOfferTimer, checkRetargetActive, retargetInformation, versionAEM }),
    [setOfferTimer, checkRetargetActive, retargetInformation, versionAEM]
  );

  return <RetargetContext.Provider value={value}>{children}</RetargetContext.Provider>;
};

