import {
  CMS_CONFIG,
  IGenericErrorCMS,
  IGetAddonResponse,
  IOfferingType,
  useAddAddon,
  useCmsConfig,
  useGetAddons,
  useGetShoppingCartSilentUpdate,
  useRemoveAddon,
} from '@vfit/consumer/data-access';
import { API, getFromLocalStorageByKey, PAGES, LoggerInstance } from '@vfit/shared/data-access';
import { useEffect } from 'react';
import { IUpdateProductPriceType } from '@vfit/shared/models';
import { updateProductPrice } from '@vfit/consumer/ibuyfixed';
import { useQueryClient } from 'react-query';
import { useCheckout } from '../../../iBuyFixed.context';
import { DEFAULT_ADDON_STATE } from '../../../iBuyFixed.context.data';
import {
  getReferenceId,
  getReferenceIdFromQueryClient,
  removeAddonFromQueryClient,
  retrieveAddonTypeById,
  retrieveAvailableAddons,
  saveAddonOnQueryClient,
} from './useAddonFlow.utils';

export const useAddonFlow = () => {
  const { addons, setAddons, addonState, setAddonState, product, setProduct, checkoutErrors } =
    useCheckout();

  const { addonCmsId, addonMecId, availableAddons, callGetAddons, callSaveAddon, callRemoveAddon } =
    addonState;

  const shoppingCart = getFromLocalStorageByKey('shoppingCart');
  const cartId: string = shoppingCart?.id || '';
  const cartItem = shoppingCart?.cartItem?.[0];
  const cartItemId: string = cartItem?.id;

  const queryClient = useQueryClient();
  const getAddonsDataFromQueryClient = queryClient.getQueryData(
    'getAvailableAddons'
  ) as IGetAddonResponse;

  const genericError = useCmsConfig(
    CMS_CONFIG[PAGES.CONSUMER],
    API.CMS_GET_GENERIC_ERROR
  ) as IGenericErrorCMS;

  const {
    data: getAddonsData,
    isSuccess: isSuccessGetAddons,
    isError: isErrorGetAddons,
    isLoading: isLoadingGetAddons,
    refetch: refetchGetAddons,
  } = useGetAddons(cartItemId, cartId);

  const {
    mutate: saveAddonMutation,
    data: saveAddonData,
    isSuccess: isSuccessAddition,
    isError: isErrorAddition,
    isLoading: isLoadingAddition,
  } = useAddAddon();

  const {
    mutate: removeAddonMutation,
    data: removeAddonData,
    isSuccess: isSuccessRemoval,
    isError: isErrorRemoval,
    isLoading: isLoadingRemoval,
  } = useRemoveAddon();

  const {
    isSuccess: isSuccessShoppingCart,
    data: shoppingCartData,
    refetch: refetchShoppingCart,
    isFetching: isLoadingShoppingCart,
    isError: isErrorShoppingCart,
  } = useGetShoppingCartSilentUpdate(cartId, {
    enabled: false,
    keyDependency: ['getShoppingCartAddons'],
  });

  const checkAvailableAddons = () => {
    refetchGetAddons();
  };

  const saveAddon = () => {
    if (addonCmsId && addonMecId) {
      const offeringType =
        retrieveAddonTypeById(addonMecId, getAddonsData) || IOfferingType.FIXED_VOICE;
      saveAddonMutation({ cartId, addonId: addonMecId, type: offeringType });
    } else {
      LoggerInstance.error('Error in offer update');
      setAddonState({ ...DEFAULT_ADDON_STATE, availableAddons });
    }
  };

  const removeAddon = () => {
    if (addonCmsId && addonMecId) {
      const offeringType =
        retrieveAddonTypeById(addonMecId, getAddonsData || getAddonsDataFromQueryClient) ||
        IOfferingType.FIXED_VOICE;
      const referenceId = getReferenceIdFromQueryClient(queryClient, addonMecId);
      removeAddonMutation({ cartId, addonId: referenceId, type: offeringType });
    } else {
      LoggerInstance.error('Error in offer update');
      setAddonState({ ...DEFAULT_ADDON_STATE, availableAddons });
    }
  };

  useEffect(() => {
    if (callGetAddons) {
      checkAvailableAddons();
    }
    if (callSaveAddon) {
      saveAddon();
    }
    if (callRemoveAddon) {
      removeAddon();
    }
  }, [callSaveAddon, callRemoveAddon, callGetAddons]);

  useEffect(() => {
    if (isSuccessAddition) {
      const offeringType =
        retrieveAddonTypeById(addonMecId, getAddonsData) || IOfferingType.FIXED_VOICE;
      const referenceId = getReferenceId(offeringType, addonMecId, saveAddonData);
      saveAddonOnQueryClient(queryClient, addonMecId, referenceId, offeringType);
      setAddons([...addons, addonCmsId]);
      setAddonState({ ...DEFAULT_ADDON_STATE, availableAddons });
      refetchShoppingCart();
    }
  }, [isSuccessAddition, saveAddonData]);

  useEffect(() => {
    if (isSuccessRemoval) {
      setAddons(addons.filter((addn) => addn !== addonCmsId));
      removeAddonFromQueryClient(queryClient, addonMecId);
      setAddonState({ ...DEFAULT_ADDON_STATE, availableAddons });
      refetchShoppingCart();
    }
  }, [isSuccessRemoval, removeAddonData]);

  useEffect(() => {
    const addonJson = JSON.stringify(addons);
    localStorage.setItem('addons', addonJson);
  }, [addons]);

  useEffect(() => {
    if (isErrorAddition || isErrorRemoval || isErrorGetAddons || isErrorShoppingCart) {
      setAddonState({ ...DEFAULT_ADDON_STATE, availableAddons });
      checkoutErrors?.showAndTrackError?.(genericError, () => {});
    }
  }, [isErrorAddition, isErrorRemoval, isErrorGetAddons, isErrorShoppingCart]);

  useEffect(() => {
    if (isSuccessGetAddons && getAddonsData) {
      const _availableAddons = retrieveAvailableAddons(getAddonsData);
      localStorage.setItem('available_addons', JSON.stringify(_availableAddons));
      setAddonState({
        ...addonState,
        callGetAddons: false,
        availableAddons: _availableAddons,
        isLoading: false,
      });
    }
  }, [isSuccessGetAddons, getAddonsData]);

  useEffect(() => {
    if (isSuccessShoppingCart && shoppingCartData) {
      setProduct({
        ...product,
        ...updateProductPrice(product, IUpdateProductPriceType.EDIT),
      });
    }
    setAddonState({ ...DEFAULT_ADDON_STATE, availableAddons });
  }, [isSuccessShoppingCart, shoppingCartData]);

  useEffect(() => {
    if (isLoadingGetAddons) {
      setAddonState({
        ...addonState,
        isLoading: true,
      });
    }
  }, [isLoadingGetAddons]);

  useEffect(() => {
    if (isLoadingAddition || isLoadingRemoval) {
      setAddonState({
        ...addonState,
        isLoadingUpdate: true,
      });
    }
  }, [isLoadingAddition, isLoadingRemoval]);

  useEffect(() => {
    if (isLoadingShoppingCart) {
      setAddonState({
        ...addonState,
        isLoadingPriceUpdate: true,
      });
    } else {
      setAddonState({ ...DEFAULT_ADDON_STATE, availableAddons });
    }
  }, [isLoadingShoppingCart]);

  return null;
};
