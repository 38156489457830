import React from 'react';
import { IPageProps, useHeroSlider, usePage, useTranslate } from '@vfit/consumer/data-access';
import { Footer, Header } from '@vfit/shared/components';
import { useTrackingProvider } from '@vfit/shared/data-access';
import { useFooter, useHeader } from '@vfit/shared/data-access';
import { HeroSliderConsumer, StickyBannerHeader } from '@vfit/consumer/components';
import { GlobalWrapper, MainContainer } from '../Layout/pageLayout.style';
import Widgets from '../../Widgets/widgets';
import TaggingHomepage from './homepage.tagging';
import BrightSky from '../../BrightSky/brightSky';

export default function Homepage({ isApp }: IPageProps) {
  const { page, pageNavigation } = usePage();
  const { elements, haveHeader, isHeaderSmall, haveFooter, isDisabledInitialTrack, isNewHeader } =
    page?.params || {};
  const { widgets, taggingOptions, stickybanner, topheaderbanner } = elements || {};
  const { header, tagging } = useHeader(pageNavigation);
  const { slides: topHomeSlide } = useHeroSlider(elements?.heroslider);
  const { isReady: isTaggingReady } = useTrackingProvider();
  const { translate } = useTranslate();

  const { footer } = useFooter(pageNavigation);

  return (
    <>
      {isTaggingReady && (
        <TaggingHomepage
          taggingOptions={taggingOptions}
          isDisabledInitialTrack={isDisabledInitialTrack}
        />
      )}
      <GlobalWrapper />
      <MainContainer id="mainContainer">
        {haveHeader && header && !isApp && (
          <Header
            disablePaddingTop
            onTrack={tagging}
            header={header}
            lite={isHeaderSmall}
            onTranslate={translate}
            isExtendedHeader={isNewHeader}
            topHeaderBanner={topheaderbanner}
          />
        )}
        <HeroSliderConsumer slides={topHomeSlide} />
        {stickybanner && <StickyBannerHeader bannerCustomConfig={stickybanner} />}
        {widgets?.sliders && <Widgets widgets={widgets?.sliders} tagging={tagging} />}
        {haveFooter && footer && !isApp && (
          <Footer onTrack={`${tagging}_FOOTER`} footer={footer} subChildren={<BrightSky />} />
        )}
      </MainContainer>
    </>
  );
}
