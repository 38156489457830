import {
  CMS_CONFIG,
  IAddonCMS,
  IAddonCommonsCMS,
  IAddonDetailsCMS,
  retrieveProductTechnology,
  useCmsConfig,
} from '@vfit/consumer/data-access';
import {
  API,
  PAGES,
  setTrackLink,
  setTrackView,
  trackLink,
  useTracking,
} from '@vfit/shared/data-access';
import { ICommonData } from '@vfit/shared/models';
import { useEffect, useState } from 'react';
import { SoftManagerService } from '@vfit/consumer/providers';
import { useQueryClient } from 'react-query';
import { removeCurrency } from '@vfit/consumer/hooks';
import { useCheckout } from '../../../iBuyFixed.context';
import { AddonCardView } from './components/addonCardView';
import { ID_FLOWS } from '../../checkout.constants';
import { evaluateCommonLabels } from './addonCard.utils';
import { SkeletonLoader } from './components/skeletonLoader';
import { getUserInfo } from '../../checkout.utils';

export const AddonCard = ({ handleOnChangeEnableGoNext, handleGoNextSlide }: ICommonData) => {
  const { product, addons, addonState, isLoadingCart, setAddonState, setCurrentStepKey } =
    useCheckout();
  const queryClient = useQueryClient();
  const { getCartProductsWithAddon, retrieveCartAddons } = SoftManagerService(queryClient);
  const [startAddonFlow, setStartAddonFlow] = useState(false);
  const [addonListFiltered, setAddonListFiltered] = useState<IAddonDetailsCMS[]>([]);
  const {
    availableAddons,
    isLoadingUpdate,
    isLoadingPriceUpdate,
    isLoading: isLoadingGetAddons,
  } = addonState;
  const addonListCMS = useCmsConfig(CMS_CONFIG[PAGES.CONSUMER], API.CMS_GET_ADDONS) as IAddonCMS;

  const addonCommonLabels = useCmsConfig(
    CMS_CONFIG[PAGES.CONSUMER],
    API.CMS_GET_ADDONS_COMMON
  ) as IAddonCommonsCMS;

  const { title, ctaLabel } = evaluateCommonLabels(addonCommonLabels);

  const addonIdList = Object.keys(addonListCMS).filter((addonCMSId) =>
    product?.addonList?.includes(addonCMSId)
  );
  const addonList = addonIdList.map((id) => ({
    cmsId: id,
    ...addonListCMS[id],
  }));

  const { trackView } = useTracking({
    event: ['view'],
    event_label: 'addons:view',
    opts: {
      product_target_segment: 'consumer',
      page_section: 'fixed offers',
      page_subsection: 'checkout',
      page_type: 'addons',
      event_category: 'sales',
      journey_type: 'journey',
      journey_name: 'checkout',
      product_technology: retrieveProductTechnology() || '',
    },
    cartProduct: {
      cart_product_quantity: '1',
      cart_product_id: `${product?.offerId}`,
      cart_product_name: product?.slug,
      cart_product_category: 'fixed',
      cart_product_price: product?.price && removeCurrency(product.price),
      ...getCartProductsWithAddon(product, retrieveCartAddons(addonIdList)),
    },
    visitorTrackingOpts: getUserInfo(),
  });
  setTrackLink(trackView, 'addons:');
  setTrackView(trackView);

  const callSaveAddon = (addon: string) => {
    const mecId = addonListCMS[addon]?.addondetails?.id || '';
    trackLink(`aggiungi ${mecId}`, 'users', 'click', 'cart_add');
    setAddonState({
      ...addonState,
      addonCmsId: addon,
      addonMecId: mecId,
      callSaveAddon: true,
    });
  };

  const callRemoveAddon = (addonToRemove: string) => {
    const mecId = addonListCMS[addonToRemove]?.addondetails?.id || '';
    trackLink(`rimuovi ${mecId}`, 'users', 'click', 'cart_remove');
    setAddonState({
      ...addonState,
      addonCmsId: addonToRemove,
      addonMecId: mecId,
      callRemoveAddon: true,
    });
  };

  const goNext = () => {
    trackLink(ctaLabel);
    handleGoNextSlide?.({}, false);
  };

  useEffect(() => {
    setCurrentStepKey(ID_FLOWS.ADDON_CARD);
    setStartAddonFlow(true);
  }, []);

  useEffect(() => {
    if (startAddonFlow && !isLoadingCart) {
      setAddonState({
        ...addonState,
        callGetAddons: true,
      });
      setStartAddonFlow(false);
    }
  }, [startAddonFlow, isLoadingCart]);

  useEffect(() => {
    if (availableAddons.length > 0) {
      setAddonListFiltered(
        addonList?.filter((addon) => availableAddons?.includes(addon.addondetails?.id || '0'))
      );
    }
  }, [availableAddons]);

  useEffect(() => {
    handleOnChangeEnableGoNext?.(
      !(isLoadingUpdate || isLoadingGetAddons || isLoadingCart || isLoadingPriceUpdate)
    );
  }, [isLoadingUpdate, isLoadingGetAddons, isLoadingCart, isLoadingPriceUpdate]);

  return (
    <>
      {(isLoadingCart || isLoadingGetAddons) && <SkeletonLoader />}
      {!isLoadingCart && !isLoadingGetAddons && (
        <AddonCardView
          title={title}
          ctaLabel={ctaLabel}
          addonList={addonListFiltered}
          addedAddons={addons}
          isLoadingUpdate={isLoadingUpdate}
          saveAddon={callSaveAddon}
          removeAddon={callRemoveAddon}
          goNext={goNext}
        />
      )}
    </>
  );
};
