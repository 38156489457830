import {
  APP_PLACEHOLDERS,
  ENRG_QSTRING,
  IActivableResponse,
  IActivableSection,
  IAppSession,
  ICarouselCMS,
} from '@vfit/consumer/data-access';
import { checkWindow, getFromLocalStorageByKey, errorManager, ErrorService } from '@vfit/shared/data-access';
import { QueryClient } from 'react-query';
import { IActionType } from '@vfit/shared/models';

const showError = () => {
  errorManager.handleError(ErrorService.getSeverityErrorHigh(), {
    title: 'Ops!',
    message: 'Si è verificato un errore. Riprova in seguito',
    actionText: 'Chiudi',
    actionEvent: () => window.location.reload(),
    onClose: () => window.location.reload(),
  });
};

const getAppSessionItems = (): string => {
  const appSession: IAppSession = checkWindow() ? getFromLocalStorageByKey('appSession') : null;
  if (!appSession?.items || appSession?.items.length === 0) {
    showError();
    return '';
  }
  return appSession?.items?.map((e) => e.value || '')?.join(',');
};

/**
 * Method to organize the hub app page
 * @param queryClient
 */
const organizeHubAppPage = (queryClient: QueryClient) => {
  const cmsPage: any = queryClient.getQueryData('getHubApp');
  const { pagesparams, widgets, tagging, seo, fullbanner } = cmsPage || {};
  return {
    params: {
      key: pagesparams?.key,
      haveHeader: pagesparams?.visibility?.haveHeader === 'true',
      isHeaderSmall: pagesparams?.visibility?.isHeaderSmall === 'true',
      haveFooter: pagesparams?.visibility?.haveFooter === 'true',
      isShow: pagesparams?.visibility?.showPages === 'true',
      isDisabledInitialTrack: pagesparams?.visibility?.isDisabledInitialTrack === 'true',
      isProtected: pagesparams?.visibility?.isProtected === 'true',
      protectedPass: pagesparams?.visibility?.protectedPass || '',
      path: pagesparams?.urlBuilder?.map(
        (urlBuilder: any) => urlBuilder.page?.trim()?.toLowerCase() || ''
      ) || [''],
      elements: {
        ...(pagesparams?.apiPath?.apiProductsList && {
          apiProductsList: pagesparams.apiPath.apiProductsList,
        }),
        ...(pagesparams?.apiPath?.apiOfferList && {
          apiOfferList: pagesparams.apiPath.apiOfferList,
        }),
        ...(pagesparams?.apiPath?.apiDeviceList && {
          apiDeviceList: pagesparams.apiPath.apiDeviceList,
        }),
        ...(widgets && widgets?.sliders && { widgets }),
        ...(seo && { seo }),
        ...(fullbanner && { fullbanner }),
        ...(tagging && {
          taggingOptions: {
            event_category: tagging?.eventCategory || '',
            page_section: tagging?.pageSection || '',
            page_subsection: tagging?.pageSubsection || '',
            page_type: tagging?.pageType || '',
            event: tagging?.event || '',
            eventLabel: tagging?.eventLabel || '',
            journey_type: tagging?.journeyType || '',
            product_target_segment: tagging?.productTargetSegment || '',
            product_category: tagging?.productCategory || '',
          },
        }),
      },
    },
  };
};

/**
 * Method to check if addon is visible in all section
 * @param sections
 * @param boId
 */
const isVisibleAddon = (sections: IActivableSection[], boId: string): boolean => {
  let isVisible = false;
  sections?.forEach((s) => {
    const productsSection = s?.products || [];
    productsSection?.forEach((p) => {
      const billingOffer = p?.campaignDetail?.components?.billingOffer || [];
      const billingOfferIds = billingOffer?.map((b: { id: string }) => b.id?.toString() || '');
      if (!isVisible && boId && billingOfferIds?.includes(boId)) {
        isVisible = true;
      }
    });
  });
  return isVisible;
};

const getSliders = (sliders: ICarouselCMS[], activableData: IActivableResponse): ICarouselCMS[] => {
  const querystringParams = (checkWindow() && window.location.search) || {};
  const urlParams = new URLSearchParams(querystringParams);
  const allSections = activableData?.sections || [];
  return sliders?.map((slider) => {
    const { elements } = slider;
    if (!elements || elements?.length === 0) return slider;
    return {
      ...slider,
      elements: elements
        ?.filter((e) => {
          if (e.id) {
            console.log(e.id);
            return isVisibleAddon(allSections, e?.id?.toString());
          }
          return e.category === 'all';
        })
        ?.map((e) => ({
          ...e,
          category: e.category === 'all' ? '' : e?.category || '',
          action: {
            ...e.action,
            ...(e?.id &&
              isVisibleAddon(allSections, e?.id?.toString()) && {
                type: IActionType.HUB_CATALOG_ACT,
              }),
            url: e?.action?.url
              ?.replace(APP_PLACEHOLDERS.ACCOUNT_APP, urlParams?.get(ENRG_QSTRING.ACCOUNT) || '')
              ?.replace(APP_PLACEHOLDERS.CHECKSUM_APP, urlParams?.get(ENRG_QSTRING.CHECKSUM) || '')
              ?.replace(APP_PLACEHOLDERS.MSISDN_APP, urlParams?.get(ENRG_QSTRING.MSISDN) || ''),
          },
        })),
    };
  });
};

export { getAppSessionItems, showError, getSliders, organizeHubAppPage };
