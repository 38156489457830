import { useEffect, useState } from 'react';
import { useTracking } from '@vfit/shared/data-access';
import { API, getFromLocalStorageByKey, openPopup, PAGES } from '@vfit/shared/data-access';
import {
  CMS_CONFIG,
  useCmsConfig,
  IPortabilityCMS,
  ENABLE_MIGRATION_CODE,
  IMultiPlayOfferingConfigurationResponse,
  useMultiPlayOfferingConfiguration,
  errorMock,
  IGenericErrorCMS,
  productSpecificError,
} from '@vfit/consumer/data-access';
import { ICommonData, IPortabilityObject } from '@vfit/shared/models';
import {useQueryClient} from "react-query";
import { useCheckout } from '../../../iBuyFixed.context';
import { ID_FLOWS } from '../../checkout.constants';
import { IFormData } from './portabilityMigrationCard.models';
import { getTagging, getUserInfo } from '../../checkout.utils';
import Portability from './components/Portability/portability';
import LoadingCard from '../LoadingCard/loadingCard';
import { organizePortabilityData } from './portabilityMigrationCard.organize';

const PortabilityMigrationCard = ({
  handleOnChangeEnableGoNext,
  handleOnClose,
  handleGoNextSlide,
}: ICommonData) => {
  const {
    portability,
    portabilityStatus,
    checkoutErrors,
    product,
    setPortability,
    setPortabilityStatus,
    setCurrentStepKey,
  } = useCheckout();
  const [isValid, setIsValid] = useState<boolean>(false);
  const multiPlayOfferingConfiguration: IMultiPlayOfferingConfigurationResponse =
    getFromLocalStorageByKey('multiPlayOfferingConfiguration');
  const [isLoading, setIsLoading] = useState<boolean>(!multiPlayOfferingConfiguration);
  const { buttonGenericError, urlGenericErrorCallMeNow } = productSpecificError(product);
  const queryClient = useQueryClient();

  const genericError = useCmsConfig(
    CMS_CONFIG[PAGES.CONSUMER],
    API.CMS_GET_GENERIC_ERROR
  ) as IGenericErrorCMS;
  const portabilityCMSData = useCmsConfig(
    CMS_CONFIG[PAGES.CONSUMER],
    API.CMS_GET_PORTABILITY
  ) as IPortabilityCMS;

  const portabilityData = organizePortabilityData(portabilityCMSData, portability);
  const { isMultipleOperators } = portabilityData;

  const {
    error: errorMultiPlay,
    isLoading: isLoadingMultiPlay,
    isError: isErrorMultiPlay,
    isSuccess: isSuccessMultiPlay,
    refetch: refetchMultiPlay,
  } = useMultiPlayOfferingConfiguration(portabilityCMSData?.portability?.operatorList, {
    enabled: false,
  });

  const taggingFixed = getTagging('portability', 'fixed', queryClient);
  useTracking({
    event: ['checkout_step4.4'],
    event_label: isMultipleOperators ? 'two carrier' : 'one carrier',
    opts: taggingFixed.opts,
    cartProduct: taggingFixed.trackingProduct,
    pageProduct: taggingFixed?.pageProductsInfo,
    visitorTrackingOpts: getUserInfo(),
  });

  const onChangeFirstCode = (firstCodeData: IFormData, isFirstValid: boolean) => {
    const portabilityObject = {
      ...portability,
      portabilityMigration: {
        ...portability.portabilityMigration,
        firstOperator: firstCodeData.operator,
        firstCode: firstCodeData.migrationCode,
        firstCodeValid: isFirstValid,
      },
    };
    setPortability(portabilityObject);
    checkIsValidEnableGoNext(portabilityObject);
  };

  const onChangeSecondCode = (secondCodeData: IFormData, isSecondValid: boolean) => {
    const portabilityObject = {
      ...portability,
      portabilityMigration: {
        ...portability.portabilityMigration,
        secondOperator: secondCodeData.operator,
        secondCode: secondCodeData.migrationCode,
        secondCodeValid: isSecondValid,
      },
    };
    setPortability(portabilityObject);
    checkIsValidEnableGoNext(portabilityObject);
  };

  const checkIsValidEnableGoNext = (portabilityObject: IPortabilityObject) => {
    const checkIsValid = (): boolean => {
      const { portabilityMigration } = portabilityObject;
      const { firstOperator, firstCodeValid, secondOperator, secondCodeValid, noCode } =
        portabilityMigration || {};
      if (noCode) {
        if (isMultipleOperators) {
          return !!(firstOperator && secondOperator);
        }
        return !!firstOperator;
      }
      if (isMultipleOperators) {
        return !!(firstOperator && secondOperator && firstCodeValid && secondCodeValid);
      }
      return !!firstOperator && !!firstCodeValid;
    };
    const isValidRes = checkIsValid();
    setIsValid(isValidRes);
    handleOnChangeEnableGoNext?.(isValidRes);
  };

  const onNoCode = (showCode: boolean) => {
    const portabilityObject = {
      ...portability,
      portabilityMigration: {
        ...portability.portabilityMigration,
        noCode: showCode,
      },
    };
    if (showCode) {
      localStorage.setItem(ENABLE_MIGRATION_CODE, '0');
    } else {
      localStorage.setItem(ENABLE_MIGRATION_CODE, '1');
    }
    setPortability(portabilityObject);
    checkIsValidEnableGoNext(portabilityObject);
  };

  const validateMigrationCode = () => {
    const updateMigrationCode = (operator: string, mCode: string | undefined) => {
      let migrationCode = mCode;
      if (operator === 'TELECOM ITALIA' && migrationCode) {
        if (migrationCode.length <= 14) {
          migrationCode = `${migrationCode.slice(0, migrationCode.length - 1)}111${
            migrationCode[migrationCode.length - 1]
          }`;
        } else if (migrationCode.length >= 15 && migrationCode.length <= 16) {
          const checkCode = migrationCode.slice(-4, -1);
          if (checkCode.includes('OFA') || checkCode.includes('012')) {
            return migrationCode;
          }
          migrationCode = `${migrationCode.slice(0, migrationCode.length - 1)}111${
            migrationCode[migrationCode.length - 1]
          }`;
        }
      }
      return migrationCode;
    };

    if (portability.portabilityMigration) {
      portability.portabilityMigration.firstCode = updateMigrationCode(
        portability?.portabilityMigration?.firstOperator || '',
        portability?.portabilityMigration.firstCode
      );
      portability.portabilityMigration.secondCode = updateMigrationCode(
        portability?.portabilityMigration?.secondOperator || '',
        portability?.portabilityMigration.secondCode
      );
    }

    return portability;
  };

  useEffect(() => {
    const goNextButton = document.getElementById('sticky-offer-next');
    const customizedPortability = validateMigrationCode();

    const setStartPortability = () => {
      setPortabilityStatus({
        ...portabilityStatus,
        isStartPortIn: true,
      });
      setPortability({
        ...customizedPortability,
      });
    };
    if (isValid) {
      goNextButton?.addEventListener('click', setStartPortability);
    } else {
      goNextButton?.removeEventListener('click', setStartPortability);
    }
    return () => {
      goNextButton?.removeEventListener('click', setStartPortability);
    };
  }, [isValid, portability]);

  useEffect(() => {
    checkIsValidEnableGoNext(portability);
  }, [portability]);

  useEffect(() => {
    if (isErrorMultiPlay) {
      checkoutErrors?.showAndTrackError?.(
        genericError,
        handleOnClose,
        errorMock('multiplayoffering', errorMultiPlay),
        undefined,
        undefined,
        () => openPopup(urlGenericErrorCallMeNow, handleOnClose),
        buttonGenericError
      );
    } else if (isSuccessMultiPlay) {
      setIsLoading(false);
    }
  }, [isErrorMultiPlay, isSuccessMultiPlay]);

  useEffect(() => {
    setCurrentStepKey(ID_FLOWS.PORTABILITY_MIGRATIONS);
    localStorage.setItem(ENABLE_MIGRATION_CODE, '1');
    if (isLoading && !isLoadingMultiPlay) refetchMultiPlay();
  }, []);

  if (isLoading) {
    return <LoadingCard />;
  }

  return (
    <Portability
      handleGoNextSlide={handleGoNextSlide}
      portabilityData={portabilityData}
      onChangeFirst={onChangeFirstCode}
      onChangeSecond={onChangeSecondCode}
      data={portability?.portabilityMigration || {}}
      onNoCode={onNoCode}
    />
  );
};

export default PortabilityMigrationCard;
