import {
  GetCustomerResponse,
  IAPIError,
  ICheckPortInFeasibilityIIResponse,
  IProduct,
} from '@vfit/consumer/data-access';
import {
  ICommonData,
  ICountryObject,
  IDeliveryOptions,
  IFormat,
  IIdentificationDetails,
  IPartyPrivacyItem,
  IStepCard,
} from '@vfit/shared/models';
import { ITrackError } from '@vfit/shared/data-access';
import { IUseDeliveryLocateAndPickupPointStatus } from './hooks/UseDeliveryLocateAndPickupPoint/useDeliveryLocateAndPickupPoint.models';

export interface IFlow {
  DEFAULT: IStepCard[];
  ESIM_ONLY: IStepCard[];
  WINBACK: IStepCard[];
  SKIP_MNP: IStepCard[];
  ESIM_ONLY_SKIP_MNP: IStepCard[];
  PORTABILITY_REQUIRED: IStepCard[];
}

export interface IFlowApp {
  DEFAULT: IStepCard[];
  ESIM_ONLY: IStepCard[];
  SKIP_MNP: IStepCard[];
  ESIM_ONLY_SKIP_MNP: IStepCard[];
  PORTABILITY_REQUIRED: IStepCard[];
}

export interface IPersonalInfoFlow {
  PERSONAL_INFO: IStepCard[];
}

export interface IOtpFLow {
  OTP: IStepCard[];
}

export interface IPortabilityFlow {
  PORTABILITY: IStepCard[];
  PORTABILITY_NUMBER: IStepCard[];
  PORTABILITY_OTP: IStepCard[];
}

export interface IPortabilityStatus {
  portInData?: ICheckPortInFeasibilityIIResponse | IAPIError;
  isStartPortIn: boolean;
  isStartFinalPortIn: boolean;
  isSuccessCheck: boolean;
  isLoadingCheck: boolean;
  isErrorCheck: boolean;
  isSuccess: boolean;
  isLoading: boolean;
  isError: boolean;
  errorMessage: string;
}

export interface ITermsFlow {
  TERMS: IStepCard[];
}

export interface IDeliveryFlow {
  DELIVERY: IStepCard[];
}

export interface IEsimSimFlow {
  RECOGNITION: IStepCard[];
}

export interface IVoucherFlow {
  VOUCHER: IStepCard[];
}

export interface IWinBackFlow {
  WINBACK_NUMBER: IStepCard[];
  WINBACK_OTP: IStepCard[];
}

export interface IPaymentFlow {
  RECURRENT_PAYMENT_CARD: IStepCard[];
  WARNING_PRICE_CHANGE: IStepCard[];
}

export interface IUpfrontPaymentFlow {
  UPFRONT_PAYMENT: IStepCard[];
}

export interface IPaymentRecognizedFlow {
  PAYMENT_METHOD: IStepCard[];
}

export interface IUpfrontSelectionFlow {
  UPFRONT_SELECTION: IStepCard[];
}

export interface IRechargeFlow {
  RECHARGE: IStepCard[];
}

export interface IContactInfo {
  checkContactProblem: boolean;
}

export interface IButtonFooter {
  buttonGoNext: string;
  buttonAcceptAndGoNext: string;
}

export interface ICheckoutProps {
  backgroundImage: string;
  isOpen: boolean;
  product?: IProduct;
  productDetailAddress: string;
  handleClose: () => void;
  isErrorOnPayment?: boolean;
  isErrorOnPaymentShowErrorModal?: boolean;
  isOnResetUser?: boolean;
  isLoadingCart?: boolean;
  buttonFooter: IButtonFooter;
}

export interface ICheckoutModalProps {
  backgroundImage: string;
  isOpen: boolean;
  handleClose: () => void;
  isLoadingCart?: boolean;
  hideCards?: boolean;
  isErrorOnPayment?: boolean;
  isErrorOnPaymentShowErrorModal?: boolean;
  isOnResetUser?: boolean;
}

export type ICheckoutModalChildrenProps = Omit<ICheckoutModalProps, 'isOpen'>;

export type ICheckoutData = ICommonData;

export interface IDeliveryBase {
  isSuccess: boolean;
  isLoading: boolean;
  isError: boolean;
  errorMessage: string;
  deliveryOptions: IDeliveryOptions[];
  identificationDetails: IIdentificationDetails | IIdentificationDetails[];
}

export interface IDeliveryAdvance {
  isSuccess: boolean;
  isLoading: boolean;
  isError: boolean;
  error?: ITrackError;
  deliveryOptions: IDeliveryOptions[];
  identificationDetails: IIdentificationDetails | IIdentificationDetails[];
}

export interface IContactMedium {
  type: string;
  preferred: boolean;
  emailAddress?: string;
  phoneNumber?: string;
}

export interface IIdentification {
  identificationType: string;
  nationality: string;
  identificationNumber: string;
  expirationDate: string;
}

export interface IOwningIndividual {
  nation: string;
  fiscalCode: string;
  firstName: string;
  lastName: string;
  contactMedium?: IContactMedium[];
  identification: IIdentification[];
}

export type IPersonalDataObject = {
  owningIndividual: IOwningIndividual;
  isValid?: boolean;
};

export interface ISimOptions {
  simOptions: IFormat[];
}

export interface IConsentsObject {
  partyPrivacyItems: IPartyPrivacyItem[];
}

export interface ITermsAndConditionsCheck {
  consensus: boolean;
  preConsensus?: boolean;
  optionToReconsider: string;
  isSuccessTermsAndConditions: boolean;
  isLoadingTermsAndConditions: boolean;
  isErrorTermsAndConditions: boolean;
}

export interface IPickUpLocationStore {
  startDeliveryLocateAndPickupPoint: () => void;
  global: IUseDeliveryLocateAndPickupPointStatus;
  searchPickupLocations: IUseDeliveryLocateAndPickupPointStatus;
  searchPostalOffice: IUseDeliveryLocateAndPickupPointStatus;
  searchLocateStore: IUseDeliveryLocateAndPickupPointStatus;
}

export interface IUpfrontSelection {
  option: string;
}

export interface IRechargeOption {
  option: string;
}

export interface IInitContext {
  customerData?: GetCustomerResponse;
  countries?: ICountryObject[];
  isLogged?: boolean;
  isValid?: boolean;
}

export interface ICheckoutSIA {
  isShow: boolean;
}

export interface IUpdateProductPriceControl {
  isUpdatePrice: boolean;
  isPriceUpdated: boolean;
}

export const PHONE_CUSTOMER = 'phone_cus';
export const EMAIL_CUSTOMER = 'email_cus';

export interface IEditorialErrorConfig {
  title: string;
  message: string;
  actionText: string;
  url: string;
  isPopup?: boolean;
}
